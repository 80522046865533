import { PATHS } from "./paths";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Dashboard from "../pages/Dash";
import Feedback from "../pages/Feedback";
import FeedbackFile from "../pages/Feedback/[filename]";
import FeedbackGuest from "../pages/Feedback/[filename]/[guestId]";
import Settings from "../pages/Settings";
import Account from "../pages/Account";
import Help from "../pages/Help";
import Brief from "../pages/Brief";
import BriefEditor from "../pages/BriefEditor";
import BriefDocuments from "../pages/BriefEditor/[briefId]";
import DashboardLayout from "../layout/DashboardLayout";
import {
  DashboardIcon,
  FeedbackIcon,
  HelpIcon,
  AccountIcon,
  SettingsIcon,
  LogoutIcon,
} from "../assets/icons/icons";

export const publicRoutes = [
  {
    path: PATHS.PUBLIC.HOME,
    element: Home,
  },
  {
    path: PATHS.PUBLIC.LOGIN,
    element: Login,
  },
];

export const protectedRoutes = [
  {
    path: PATHS.PROTECTED.DASHBOARD,
    element: Dashboard,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK,
    element: Feedback,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK_FILE,
    element: FeedbackFile,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK_GUEST,
    element: FeedbackGuest,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.SETTINGS,
    element: Settings,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.ACCOUNT,
    element: Account,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.HELP,
    element: Help,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.BRIEF,
    element: Brief,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.BRIEFEDIT,
    element: BriefDocuments,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.BRIEFEDITORNEW,
    element: BriefEditor,
    // layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.BRIEFEDITOR,
    element: BriefEditor,
    // layout: DashboardLayout,
  },
];
export const NAVIGATION_LINKS = [
  {
    path: PATHS.PROTECTED.DASHBOARD,
    label: "Dashboard",
    icon: DashboardIcon,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK,
    label: "Feedback",
    icon: FeedbackIcon,
    // Add isActive function for special cases like dynamic routes
    isActive: (path) => path.startsWith("/feedback"),
  },
  {
    path: PATHS.PROTECTED.BRIEF,
    label: "Brief",
    icon: HelpIcon,
    disabled: false,
  },
  {
    path: PATHS.PROTECTED.HELP,
    label: "Help",
    icon: HelpIcon,
  },
  {
    path: PATHS.PROTECTED.ACCOUNT,
    label: "Account",
    icon: AccountIcon,
  },
  {
    path: PATHS.PROTECTED.SETTINGS,
    label: "Settings",
    icon: SettingsIcon,
  },
];
export const isDashboardPath = (path) => {
  // Get all protected paths
  const protectedPaths = Object.values(PATHS.PROTECTED);

  // First check exact matches
  if (protectedPaths.includes(path)) {
    return true;
  }

  // Then check for dynamic routes
  return protectedPaths.some((protectedPath) => {
    // Convert route pattern to regex
    // Replace :parameter with any character match
    const pathRegex = new RegExp(
      "^" + protectedPath.replace(/:\w+/g, "[^/]+") + "$"
    );
    return pathRegex.test(path);
  });
};
