import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useThemeContext } from "../../context/ThemeContext";

const TranscriptionDisplay = ({
  transcription,
  pageNumber,
  onDelete,
  step,
  questions,
  currentQuestionIndex,
}) => {
  const { theme } = useThemeContext();
  const containerRef = useRef(null);
  const [displayItems, setDisplayItems] = useState([]);
  const lastPageNumber = useRef(pageNumber);
  const processedTranscriptions = useRef(new Set());
  const lastQuestionIndex = useRef(currentQuestionIndex);
  const isInitialMount = useRef(true);
  const restorationComplete = useRef(false);
  // console.log(transcription, questions);
  // Combined restoration effect
  useEffect(() => {
    if (
      (transcription.length > 0 || step === 5) &&
      !restorationComplete.current
    ) {
      const items = [];
      let sequenceNumber = 0;

      // Step 1: Add initial feedback transcriptions
      transcription.forEach((item) => {
        if (item.step === 1) {
          const transcriptionKey = `${item.step}-${item.pageNumber}-${item.text}`;
          if (!processedTranscriptions.current.has(transcriptionKey)) {
            processedTranscriptions.current.add(transcriptionKey);
            items.push({
              type: "transcription",
              text: item.text,
              step: item.step,
              pageNumber: item.pageNumber,
              sequence: sequenceNumber++,
              id: `${item.step}-${item.pageNumber}-${Date.now()}-${Math.random()
                .toString(36)
                .substr(2, 9)}`,
            });
          }
        }
      });

      // Add step 2 transition
      if (step >= 2) {
        items.push({
          type: "stepTransition2",
          id: `step-2-transition-${Date.now()}`,
          sequence: sequenceNumber++,
          text: "Thanks for the feedback, Allow me a few question to make the feedback clearer.",
        });
      }

      // Create a map of question indices to their answers
      const questionAnswers = new Map();
      transcription.forEach((item) => {
        if (item.step === 2) {
          if (!questionAnswers.has(item.questionIndex)) {
            questionAnswers.set(item.questionIndex, []);
          }
          questionAnswers.get(item.questionIndex).push(item);
        }
      });

      // Process questions and answers in sequence
      let processedIndices = new Set();

      // First, add items based on transcription sequence
      transcription.forEach((item) => {
        if (item.step === 2) {
          const qIndex = item.questionIndex;
          if (!processedIndices.has(qIndex) && questions[qIndex]) {
            // Add question
            items.push({
              type: "question",
              text: questions[qIndex].question_text,
              sequence: sequenceNumber++,
              id: `question-${qIndex}-${Date.now()}`,
              index: qIndex,
            });
            processedIndices.add(qIndex);
          }

          // Add answer
          const transcriptionKey = `${item.step}-${item.pageNumber}-${item.text}`;
          if (!processedTranscriptions.current.has(transcriptionKey)) {
            processedTranscriptions.current.add(transcriptionKey);
            items.push({
              type: "transcription",
              text: item.text,
              step: item.step,
              pageNumber: item.pageNumber,
              sequence: sequenceNumber++,
              id: `${item.step}-${item.pageNumber}-${Date.now()}-${Math.random()
                .toString(36)
                .substr(2, 9)}`,
            });
          }
        }
      });

      // Add current question if in step 2 and not yet processed
      if (
        step === 2 &&
        currentQuestionIndex >= 0 &&
        questions[currentQuestionIndex] &&
        !processedIndices.has(currentQuestionIndex)
      ) {
        items.push({
          type: "question",
          text: questions[currentQuestionIndex].question_text,
          sequence: sequenceNumber++,
          id: `question-${currentQuestionIndex}-${Date.now()}`,
          index: currentQuestionIndex,
        });
      }

      // Sort items by sequence number
      items.sort((a, b) => a.sequence - b.sequence);

      setDisplayItems(items);
      lastQuestionIndex.current = currentQuestionIndex;
      lastPageNumber.current = pageNumber;
      isInitialMount.current = false;
      restorationComplete.current = true;
    }
  }, [transcription, step, questions, currentQuestionIndex, pageNumber]);
  // Handle new transcriptions
  useEffect(() => {
    if (
      !isInitialMount.current &&
      transcription.length > 0 &&
      restorationComplete.current
    ) {
      const lastTranscription = transcription[transcription.length - 1];
      const transcriptionKey = `${lastTranscription.step}-${lastTranscription.pageNumber}-${lastTranscription.text}`;

      if (!processedTranscriptions.current.has(transcriptionKey)) {
        processedTranscriptions.current.add(transcriptionKey);
        setDisplayItems((prev) => [
          ...prev,
          {
            type: "transcription",
            text: lastTranscription.text,
            step: lastTranscription.step,
            pageNumber: lastTranscription.pageNumber,
            sequence: prev.length,
            id: `${lastTranscription.step}-${
              lastTranscription.pageNumber
            }-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          },
        ]);
      }
    }
  }, [transcription]);

  // Handle step transitions and new questions
  useEffect(() => {
    if (!isInitialMount.current && restorationComplete.current) {
      setDisplayItems((prev) => {
        const newItems = [...prev];
        const maxSequence = Math.max(
          ...newItems.map((item) => item.sequence || 0),
          0
        );
        let sequence = maxSequence + 1;

        // Handle step 2 transition
        if (
          step === 2 &&
          !newItems.some((item) => item.type === "stepTransition2")
        ) {
          newItems.push({
            type: "stepTransition2",
            id: `step-2-transition-${Date.now()}`,
            sequence: sequence++,
            text: "Thanks for the feedback, Allow me a few question to make the feedback clearer.",
          });
        }

        // Add new question if it doesn't exist
        if (
          step === 2 &&
          questions?.[currentQuestionIndex] &&
          !newItems.some(
            (item) =>
              item.type === "question" && item.index === currentQuestionIndex
          )
        ) {
          newItems.push({
            type: "question",
            id: `question-${currentQuestionIndex}-${Date.now()}`,
            sequence: sequence++,
            text: questions[currentQuestionIndex].question_text,
            index: currentQuestionIndex,
          });
        }

        // Sort by sequence number
        return newItems.sort((a, b) => a.sequence - b.sequence);
      });
    }
  }, [step, questions, currentQuestionIndex]);

  // Auto-scroll to bottom
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [displayItems]);

  useEffect(() => {
    if (pageNumber !== lastPageNumber.current) {
      setDisplayItems((prev) => [
        ...prev,
        {
          type: "pageChange",
          text: `Moved to page ${pageNumber}`,
          pageNumber,
          id: `page-change-${Date.now()}-${Math.random()
            .toString(36)
            .substr(2, 9)}`,
        },
      ]);
      lastPageNumber.current = pageNumber;
    }
  }, [pageNumber]);

  const handleDelete = (item) => {
    if (item.type === "transcription") {
      const transcriptionKey = `${item.step}-${item.pageNumber}-${item.text}`;
      processedTranscriptions.current.delete(transcriptionKey);
      onDelete && onDelete(item.step, item.pageNumber);
      setDisplayItems((prev) => prev.filter((i) => i.id !== item.id));
    }
  };

  const renderItem = (item) => {
    switch (item.type) {
      case "transcription":
        return (
          item.text !== "" && (
            <div
              key={item.id}
              className="text-base font-medium flex justify-between items-center pr-2 cursor-pointer group"
            >
              <span>{item.text}</span>
              {onDelete && (
                <div
                  className="opacity-0 cursor-pointer transition-all duration-200 flex items-center group-hover:opacity-100"
                  onClick={() => handleDelete(item)}
                  title="Delete transcription"
                >
                  <MdDelete size={18} color="#FF4646" />
                </div>
              )}
            </div>
          )
        );
      case "pageChange":
        return (
          <div key={item.id} className="text-sm font-medium text-[#5D6673]">
            <span>{item.text}</span>
          </div>
        );
      case "stepTransition2":
      case "stepTransition5":
      case "question":
        return (
          <div
            key={item.id}
            className={`
            px-4 py-2 text-base font-medium rounded-t-3xl rounded-br-3xl ${
              theme === "light"
                ? "bg-[#D4D4D8] text-gray-800"
                : "bg-gray-700 text-gray-100"
            }`}
          >
            {item.text}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div
      ref={containerRef}
      className="flex flex-col gap-2.5 h-[310px] overflow-y-auto text-left custom-scrollbar"
    >
      {displayItems.map((item) => renderItem(item))}
    </div>
  );
};

export default TranscriptionDisplay;
