export const StarIcon = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
    <svg
      width="29"
      height="31"
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4_1346)">
        <path
          d="M9.28664 4.47935C9.30844 4.37271 9.46079 4.37271 9.48259 4.47935L10.2852 8.4061C10.2932 8.44535 10.3239 8.47602 10.3631 8.48404L14.2899 9.28664C14.3965 9.30844 14.3965 9.46079 14.2899 9.48259L10.3631 10.2852C10.3239 10.2932 10.2932 10.3239 10.2852 10.3631L9.48259 14.2899C9.46079 14.3965 9.30844 14.3965 9.28664 14.2899L8.48404 10.3631C8.47602 10.3239 8.44535 10.2932 8.4061 10.2852L4.47935 9.48259C4.37271 9.46079 4.37271 9.30844 4.47935 9.28664L8.4061 8.48404C8.44535 8.47602 8.47602 8.44535 8.48404 8.4061L9.28664 4.47935Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_4_1346)">
        <path
          d="M11.6292 15.438C11.6945 15.1181 12.1516 15.1181 12.217 15.438L13.0583 19.554C13.0823 19.6718 13.1744 19.7638 13.2921 19.7879L17.4081 20.6292C17.728 20.6945 17.728 21.1516 17.4081 21.217L13.2921 22.0583C13.1744 22.0823 13.0823 22.1744 13.0583 22.2921L12.217 26.4081C12.1516 26.728 11.6945 26.728 11.6292 26.4081L10.7879 22.2921C10.7638 22.1744 10.6718 22.0823 10.554 22.0583L6.43804 21.217C6.11814 21.1516 6.11814 20.6945 6.43804 20.6292L10.554 19.7879C10.6718 19.7638 10.7638 19.6718 10.7879 19.554L11.6292 15.438Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d_4_1346)">
        <path
          d="M19.2253 7.86861C19.3197 7.45356 19.911 7.45356 20.0054 7.8686L20.786 11.3005C20.8158 11.4315 20.9094 11.5389 21.0352 11.5862L24.0042 12.7025C24.3498 12.8325 24.3498 13.3214 24.0042 13.4513L21.0352 14.5677C20.9094 14.615 20.8158 14.7223 20.786 14.8534L20.0054 18.2852C19.911 18.7003 19.3197 18.7003 19.2253 18.2852L18.4447 14.8534C18.4149 14.7223 18.3212 14.615 18.1954 14.5677L15.2265 13.4513C14.8808 13.3214 14.8808 12.8325 15.2265 12.7025L18.1954 11.5862C18.3212 11.5389 18.4149 11.4315 18.4447 11.3005L19.2253 7.86861Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4_1346"
          x="0.399414"
          y="0.399372"
          width="17.9705"
          height="17.9705"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4_1346"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4_1346"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4_1346"
          x="2.198"
          y="11.1981"
          width="19.45"
          height="19.4499"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4_1346"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4_1346"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4_1346"
          x="10.9673"
          y="3.55732"
          width="17.2961"
          height="19.0392"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4_1346"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4_1346"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const NextIcon = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72489 0.558058C8.96896 0.313981 9.36469 0.313981 9.60877 0.558058L14.6088 5.55806C14.8528 5.80214 14.8528 6.19786 14.6088 6.44194L9.60877 11.4419C9.36469 11.686 8.96896 11.686 8.72489 11.4419C8.48081 11.1979 8.48081 10.8021 8.72489 10.5581L12.6579 6.625H0.833496C0.488318 6.625 0.208496 6.34518 0.208496 6C0.208496 5.65482 0.488318 5.375 0.833496 5.375H12.6579L8.72489 1.44194C8.48081 1.19786 8.48081 0.802136 8.72489 0.558058Z"
        fill="white"
      />
    </svg>
  );
};

export const GoogleIcon = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.02 12.2729C24.02 11.422 23.9436 10.6038 23.8018 9.81836H12.5V14.4602H18.9582C18.68 15.9602 17.8345 17.2311 16.5636 18.082V21.0929H20.4418C22.7109 19.0038 24.02 15.9274 24.02 12.2729Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 24.0003C15.74 24.0003 18.4564 22.9257 20.4418 21.093L16.5636 18.0821C15.4891 18.8021 14.1145 19.2275 12.5 19.2275C9.37455 19.2275 6.72909 17.1166 5.78546 14.2803H1.77637V17.3894C3.75091 21.3112 7.80909 24.0003 12.5 24.0003Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78545 14.2804C5.54545 13.5604 5.40909 12.7913 5.40909 12.0004C5.40909 11.2095 5.54545 10.4404 5.78545 9.72042V6.61133H1.77636C0.963636 8.23133 0.5 10.0641 0.5 12.0004C0.5 13.9368 0.963636 15.7695 1.77636 17.3895L5.78545 14.2804Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.77273C14.2618 4.77273 15.8436 5.37818 17.0873 6.56727L20.5291 3.12545C18.4509 1.18909 15.7345 0 12.5 0C7.80909 0 3.75091 2.68909 1.77637 6.61091L5.78546 9.72C6.72909 6.88364 9.37455 4.77273 12.5 4.77273Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export const DashboardIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.5C2 4.37868 2 3.31802 2.65901 2.65901C3.31802 2 4.37868 2 6.5 2C8.62132 2 9.68198 2 10.341 2.65901C11 3.31802 11 4.37868 11 6.5C11 8.62132 11 9.68198 10.341 10.341C9.68198 11 8.62132 11 6.5 11C4.37868 11 3.31802 11 2.65901 10.341C2 9.68198 2 8.62132 2 6.5Z"
        fill="currentColor"
      />
      <path
        d="M13 17.5C13 15.3787 13 14.318 13.659 13.659C14.318 13 15.3787 13 17.5 13C19.6213 13 20.682 13 21.341 13.659C22 14.318 22 15.3787 22 17.5C22 19.6213 22 20.682 21.341 21.341C20.682 22 19.6213 22 17.5 22C15.3787 22 14.318 22 13.659 21.341C13 20.682 13 19.6213 13 17.5Z"
        fill="currentColor"
      />
      <path
        d="M2 17.5C2 15.3787 2 14.318 2.65901 13.659C3.31802 13 4.37868 13 6.5 13C8.62132 13 9.68198 13 10.341 13.659C11 14.318 11 15.3787 11 17.5C11 19.6213 11 20.682 10.341 21.341C9.68198 22 8.62132 22 6.5 22C4.37868 22 3.31802 22 2.65901 21.341C2 20.682 2 19.6213 2 17.5Z"
        fill="currentColor"
      />
      <path
        d="M13 6.5C13 4.37868 13 3.31802 13.659 2.65901C14.318 2 15.3787 2 17.5 2C19.6213 2 20.682 2 21.341 2.65901C22 3.31802 22 4.37868 22 6.5C22 8.62132 22 9.68198 21.341 10.341C20.682 11 19.6213 11 17.5 11C15.3787 11 14.318 11 13.659 10.341C13 9.68198 13 8.62132 13 6.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const FeedbackIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6 11C6.55228 11 7 10.5523 7 10C7 9.44771 6.55228 9 6 9C5.44772 9 5 9.44771 5 10C5 10.5523 5.44772 11 6 11ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44771 10.5523 9 10 9C9.44771 9 9 9.44771 9 10C9 10.5523 9.44771 11 10 11ZM14 11C14.5523 11 15 10.5523 15 10C15 9.44771 14.5523 9 14 9C13.4477 9 13 9.44771 13 10C13 10.5523 13.4477 11 14 11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const HelpIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 -3.92528e-07 10 -8.74228e-07C4.49 -1.35593e-06 1.35593e-06 4.49 8.74228e-07 10C3.92528e-07 15.51 4.49 20 10 20ZM10.75 14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14L9.25 9C9.25 8.59 9.59 8.25 10 8.25C10.41 8.25 10.75 8.59 10.75 9L10.75 14ZM9.08 5.62C9.13 5.49 9.2 5.39 9.29 5.29C9.39 5.2 9.5 5.13 9.62 5.08C9.74 5.03 9.87 5 10 5C10.13 5 10.26 5.03 10.38 5.08C10.5 5.13 10.61 5.2 10.71 5.29C10.8 5.39 10.87 5.49 10.92 5.62C10.97 5.74 11 5.87 11 6C11 6.13 10.97 6.26 10.92 6.38C10.87 6.5 10.8 6.61 10.71 6.71C10.61 6.8 10.5 6.87 10.38 6.92C10.14 7.02 9.86 7.02 9.62 6.92C9.5 6.87 9.39 6.8 9.29 6.71C9.2 6.61 9.13 6.5 9.08 6.38C9.03 6.26 9 6.13 9 6C9 5.87 9.03 5.74 9.08 5.62Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const AccountIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7C7 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7ZM9.99998 18.5C11.784 18.5 13.4397 17.9504 14.8069 17.0112C15.4108 16.5964 15.6688 15.8062 15.3178 15.1632C14.59 13.8303 13.0902 13 9.99994 13C6.90969 13 5.40997 13.8302 4.68214 15.1632C4.33105 15.8062 4.5891 16.5963 5.19296 17.0111C6.56018 17.9503 8.2159 18.5 9.99998 18.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const SettingsIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42773 0C8.31389 2.72392e-08 7.29953 0.600705 5.27081 1.80211L4.58466 2.20846C2.55594 3.40987 1.54158 4.01057 0.984656 5C0.427734 5.98943 0.427734 7.19084 0.427734 9.59366V10.4063C0.427734 12.8092 0.427734 14.0106 0.984656 15C1.54158 15.9894 2.55594 16.5901 4.58466 17.7915L5.27081 18.1979C7.29953 19.3993 8.31389 20 9.42773 20C10.5416 20 11.5559 19.3993 13.5847 18.1979L14.2708 17.7915C16.2995 16.5901 17.3139 15.9894 17.8708 15C18.4277 14.0106 18.4277 12.8092 18.4277 10.4063V9.59366C18.4277 7.19084 18.4277 5.98943 17.8708 5C17.3139 4.01057 16.2995 3.40987 14.2708 2.20846L13.5847 1.80211C11.5559 0.600705 10.5416 -2.72392e-08 9.42773 0ZM5.67773 10C5.67773 7.92893 7.35667 6.25 9.42773 6.25C11.4988 6.25 13.1777 7.92893 13.1777 10C13.1777 12.0711 11.4988 13.75 9.42773 13.75C7.35667 13.75 5.67773 12.0711 5.67773 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LogoutIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2V6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.75 2.75C12.75 2.33579 12.4142 2 12 2C11.5858 2 11.25 2.33579 11.25 2.75V6.75C11.25 7.16421 11.5858 7.5 12 7.5C12.4142 7.5 12.75 7.16421 12.75 6.75V2.75Z"
        fill="currentColor"
      />
      <path
        d="M8.7919 5.14692C9.17345 4.98571 9.35208 4.54571 9.19087 4.16416C9.02966 3.7826 8.58966 3.60398 8.2081 3.76519C4.70832 5.24386 2.25 8.70905 2.25 12.7501C2.25 18.1349 6.61522 22.5001 12 22.5001C17.3848 22.5001 21.75 18.1349 21.75 12.7501C21.75 8.70905 19.2917 5.24386 15.7919 3.76519C15.4103 3.60398 14.9703 3.7826 14.8091 4.16416C14.6479 4.54571 14.8265 4.98571 15.2081 5.14692C18.1722 6.39927 20.25 9.33293 20.25 12.7501C20.25 17.3065 16.5563 21.0001 12 21.0001C7.44365 21.0001 3.75 17.3065 3.75 12.7501C3.75 9.33293 5.82779 6.39927 8.7919 5.14692Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const CopyIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 9.16699C5.25 6.80997 5.25 5.63146 5.98223 4.89923C6.71447 4.16699 7.89298 4.16699 10.25 4.16699H12.75C15.107 4.16699 16.2855 4.16699 17.0178 4.89923C17.75 5.63146 17.75 6.80997 17.75 9.16699V13.3337C17.75 15.6907 17.75 16.8692 17.0178 17.6014C16.2855 18.3337 15.107 18.3337 12.75 18.3337H10.25C7.89298 18.3337 6.71447 18.3337 5.98223 17.6014C5.25 16.8692 5.25 15.6907 5.25 13.3337V9.16699Z"
        stroke="#006FEE"
        stroke-width="1.5"
      />
      <path
        d="M5.25 15.8337C3.86929 15.8337 2.75 14.7144 2.75 13.3337V8.33366C2.75 5.19096 2.75 3.61961 3.72631 2.6433C4.70262 1.66699 6.27397 1.66699 9.41667 1.66699H12.75C14.1307 1.66699 15.25 2.78628 15.25 4.16699"
        stroke="#006FEE"
        stroke-width="1.5"
      />
    </svg>
  );
};
export const DeleteIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8337 5H3.66699"
        stroke="#EE3342"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16.4446 7.08301L16.0613 12.8323C15.9138 15.0447 15.84 16.1509 15.1192 16.8253C14.3983 17.4997 13.2897 17.4997 11.0723 17.4997H10.4279C8.21054 17.4997 7.10187 17.4997 6.38103 16.8253C5.66019 16.1509 5.58644 15.0447 5.43895 12.8323L5.05566 7.08301"
        stroke="#EE3342"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.66699 9.16699L9.08366 13.3337"
        stroke="#EE3342"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12.8337 9.16699L12.417 13.3337"
        stroke="#EE3342"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.16699 5C6.21356 5 6.23684 5 6.25795 4.99947C6.94415 4.98208 7.54951 4.54576 7.78301 3.90027C7.79019 3.88041 7.79755 3.85832 7.81228 3.81415L7.89318 3.57143C7.96225 3.36423 7.99678 3.26063 8.04259 3.17267C8.22533 2.82173 8.56344 2.57803 8.95417 2.51564C9.0521 2.5 9.1613 2.5 9.37971 2.5H12.1209C12.3393 2.5 12.4486 2.5 12.5465 2.51564C12.9372 2.57803 13.2753 2.82173 13.4581 3.17267C13.5039 3.26063 13.5384 3.36423 13.6075 3.57143L13.6884 3.81415C13.7031 3.85826 13.7105 3.88042 13.7176 3.90027C13.9511 4.54576 14.5565 4.98208 15.2427 4.99947C15.2638 5 15.2871 5 15.3337 5"
        stroke="#EE3342"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const TrendingUpIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3348 1C15.3348 0.585786 15.6706 0.25 16.0848 0.25H21.667C22.0812 0.25 22.417 0.585786 22.417 1V6.54583C22.417 6.96005 22.0812 7.29583 21.667 7.29583C21.2528 7.29583 20.917 6.96005 20.917 6.54583V2.80286L14.7812 8.90129C14.3122 9.36744 13.908 9.76923 13.5409 10.0477C13.1474 10.3462 12.7102 10.572 12.172 10.572C11.6338 10.5719 11.1967 10.346 10.8032 10.0474C10.4362 9.76884 10.0321 9.36697 9.56328 8.90072L9.28902 8.62801C8.77486 8.11675 8.44151 7.78751 8.16394 7.57686C7.90371 7.37937 7.78205 7.35734 7.70149 7.35737C7.62092 7.3574 7.49927 7.37952 7.23919 7.5772C6.96177 7.78805 6.62866 8.11753 6.11488 8.62917L2.19621 12.5314C1.9027 12.8237 1.42783 12.8227 1.13555 12.5292C0.843273 12.2357 0.844268 11.7608 1.13777 11.4686L5.09133 7.53155C5.56025 7.06453 5.96441 6.66202 6.33151 6.383C6.72501 6.08391 7.16235 5.85756 7.70094 5.85737C8.23953 5.85717 8.67703 6.0832 9.07074 6.38199C9.43805 6.66074 9.84249 7.06296 10.3118 7.52963L10.586 7.80235C11.0997 8.31316 11.4328 8.64209 11.7101 8.85257C11.9701 9.04989 12.0916 9.07197 12.1722 9.07198C12.2527 9.07199 12.3743 9.04994 12.6343 8.85268C12.9117 8.64226 13.2448 8.31341 13.7586 7.80272L19.8485 1.75H16.0848C15.6706 1.75 15.3348 1.41421 15.3348 1Z"
        fill="#17C964"
      />
    </svg>
  );
};
export const TrendingDownIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.968559 0.470783C1.26084 0.177276 1.73571 0.176281 2.02922 0.468559L5.94789 4.37083C6.46167 4.88247 6.79478 5.21195 7.07219 5.4228C7.33228 5.62048 7.45393 5.6426 7.53449 5.64263C7.61506 5.64266 7.73672 5.62063 7.99695 5.42314C8.27452 5.21249 8.60787 4.88325 9.12203 4.37199L9.39627 4.09929C9.86511 3.63304 10.2692 3.23116 10.6362 2.9526C11.0297 2.654 11.4668 2.42808 12.005 2.42802C12.5432 2.42796 12.9804 2.65377 13.3739 2.95229C13.741 3.23077 14.1452 3.63256 14.6142 4.09871L20.75 10.1971V6.45417C20.75 6.03995 21.0858 5.70417 21.5 5.70417C21.9142 5.70417 22.25 6.03995 22.25 6.45417V12C22.25 12.4142 21.9142 12.75 21.5 12.75H15.9179C15.5036 12.75 15.1679 12.4142 15.1679 12C15.1679 11.5858 15.5036 11.25 15.9179 11.25H19.6815L13.5916 5.19728C13.0778 4.68659 12.7447 4.35774 12.4673 4.14732C12.2073 3.95006 12.0857 3.92801 12.0052 3.92802C11.9247 3.92803 11.8031 3.95011 11.5431 4.14743C11.2658 4.35791 10.9327 4.68684 10.419 5.19765L10.1448 5.47037C9.6755 5.93704 9.27105 6.33926 8.90375 6.61801C8.51003 6.9168 8.07254 7.14283 7.53395 7.14263C6.99535 7.14244 6.55802 6.91609 6.16452 6.617C5.79742 6.33798 5.39326 5.93547 4.92433 5.46845C4.91275 5.45691 4.90112 5.44533 4.88945 5.43372L0.970783 1.53144C0.677276 1.23916 0.676281 0.764289 0.968559 0.470783Z"
        fill="#F31260"
      />
    </svg>
  );
};

export const SearchIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4425_1018)">
        <circle
          cx="9.58366"
          cy="9.58366"
          r="7.91667"
          stroke="#A1A1AA"
          stroke-width="1.5"
        />
        <path
          d="M16.667 16.667L18.3337 18.3337"
          stroke="#A1A1AA"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4425_1018">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DragDropIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 19.9997C3.33301 12.1429 3.33301 8.21456 5.77378 5.77378C8.21456 3.33301 12.1429 3.33301 19.9997 3.33301C27.8564 3.33301 31.7848 3.33301 34.2256 5.77378C36.6663 8.21456 36.6663 12.1429 36.6663 19.9997"
        stroke="#A1A1AA"
        stroke-width="1.5"
      />
      <path
        d="M3.33301 23.3333C3.33301 18.6662 3.33301 16.3327 4.24129 14.5501C5.04023 12.9821 6.31507 11.7072 7.88309 10.9083C9.66568 10 11.9992 10 16.6663 10H23.333C28.0001 10 30.3337 10 32.1163 10.9083C33.6843 11.7072 34.9591 12.9821 35.7581 14.5501C36.6663 16.3327 36.6663 18.6662 36.6663 23.3333C36.6663 28.0004 36.6663 30.334 35.7581 32.1166C34.9591 33.6846 33.6843 34.9594 32.1163 35.7584C30.3337 36.6667 28.0001 36.6667 23.333 36.6667H16.6663C11.9992 36.6667 9.66568 36.6667 7.88309 35.7584C6.31507 34.9594 5.04023 33.6846 4.24129 32.1166C3.33301 30.334 3.33301 28.0004 3.33301 23.3333Z"
        stroke="#A1A1AA"
        stroke-width="1.5"
      />
      <path
        d="M19.9997 28.333L19.9997 18.333M19.9997 18.333L24.1663 22.4997M19.9997 18.333L15.833 22.4997"
        stroke="#A1A1AA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const UserIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.25C5.37665 0.25 3.25 2.37665 3.25 5C3.25 7.62335 5.37665 9.75 8 9.75C10.6234 9.75 12.75 7.62335 12.75 5C12.75 2.37665 10.6234 0.25 8 0.25ZM4.75 5C4.75 3.20507 6.20507 1.75 8 1.75C9.79493 1.75 11.25 3.20507 11.25 5C11.25 6.79493 9.79493 8.25 8 8.25C6.20507 8.25 4.75 6.79493 4.75 5Z"
        fill="#A1A1AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.25C5.96067 11.25 4.07752 11.7208 2.67815 12.5204C1.3 13.3079 0.25 14.5101 0.25 16C0.25 17.4899 1.3 18.6921 2.67815 19.4796C4.07752 20.2792 5.96067 20.75 8 20.75C10.0393 20.75 11.9225 20.2792 13.3219 19.4796C14.7 18.6921 15.75 17.4899 15.75 16C15.75 14.5101 14.7 13.3079 13.3219 12.5204C11.9225 11.7208 10.0393 11.25 8 11.25ZM1.75 16C1.75 15.2807 2.26701 14.483 3.42236 13.8228C4.55649 13.1747 6.17334 12.75 8 12.75C9.82666 12.75 11.4435 13.1747 12.5776 13.8228C13.733 14.483 14.25 15.2807 14.25 16C14.25 16.7193 13.733 17.517 12.5776 18.1772C11.4435 18.8253 9.82666 19.25 8 19.25C6.17334 19.25 4.55649 18.8253 3.42236 18.1772C2.26701 17.517 1.75 16.7193 1.75 16Z"
        fill="#A1A1AA"
      />
    </svg>
  );
};
export const CalendarIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
        fill="#A1A1AA"
      />
      <path
        d="M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z"
        fill="#A1A1AA"
      />
      <path
        d="M12 12C12 12.5523 11.5523 13 11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11C11.5523 11 12 11.4477 12 12Z"
        fill="#A1A1AA"
      />
      <path
        d="M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z"
        fill="#A1A1AA"
      />
      <path
        d="M6 13C6.55229 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
        fill="#A1A1AA"
      />
      <path
        d="M6 17C6.55229 17 7 16.5523 7 16C7 15.4477 6.55229 15 6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17Z"
        fill="#A1A1AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.26272C7.412 2.24999 8.14133 2.24999 8.94346 2.25H13.0564C13.8586 2.24999 14.588 2.24999 15.25 2.26272V1.5C15.25 1.08579 15.5858 0.75 16 0.75C16.4142 0.75 16.75 1.08579 16.75 1.5V2.32709C17.0099 2.34691 17.2561 2.37182 17.489 2.40313C18.6614 2.56076 19.6104 2.89288 20.3588 3.64124C21.1071 4.38961 21.4392 5.33855 21.5969 6.51098C21.75 7.65018 21.75 9.1058 21.75 10.9435V13.0564C21.75 14.8941 21.75 16.3498 21.5969 17.489C21.4392 18.6614 21.1071 19.6104 20.3588 20.3588C19.6104 21.1071 18.6614 21.4392 17.489 21.5969C16.3498 21.75 14.8942 21.75 13.0565 21.75H8.94359C7.10585 21.75 5.65018 21.75 4.51098 21.5969C3.33856 21.4392 2.38961 21.1071 1.64124 20.3588C0.89288 19.6104 0.560763 18.6614 0.403135 17.489C0.249972 16.3498 0.249985 14.8942 0.25 13.0564V10.9436C0.249985 9.10582 0.249972 7.65019 0.403135 6.51098C0.560763 5.33855 0.89288 4.38961 1.64124 3.64124C2.38961 2.89288 3.33856 2.56076 4.51098 2.40313C4.7439 2.37182 4.99006 2.34691 5.25 2.32709V1.5C5.25 1.08579 5.58579 0.75 6 0.75ZM4.71085 3.88976C3.70476 4.02502 3.12511 4.27869 2.7019 4.7019C2.27869 5.12511 2.02502 5.70476 1.88976 6.71085C1.86685 6.88123 1.8477 7.06061 1.83168 7.25H20.1683C20.1523 7.06061 20.1331 6.88124 20.1102 6.71085C19.975 5.70476 19.7213 5.12511 19.2981 4.7019C18.8749 4.27869 18.2952 4.02502 17.2892 3.88976C16.2615 3.75159 14.9068 3.75 13 3.75H9C7.09318 3.75 5.73851 3.75159 4.71085 3.88976ZM1.75 11C1.75 10.146 1.75032 9.40273 1.76309 8.75H20.2369C20.2497 9.40273 20.25 10.146 20.25 11V13C20.25 14.9068 20.2484 16.2615 20.1102 17.2892C19.975 18.2952 19.7213 18.8749 19.2981 19.2981C18.8749 19.7213 18.2952 19.975 17.2892 20.1102C16.2615 20.2484 14.9068 20.25 13 20.25H9C7.09318 20.25 5.73851 20.2484 4.71085 20.1102C3.70476 19.975 3.12511 19.7213 2.7019 19.2981C2.27869 18.8749 2.02502 18.2952 1.88976 17.2892C1.75159 16.2615 1.75 14.9068 1.75 13V11Z"
        fill="#A1A1AA"
      />
    </svg>
  );
};
export const MessageSquareIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
        fill="#A1A1AA"
      />
      <path
        d="M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z"
        fill="#A1A1AA"
      />
      <path
        d="M12 12C12 12.5523 11.5523 13 11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11C11.5523 11 12 11.4477 12 12Z"
        fill="#A1A1AA"
      />
      <path
        d="M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z"
        fill="#A1A1AA"
      />
      <path
        d="M6 13C6.55229 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
        fill="#A1A1AA"
      />
      <path
        d="M6 17C6.55229 17 7 16.5523 7 16C7 15.4477 6.55229 15 6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17Z"
        fill="#A1A1AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.26272C7.412 2.24999 8.14133 2.24999 8.94346 2.25H13.0564C13.8586 2.24999 14.588 2.24999 15.25 2.26272V1.5C15.25 1.08579 15.5858 0.75 16 0.75C16.4142 0.75 16.75 1.08579 16.75 1.5V2.32709C17.0099 2.34691 17.2561 2.37182 17.489 2.40313C18.6614 2.56076 19.6104 2.89288 20.3588 3.64124C21.1071 4.38961 21.4392 5.33855 21.5969 6.51098C21.75 7.65018 21.75 9.1058 21.75 10.9435V13.0564C21.75 14.8941 21.75 16.3498 21.5969 17.489C21.4392 18.6614 21.1071 19.6104 20.3588 20.3588C19.6104 21.1071 18.6614 21.4392 17.489 21.5969C16.3498 21.75 14.8942 21.75 13.0565 21.75H8.94359C7.10585 21.75 5.65018 21.75 4.51098 21.5969C3.33856 21.4392 2.38961 21.1071 1.64124 20.3588C0.89288 19.6104 0.560763 18.6614 0.403135 17.489C0.249972 16.3498 0.249985 14.8942 0.25 13.0564V10.9436C0.249985 9.10582 0.249972 7.65019 0.403135 6.51098C0.560763 5.33855 0.89288 4.38961 1.64124 3.64124C2.38961 2.89288 3.33856 2.56076 4.51098 2.40313C4.7439 2.37182 4.99006 2.34691 5.25 2.32709V1.5C5.25 1.08579 5.58579 0.75 6 0.75ZM4.71085 3.88976C3.70476 4.02502 3.12511 4.27869 2.7019 4.7019C2.27869 5.12511 2.02502 5.70476 1.88976 6.71085C1.86685 6.88123 1.8477 7.06061 1.83168 7.25H20.1683C20.1523 7.06061 20.1331 6.88124 20.1102 6.71085C19.975 5.70476 19.7213 5.12511 19.2981 4.7019C18.8749 4.27869 18.2952 4.02502 17.2892 3.88976C16.2615 3.75159 14.9068 3.75 13 3.75H9C7.09318 3.75 5.73851 3.75159 4.71085 3.88976ZM1.75 11C1.75 10.146 1.75032 9.40273 1.76309 8.75H20.2369C20.2497 9.40273 20.25 10.146 20.25 11V13C20.25 14.9068 20.2484 16.2615 20.1102 17.2892C19.975 18.2952 19.7213 18.8749 19.2981 19.2981C18.8749 19.7213 18.2952 19.975 17.2892 20.1102C16.2615 20.2484 14.9068 20.25 13 20.25H9C7.09318 20.25 5.73851 20.2484 4.71085 20.1102C3.70476 19.975 3.12511 19.7213 2.7019 19.2981C2.27869 18.8749 2.02502 18.2952 1.88976 17.2892C1.75159 16.2615 1.75 14.9068 1.75 13V11Z"
        fill="#A1A1AA"
      />
    </svg>
  );
};
export const LinkIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7388 2.26118C15.0572 -0.420394 10.7095 -0.420394 8.02792 2.26118L7.30707 2.98203C7.01418 3.27492 7.01418 3.7498 7.30707 4.04269C7.59997 4.33558 8.07484 4.33558 8.36773 4.04269L9.08858 3.32184C11.1844 1.22605 14.5823 1.22605 16.6781 3.32184C18.7739 5.41763 18.7739 8.81558 16.6781 10.9114L15.9573 11.6322C15.6644 11.9251 15.6644 12.4 15.9573 12.6929C16.2502 12.9858 16.725 12.9858 17.0179 12.6929L17.7388 11.972C20.4203 9.29045 20.4203 4.94276 17.7388 2.26118Z"
        fill="#A1A1AA"
      />
      <path
        d="M4.04269 7.30707C4.33558 7.59997 4.33558 8.07484 4.04269 8.36773L3.32184 9.08858C1.22605 11.1844 1.22605 14.5823 3.32184 16.6781C5.41763 18.7739 8.81558 18.7739 10.9114 16.6781L11.6322 15.9573C11.9251 15.6644 12.4 15.6644 12.6929 15.9573C12.9858 16.2501 12.9858 16.725 12.6929 17.0179L11.972 17.7388C9.29045 20.4203 4.94276 20.4203 2.26118 17.7388C-0.420394 15.0572 -0.420394 10.7095 2.26118 8.02792L2.98203 7.30707C3.27492 7.01418 3.7498 7.01418 4.04269 7.30707Z"
        fill="#A1A1AA"
      />
      <path
        d="M12.6928 7.30707C12.9857 7.59997 12.9857 8.07484 12.6928 8.36773L8.36773 12.6928C8.07484 12.9857 7.59997 12.9857 7.30707 12.6928C7.01418 12.3999 7.01418 11.9251 7.30707 11.6322L11.6322 7.30707C11.9251 7.01418 12.3999 7.01418 12.6928 7.30707Z"
        fill="#A1A1AA"
      />
    </svg>
  );
};
export const DownloadIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4425_1108)">
        <path
          d="M14.1665 7.50195C15.979 7.51204 16.9606 7.59242 17.6009 8.23274C18.3332 8.96497 18.3332 10.1435 18.3332 12.5005V13.3338C18.3332 15.6909 18.3332 16.8694 17.6009 17.6016C16.8687 18.3338 15.6902 18.3338 13.3332 18.3338H6.6665C4.30948 18.3338 3.13097 18.3338 2.39874 17.6016C1.6665 16.8694 1.6665 15.6909 1.6665 13.3338L1.6665 12.5005C1.6665 10.1435 1.6665 8.96497 2.39874 8.23274C3.03906 7.59242 4.02065 7.51204 5.83317 7.50195"
          stroke="#006FEE"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M10 1.66699L10 12.5003M10 12.5003L7.5 9.58366M10 12.5003L12.5 9.58366"
          stroke="#006FEE"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4425_1108">
          <rect width="20" height="20" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const MoreVertical = ({ color = "black", className }) => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.99984 3.83333C1.07936 3.83333 0.333171 3.08714 0.333171 2.16667C0.333171 1.24619 1.07936 0.5 1.99984 0.5C2.92031 0.5 3.6665 1.24619 3.6665 2.16667C3.6665 3.08714 2.92031 3.83333 1.99984 3.83333Z"
        fill={color}
      />
      <path
        d="M1.99984 9.66667C1.07936 9.66667 0.333171 8.92047 0.333171 8C0.333171 7.07952 1.07936 6.33333 1.99984 6.33333C2.92031 6.33333 3.6665 7.07952 3.6665 8C3.6665 8.92047 2.92031 9.66667 1.99984 9.66667Z"
        fill={color}
      />
      <path
        d="M1.99984 15.5C1.07936 15.5 0.333171 14.7538 0.333171 13.8333C0.333171 12.9129 1.07936 12.1667 1.99984 12.1667C2.92031 12.1667 3.6665 12.9129 3.6665 13.8333C3.6665 14.7538 2.92031 15.5 1.99984 15.5Z"
        fill={color}
      />
    </svg>
  );
};
export const SaveIcon = ({ color = "black", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4551_592)">
        <path
          d="M2.88689 17.1123C4.10728 18.3327 6.07147 18.3327 9.99984 18.3327C13.9282 18.3327 15.8924 18.3327 17.1128 17.1123C18.3332 15.8919 18.3332 13.9277 18.3332 9.99935C18.3332 9.7148 18.3332 9.57252 18.3205 9.42785C18.2617 8.75342 17.9882 8.09316 17.5529 7.57463C17.4595 7.4634 17.3565 7.36032 17.1503 7.15416L12.845 2.84888C12.6389 2.64273 12.5358 2.53964 12.4246 2.44628C11.906 2.01101 11.2458 1.73752 10.5713 1.67864C10.4267 1.66602 10.2844 1.66602 9.99984 1.66602C6.07147 1.66602 4.10728 1.66602 2.88689 2.8864C1.6665 4.10679 1.6665 6.07098 1.6665 9.99935C1.6665 13.9277 1.6665 15.8919 2.88689 17.1123Z"
          fill="#17C964"
          fill-opacity="0.2"
          stroke="#17C964"
          stroke-width="1.5"
        />
        <path
          d="M14.1668 18.3327V17.4993C14.1668 15.928 14.1668 15.1423 13.6787 14.6542C13.1905 14.166 12.4048 14.166 10.8335 14.166H9.16683C7.59548 14.166 6.80981 14.166 6.32165 14.6542C5.8335 15.1423 5.8335 15.928 5.8335 17.4993V18.3327"
          stroke="#17C964"
          stroke-width="1.5"
        />
        <path
          d="M5.8335 6.66602H10.8335"
          stroke="#17C964"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4551_592">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
