import React from "react";
import { formatDate } from "../../helper/helper";
import tick from "../../assets/icons/smallGreenTick.svg";
import { useThemeContext } from "../../context/ThemeContext";

const FileUploadComment = ({ timestamp }) => {
  return (
    <div className="flex">
      <div className="flex flex-col items-center">
        <img src={tick} alt="icon" className="z-10" />
      </div>

      <div className="flex flex-col ml-3 text-left">
        <div className="flex items-center gap-2">
          <span className="text-xs font-medium text-[#A1A1AA]">
            {timestamp}
          </span>
          <span className="text-sm font-medium text-[#17C964]">
            File Created
          </span>
        </div>
      </div>
    </div>
  );
};

const Comment = ({ number, name, timestamp, message, isLast, handleClick }) => {
  const { theme } = useThemeContext();
  const isDark = theme === 'dark';

  return (
    <div className="flex cursor-pointer group" onClick={handleClick}>
      <div className="flex flex-col items-center">
        <div className="w-6 h-6 rounded-full bg-[#006FEE] z-10 flex items-center justify-center text-white text-sm group-hover:ring-2 group-hover:ring-blue-200 transition-all">
          {number}
        </div>
      </div>

      <div className="flex flex-col ml-3 text-left">
        <div className={`rounded-2xl p-2 transition-colors ${
          isDark 
            ? 'bg-gray-800 group-hover:bg-gray-700 active:bg-gray-600' 
            : 'bg-[#FAFAFA] group-hover:bg-gray-100 active:bg-gray-200'
        }`}>
          <span className="text-xs font-medium text-[#A1A1AA]">
            {timestamp}
          </span>
          <h3 className={`text-base font-bold mt-1 ${
            isDark ? 'text-white' : 'text-black'
          }`}>
            {name}
          </h3>
          <p className={`text-sm font-medium mt-1 ${
            isDark ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

const CommentThread = ({ comments, document, onReviewClick }) => {
  const { theme } = useThemeContext();
  const isDark = theme === 'dark';

  return (
    <div className="relative flex flex-col gap-8">
      {/* Continuous vertical line */}
      <div className={`absolute left-[11px] top-0 bottom-0 border-l-2 ${
        isDark ? 'border-gray-700' : 'border-[#D4D4D8]'
      }`} />

      {/* Regular Comments */}
      {comments.map((comment, index) => (
        <Comment
          key={index}
          number={comment.id}
          name={`user ${comment.id}`}
          timestamp={formatDate(comment.created_at)}
          message={
            comment.misc.additional_notes || comment.misc.key_takeaways || ""
          }
          isLast={index === comments.length - 1}
          handleClick={() => onReviewClick(comment)}
        />
      ))}
      {/* File Upload Comment */}
      <FileUploadComment timestamp={formatDate(document.created_at)} />
    </div>
  );
};

export default CommentThread;