import React from "react";
import "./App.css";
import "./styles/CardCarousel.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { publicRoutes, protectedRoutes } from "./routes/config";
import { PATHS } from "./routes/paths";
import Navbar from "./components/Navbar";
import RouteGuard from "./components/RouteGuard";
import DashboardLayout from "./layout/DashboardLayout";
import { AuthProvider } from "./context/AuthContext";
import { DocumentProvider } from "./context/DocumentContext";
import { BreadcrumbProvider } from "./context/BreadcrumbContext";
import { SessionProvider } from "./context/SessionContext";
import { ToastContainer } from "react-toastify";
import { pdfjs } from "react-pdf";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useThemeContext } from "./context/ThemeContext";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

function App() {
  const { theme } = useThemeContext();
  const location = useLocation();

  // Set the worker path
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const renderNavbar = () => {
    if (location.pathname === PATHS.PUBLIC.LOGIN) {
      return;
    }

    return <Navbar />;
  };

  return (
    <main className={`${theme} text-foreground bg-background`}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <SessionProvider>
            <BreadcrumbProvider>
              <DocumentProvider>
                <ToastContainer />
                <div className="App">
                  {renderNavbar()}
                  <div
                    className={`${
                      location.pathname === PATHS.PUBLIC.LOGIN ? "" : "mt-24"
                    }`}
                  >
                    <Routes>
                      {/* Public Routes */}
                      {publicRoutes.map(({ path, element: Element }) => (
                        <Route key={path} path={path} element={<Element />} />
                      ))}

                      {/* Protected Routes */}
                      {protectedRoutes.map(
                        ({ path, element: Element, layout: Layout }) => (
                          <Route
                            key={path}
                            path={path}
                            element={
                              <RouteGuard
                                element={Element}
                                layout={Layout || undefined} // Don't pass anything if Layout is undefined
                              />
                            }
                          />
                        )
                      )}
                    </Routes>
                  </div>
                </div>
              </DocumentProvider>
            </BreadcrumbProvider>
          </SessionProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </main>
  );
}

export default App;
