// Summary.jsx
import { Button } from "@heroui/react";
import { SUMMARY_TEXT } from "../../constants/constant";
import { StarIcon } from "../../assets/icons/icons";
import tickIcon from "../../assets/icons/greenTick.svg";
import SummaryDocument from "../SummaryDocument";

const Summary = ({ handleStartOver, summary }) => {
  const getSeverityColor = (severity) => {
    switch (severity) {
      case "high":
        return "#FF4646";
      case "medium":
        return "#FFA500";
      case "low":
        return "#39F2AE";
      default:
        return "#FFFFFF";
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case "visual":
        return "👁️";
      case "content":
        return "📝";
      case "technical":
        return "⚙️";
      case "accessibility":
        return "♿";
      case "structure":
        return "🏗️";
      default:
        return "📌";
    }
  };

  // Check if summary has valid data
  const hasSummaryData =
    summary &&
    (summary.feedback_summary?.length > 0 ||
      summary.misc?.key_takeaways ||
      summary.misc?.additional_notes);

  return (
    <>
      <div className="flex flex-col items-start flex-1">
        <div className="w-full">
          <div className="m-auto">
            <div className="text-center flex justify-center mb-6">
              <img src={tickIcon} alt="icon" />
            </div>
            <h2 className="font-semibold text-[40px]">
              {SUMMARY_TEXT.heading}
            </h2>
            <p className="font-medium text-base text-[#71717A]">
              {SUMMARY_TEXT.subHeading}
            </p>
            <div className="mt-6">
              {/* Show SummaryDocument if there's any valid data */}
              {hasSummaryData && <SummaryDocument data={summary} />}

              {/* <CustomButton
                handleButtonClick={handleStartOver}
                text={SUMMARY_TEXT.startText}
                backgroundColor="#39F2AE"
                glow={true}
                textColor="white"
                height="48px"
                radius="25px"
                icon={starIcon}
                iconPosition="start"
                gap="12px"
              /> */}
            </div>
          </div>
          <div className="mt-[54px] text-left">
            {summary?.feedback_summary?.length > 0 && (
              <div className="w-full py-4 ">
                <div className="container-95">
                  {summary.feedback_summary.map((issue, i) => (
                    <div key={i} className="py-[16px]">
                      <div className="flex items-center gap-4 mb-3">
                        <div className="bg-[#006FEE] text-white px-3 py-1 rounded text-sm font-medium">
                          Page {issue.pageNumber}
                        </div>
                        <div
                          className="text-sm font-semibold uppercase"
                          style={{ color: getSeverityColor(issue.severity) }}
                        >
                          {issue.severity}
                        </div>
                        <div className="flex items-center gap-1.5 text-sm capitalize">
                          {getTypeIcon(issue.type)} {issue.type}
                        </div>
                      </div>
                      <h3 className="font-semibold text-2xl">
                        {issue.issueTitle}
                      </h3>
                      <p className="font-medium text-base text-[#71717A]">
                        {issue.issueExplanation}
                      </p>
                      {issue.context && (
                        <div className="rounded-lg p-3 mt-3">
                          <p className="text-base">{issue.context}</p>
                        </div>
                      )}
                      {i !== summary.feedback_summary.length - 1 && (
                        <hr className="border-0 h-px bg-[#D4D4D8] my-5" />
                      )}
                    </div>
                  ))}

                  {summary.misc && (
                    <div className="mt-6">
                      <hr className="border-0 h-px bg-[#D4D4D8] my-5" />
                      {summary.misc.key_takeaways && (
                        <div className="mt-6 first:mt-0">
                          <h3 className="font-semibold text-2xl">
                            Key Takeaways
                          </h3>
                          <p className="font-medium text-base text-[#71717A]">
                            {summary.misc.key_takeaways}
                          </p>
                        </div>
                      )}
                      <hr className="border-0 h-px bg-[#D4D4D8] my-5" />
                      {summary.misc.additional_notes && (
                        <div className="mt-6 first:mt-0">
                          <h3 className="font-semibold text-2xl">
                            Additional Notes
                          </h3>
                          <p className="font-medium text-base text-[#71717A]">
                            {summary.misc.additional_notes}
                          </p>
                        </div>
                      )}
                      {summary.misc.context_insights && (
                        <div className="mt-6 first:mt-0">
                          <h3 className="font-semibold text-2xl">
                            Context Insights
                          </h3>
                          <p className="font-medium text-base text-[#71717A]">
                            {summary.misc.context_insights}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center w-full my-5">
          <Button
            color="primary"
            startContent={<StarIcon />}
            onPress={handleStartOver}
          >
            {SUMMARY_TEXT.newText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Summary;
