import React from 'react';
import { useThemeContext } from "../../context/ThemeContext";

const StepItem = ({ number, text }) => {
  const { isDark } = useThemeContext();
  
  const paragraphStyle = isDark 
    ? "font-light text-[16px] text-white/90" 
    : "font-light text-[16px] text-black/90";
  
  return (
    <div className="mb-4 flex gap-4 items-center">
     <div className="w-5 h-5 rounded-full bg-[#006fee] flex justify-center items-center text-sm font-normal shrink-0 text-white">
        {number}
      </div>
      <p className={paragraphStyle}>{text}</p>
    </div>
  );
};

export default StepItem;