import React from "react";
import { TrendingUpIcon, TrendingDownIcon } from "../../assets/icons/icons";



const StatBox = ({ title, value, percentage, isPositive }) => {
  return (
    <div className="flex-1 space-y-4">
      <h3 className="text-base font-medium text-gray-500">{title}</h3>
      <p className="text-6xl font-bold">{value}</p>
      <div className="flex items-center gap-1 justify-center">
        <span
          className={`text-sm font-semibold ${
            isPositive ? "text-[#17C964]" : "text-[#F31260]"
          }`}
        >
          {Math.abs(percentage)}%
        </span>
        {isPositive ? (
          <TrendingUpIcon className="w-4 h-4 text-[#17C964]" />
        ) : (
          <TrendingDownIcon className="w-4 h-4 text-[#F31260]" />
        )}
      </div>
    </div>
  );
};

export default StatBox;
