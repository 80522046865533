import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getBriefDocumentApi,
  updateBriefDocumentSectionAnswerApi,
  processAIWritingApi,
} from "../../../api/briefApi";
import BriefInfo from "../../../components/BriefInfo";
import DocumentContainer from "../../../components/DocumentSection";
import { timeAgo } from "../../../helper/helper";
import { Button } from "@heroui/react";
import { DownloadIcon, SaveIcon } from "../../../assets/icons/icons";
import toaster from "../../../components/Toast/Toast";

const BriefDocument = () => {
  const { briefId } = useParams();

  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSections, setEditedSections] = useState({});
  const [saving, setSaving] = useState(false);
  const [aiProcessing, setAiProcessing] = useState(false);

  // Map to store editor instances by section ID.
  const editorMap = useRef(new Map());

  const fetchDocument = async () => {
    if (!briefId) return;

    try {
      setLoading(true);
      const response = await getBriefDocumentApi(briefId);
      console.log("API Response:", response);
      setDocument(response.data);
      setError(null);
    } catch (err) {
      console.error("Failed to fetch document:", err);
      if (err.status === 404) {
        setDocument(null);
      } else {
        setError(
          err.message || "An error occurred while fetching the document"
        );
        toaster.error(
          err.message || "An error occurred while fetching the document"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocument();

    // Clear editor references when component unmounts
    return () => {
      editorMap.current.clear();
    };
  }, [briefId]);

  const handleEditorReady = (sectionId, editor) => {
    editorMap.current.set(sectionId, editor);
  };

  const handleEditClick = () => {
    if (document && document.content && document.content.sections) {
      const initialEditState = {};
      document.content.sections.forEach((section) => {
        // Make sure we're correctly initializing with the current section content
        console.log(
          `Setting initial state for section ${section.section_id}:`,
          section.answer
        );
        initialEditState[section.section_id] = section.answer || "";
      });
      console.log("Initial edit state:", initialEditState);
      setEditedSections(initialEditState);
    }
    setIsEditing(true);
    toaster.info("You are now in edit mode");
  };

  const handleSaveClick = async () => {
    if (
      !document ||
      !document.content ||
      !document.content.sections ||
      !briefId
    ) {
      console.error("Missing required data for save operation");
      return;
    }

    try {
      setSaving(true);

      // Debug: Print all section IDs and their values
      Object.entries(editedSections).forEach(([id, value]) => {
        console.log(`Section ${id} current value:`, value);
      });

      const updatePromises = [];

      for (const [sectionId, editedAnswer] of Object.entries(editedSections)) {
        const originalSection = document.content.sections.find(
          (s) => s.section_id.toString() === sectionId.toString()
        );

        if (!originalSection) {
          console.error(`Section with ID ${sectionId} not found in document`);
          continue;
        }

        // Always update if we have content, don't rely on comparison
        if (editedAnswer) {
          updatePromises.push(
            updateBriefDocumentSectionAnswerApi(
              briefId,
              sectionId,
              editedAnswer
            )
              .then((response) => {
                return response;
              })
              .catch((error) => {
                console.error(`Error updating section ${sectionId}:`, error);
                throw error;
              })
          );
        } else {
          console.log(`No content for section ${sectionId}, skipping`);
        }
      }

      if (updatePromises.length === 0) {
        setIsEditing(false);
        return;
      }

      const results = await Promise.all(updatePromises);

      await fetchDocument();

      setIsEditing(false);
      toaster.success("Document updated successfully");
    } catch (error) {
      console.error("Failed to save document changes:", error);
      toaster.error(error.message || "Failed to save changes");
    } finally {
      setSaving(false);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedSections({});
    toaster.info("Editing cancelled");
  };

  const handleSectionChange = (sectionId, newAnswer) => {
    console.log(`Updating section ${sectionId} with new content:`, newAnswer);
    setEditedSections((prev) => {
      const updated = {
        ...prev,
        [sectionId]: newAnswer,
      };
      console.log("Updated editedSections:", updated);
      return updated;
    });
  };

  // Enhanced AI writing handler that directly manipulates the editor
  const handleWriteWithAI = async (
    selectedText,
    sectionId,
    templateId,
    templateDetail
  ) => {
    console.log("AI writing request received:", {
      selectedText,
      sectionId,
      templateId,
      templateDetail,
    });

    // Early validation
    if (!selectedText || !sectionId) {
      console.error("Missing required parameters for AI writing");
      toaster.error("Could not process AI request: missing data");
      return;
    }

    // Get the editor instance for this section
    const editor = editorMap.current.get(sectionId);
    if (!editor) {
      console.error(`Editor for section ${sectionId} not found`);
      toaster.error("Could not find editor for this section");
      return;
    }

    // Show loading state
    setAiProcessing(true);
    toaster.info(`Processing AI request: ${templateId || "custom"}`);

    try {
      // Call the API to process the text with Claude
      const response = await processAIWritingApi(
        briefId,
        sectionId,
        selectedText,
        templateId,
        templateDetail
      );

      // Get the processed text from the response
      const processedText = response.data.processedText;

      // Highlight the AI-generated content
      const highlightedAIContent = `<span style="background-color: rgba(144, 238, 144, 0.2)">${processedText}</span>`;

      // Use Tiptap's API to replace the selected text at cursor position
      editor
        .chain()
        .focus()
        .deleteSelection()
        .insertContent(highlightedAIContent)
        .run();

      toaster.success("AI content added successfully");
    } catch (error) {
      console.error("Error processing AI request:", error);
      toaster.error(error.message || "Failed to process AI request");
    } finally {
      setAiProcessing(false);
    }
  };


  if (!briefId) {
    return <div className="p-6">No brief ID provided</div>;
  }

  if (loading) {
    return <div className="p-6 text-center">Loading document...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-600">Error: {error}</div>;
  }

  return (
    <div className="mx-auto p-6 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {document && document.title ? document.title : "Brief Document"}
        </h1>

        <div className="flex gap-3">
          {isEditing ? (
            <>
              <Button
                className="bg-[#17C96433] text-[#17C964] font-bold text-base"
                startContent={<SaveIcon />}
                onPress={handleSaveClick}
                isLoading={saving}
                isDisabled={saving || aiProcessing}
              >
                Save
              </Button>
              <Button
                className="bg-gray-200 text-gray-700 font-bold text-base"
                onPress={handleCancelEdit}
                isDisabled={saving || aiProcessing}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                className="bg-[#006FEE33] text-[#006FEE] font-bold text-base"
                onPress={handleEditClick}
                startContent={<DownloadIcon />}
              >
                Edit
              </Button>
              <Button
                className="bg-[#006FEE33] text-[#006FEE] font-bold text-base"
                startContent={<DownloadIcon />}
              >
                Download
              </Button>
            </>
          )}
        </div>
      </div>

      {aiProcessing && (
        <div className="mb-4 p-3 bg-blue-100 text-blue-800 rounded-lg flex items-center">
          <div className="animate-spin mr-2 h-5 w-5 border-b-2 border-blue-800 rounded-full"></div>
          <span>AI is processing your request...</span>
        </div>
      )}

      {document && document.content && (
        <div className="flex gap-6">
          <div className="w-[75%]">
            <DocumentContainer
              document={document}
              isEditing={isEditing}
              editedSections={editedSections}
              onSectionChange={handleSectionChange}
              onWriteWithAI={handleWriteWithAI}
              onEditorReady={handleEditorReady}
            />
          </div>
          <div className="w-[25%]">
            <BriefInfo
              templateType={document.content.title || ""}
              createdBy={document.content.created_by.name || ""}
              dateCreated={timeAgo(document.created_at) || "2 Days Ago"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BriefDocument;
