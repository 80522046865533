import React, { useState} from "react";
import FeedbackPopup from "../FeedbackPopup";
import ReviewCard from "../ReviewCard";
import InputOptions from "../InputOptions";
import { CHAT_TEXT } from "../../constants/constant";
import recordMiniIcon from "../../assets/icons/recordMini.svg";
import typeMiniIcon from "../../assets/icons/typeMini.svg";
import toaster from "../Toast/Toast";

const ReviewFeedback = ({ setStep, summary, setSummary }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [mode, setMode] = useState("add");



  const handleOptionClick = (option) => {
    if (option === "type") {
      handleAddNew();
    } else if (option === "record") {
      setStep(5);
    }
  };

  const handleEdit = (data, originalItem) => {
    setEditingData({
      title: data.title,
      description: data.text || data.description,
      page: data.pageNumber || data.page,
      originalItem: originalItem,
    });
    setMode("edit");
    setIsPopupOpen(true);
  };

  const handleAddNew = () => {
    setEditingData(null);
    setMode("add");
    setIsPopupOpen(true);
  };

  const handleDelete = (itemToDelete) => {
    setSummary((prevSummary) => {
      // Create a copy of the previous summary
      const newSummary = { ...prevSummary };

      // Find the index of the item to delete by matching all relevant properties
      const itemIndex = newSummary.feedback_summary.findIndex(
        (item) =>
          item.pageNumber === itemToDelete.pageNumber &&
          item.issueTitle === itemToDelete.issueTitle &&
          item.issueExplanation === itemToDelete.issueExplanation
      );

      // If item found, remove it from the array
      if (itemIndex !== -1) {
        newSummary.feedback_summary = [
          ...newSummary.feedback_summary.slice(0, itemIndex),
          ...newSummary.feedback_summary.slice(itemIndex + 1),
        ];
      }

      return newSummary;
    });
  };

  const isMatchingItem = (item, originalItem) => {
    return (
      item.pageNumber === originalItem.pageNumber &&
      item.issueTitle === originalItem.issueTitle &&
      item.issueExplanation === originalItem.issueExplanation
    );
  };
  
  const handleConfirm = (formData) => {
    try {
      // Validate formData
      if (!formData || !formData.pageNumber || !formData.title || !formData.text) {
        console.error('Invalid form data:', formData);
        toaster.error('Please fill in all required fields');
        return;
      }
  
      setSummary(prevSummary => {
        // Handle case where prevSummary or feedback_summary is undefined
        if (!prevSummary || !prevSummary.feedback_summary) {
          return {
            ...prevSummary,
            feedback_summary: [{
              pageNumber: formData.pageNumber,
              issueTitle: formData.title,
              issueExplanation: formData.text,
              severity: "low",
              type: "content"
            }]
          };
        }
  
        // Handle edit mode
        if (mode === "edit" && editingData?.originalItem) {
          return {
            ...prevSummary,
            feedback_summary: prevSummary.feedback_summary.map(item => 
              isMatchingItem(item, editingData.originalItem)
                ? {
                    ...item,
                    pageNumber: formData.pageNumber,
                    issueTitle: formData.title,
                    issueExplanation: formData.text
                  }
                : item
            )
          };
        }
  
        // Handle add mode
        return {
          ...prevSummary,
          feedback_summary: [
            ...prevSummary.feedback_summary,
            {
              pageNumber: formData.pageNumber,
              issueTitle: formData.title,
              issueExplanation: formData.text,
              severity: "low",
              type: "content"
            }
          ]
        };
      });
  
      // Close popup after successful update
      setIsPopupOpen(false);
      
      // Clear editing data
      setEditingData(null);
  
      // Show success message
      toaster.success(mode === "edit" ? "Feedback updated successfully" : "Feedback added successfully");
  
    } catch (error) {
      console.error('Error in handleConfirm:', error);
      toaster.error('Failed to update feedback');
    }
  };

  return (
    <div className='relative'>
      <FeedbackPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleConfirm}
        initialData={editingData}
        mode={mode}
      />
      <div className="mb-6">
        <InputOptions
          onRecordClick={() => handleOptionClick("record")}
          onTypeClick={() => handleOptionClick("type")}
          recordIcon={recordMiniIcon}
          typeIcon={typeMiniIcon}
        />
      </div>

      <div className='max-h-full h-[50vh] min-h-[280px] overflow-auto flex flex-col gap-4'>
        {summary?.feedback_summary?.length > 0 &&
          summary.feedback_summary.map((el, i) => (
            <ReviewCard
              pageNumber={el.pageNumber}
              key={i}
              title={el.issueTitle}
              text={el.issueExplanation}
              showActions={true}
              onEdit={() =>
                handleEdit(
                  {
                    title: el.issueTitle,
                    description: el.issueExplanation,
                    page: el.pageNumber,
                  },
                  el
                )
              }
              onDelete={() => handleDelete(el)}
            />
          ))}
        {summary?.general_feedback && (
          <>
            <p className="font-medium text-sm text-[#5D6673]">
              {CHAT_TEXT.feedbackReviewHelperTitle}
            </p>
            <ReviewCard text={summary.general_feedback} showActions={false} />
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewFeedback;
