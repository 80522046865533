import React from 'react';
import { useThemeContext } from "../../context/ThemeContext";

const DefaultDotText = ({ text = "Default" }) => {
  const { isDark } = useThemeContext();
  
  // Determine dot color based on text
  const getDotColor = () => {
    switch (text.toLowerCase()) {
      case 'processing':
        return 'bg-[#1890FF]';
      case 'success':
        return 'bg-[#52C41A]';
      default:
        return 'bg-[#D9D9D9]';
    }
  };
  
  // Determine text color based on mode and status
  const getTextColor = () => {
    if (isDark) {
      switch (text.toLowerCase()) {
        case 'processing':
          return 'text-[#1890FF]';
        case 'success':
          return 'text-[#52C41A]';
        default:
          return 'text-white/85';
      }
    } else {
      switch (text.toLowerCase()) {
        case 'processing':
          return 'text-[#1890FF]';
        case 'success':
          return 'text-[#52C41A]';
        default:
          return 'text-black/85';
      }
    }
  };
  
  const dotStyle = `w-1.5 h-1.5 rounded-full ${getDotColor()} mr-2`;
  const textStyle = `font-normal text-sm ${getTextColor()}`;
  
  return (
    <div className="flex items-center mt-2">
      <div className={dotStyle}></div>
      <span className={textStyle}>{text}</span>
    </div>
  );
};

export default DefaultDotText;