import React from "react";
import { useThemeContext } from "../../context/ThemeContext";

const CommonBox = ({
  children,
  paddingTop = "20px",
  paddingBottom = "20px",
  padding = "0",
  width = "100%",
}) => {
  const { theme } = useThemeContext();
  const styles = {
    backgroundColor: theme === "light" ? "#FAFAFA" : "rgba(13,20,28,0.7)",
    paddingTop,
    paddingBottom,
    width,
    padding
  };

  return (
    <div
      className="
        rounded-[20px]
        border-divider
        max-h-[calc(100vh-120px)]
        box-border
        flex
        flex-col
        relative
        overflow-scroll
      "
      style={styles}
    >
      {children}
    </div>
  );
};

export default CommonBox;
