export const PATHS = {
    PUBLIC: {
      HOME: '/',
      LOGIN: '/login',
    },
    PROTECTED: {
      DASHBOARD: '/dashboard',
      FEEDBACK: '/feedback',
      FEEDBACK_FILE: '/feedback/:filename',
      FEEDBACK_GUEST: '/feedback/:filename/:guestId',
      SETTINGS: '/settings',
      ACCOUNT: '/account',
      HELP: '/help',
      BRIEFEDITORNEW: '/briefs/new/:templateId',
      BRIEFEDITOR: '/briefs/:briefId',
      BRIEF: '/brief',
      BRIEFEDIT:'/brief/document/:briefId'
    }
};