import React, { useState, useEffect } from "react";
import CommonBox from "../CommonBox";
import NavigationBar from "../NavigationBar";
import fileIcon from "../../assets/icons/fileIcon.svg";
import { PDF_TEXT } from "../../constants/constant";
import { Document, Page } from "react-pdf";
import { convertISOToCustomDate } from "../../helper/helper";
import saveIcon from "../../assets/icons/saveIcon.svg";
import addIcon from "../../assets/icons/addIcon.svg";
import { useAuth } from "../../context/AuthContext";

const canvasStyle = {
  borderRadius: '20px', // or any other value you prefer
};

const PdfBox = ({
  numPages,
  setNumPages,
  pageNumber,
  setPageNumber,
  pdfFile,
  setPdfFile,

}) => {
  
  const { document: fileDocument } = useAuth();
  const [loading, setLoading] = useState(true);
  const [pageScale, setPageScale] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);

  // Fixed height but width will be calculated
  const CONTAINER_HEIGHT = 470;

  // Add resize handler
  useEffect(() => {
    const updateWidth = () => {
      const container = document.getElementById("pdf-container");
      if (container) {
        setContainerWidth(container.clientWidth);
      }
    };

    // Initial width calculation
    updateWidth();

    // Update width on resize
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function onPageLoadSuccess({ width: originalWidth, height: originalHeight }) {
    if (loading && containerWidth) {
      // Only calculate scale once when page first loads and container width is available
      // Calculate scale ratios
      const widthScale = containerWidth / originalWidth;
      const heightScale = CONTAINER_HEIGHT / originalHeight;

      // Use the smaller scale to ensure page fits in both dimensions
      const newScale = Math.min(widthScale, heightScale, 1);
      setPageScale(newScale + .01);
      setLoading(false);
    }
  }

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfFile(file); // Set the selected PDF file
    }
  };

  // Handle drag and drop
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setPdfFile(file); // Set the dropped PDF file
    }
  };

  // Handle drag over event (to allow drop)
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Remove PDF file
  // const removePdfFile = () => {
  //   setPdfFile(null); // Clear the PDF file state
  //   setNumPages(null); // Reset number of pages
  //   setPageNumber(1); // Reset page number
  // };

  const handleNext = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevState) => prevState + 1);
    }
  };

  // Navigate to the previous page
  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber((prevState) => prevState - 1);
    }
  };
  const handleDownload = async () => {
    if (!fileDocument?.file_url) {
      console.error("No file URL available");
      return;
    }

    try {
      const response = await fetch(fileDocument.file_url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.style.display = "none";
      link.href = downloadUrl;
      link.download = fileDocument.title || "document.pdf"; // Set your desired filename

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <CommonBox width="69.22%" paddingTop="0" paddingBottom="0" padding="16px">
      {fileDocument && (
        <div className="flex justify-between items-start w-full text-left">
          <div>
            {/* Title - Weight 700, Size 24px */}
            <h1 className="font-bold text-2xl">Review: {fileDocument.title}</h1>

            {/* Date - Weight 500, Size 14px */}
            <p className="font-medium text-sm mt-2">
              Date: {convertISOToCustomDate(fileDocument.created_at)}
            </p>

            {/* Subtitle - Weight 500, Size 14px */}
            <p className="font-medium text-sm mt-1">
              Creator: {fileDocument.userName}
            </p>
          </div>
          <div className="flex flex-row gap-1">
            <div
              onClick={handleDownload}
              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
            >
              <img src={addIcon} alt="download" className="w-6 h-6" />
            </div>
            <div
              onClick={handleDownload}
              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
            >
              <img src={saveIcon} alt="save" className="w-6 h-6" />
            </div>
          </div>
        </div>
      )}
      <div
        id="pdf-container"
        className={
          !pdfFile
            ? "flex justify-center items-center h-full w-full flex-1"
            : "flex justify-center items-center w-full h-[470px] overflow-hiden my-4"
        }
      >
        {!pdfFile ? (
          <div
            className="h-[172px] w-[148px] rounded-2xl border border-[#3c3c3c] flex justify-center items-center cursor-pointer"
            onClick={() => document.getElementById("fileInput").click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="application/pdf"
              onChange={handleFileSelect}
            />
            <div>
              <img src={fileIcon} alt="file" />
              <p className="m-0 text-sm">{PDF_TEXT.fileText}</p>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="canvas"
              loading={<div>Loading...</div>}
            >
              <Page
                pageNumber={pageNumber}
                className="flex justify-center items-center !bg-[transparent]"
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={onPageLoadSuccess}
                scale={pageScale}
                loading={<div>Loading page...</div>}
                canvasRef={(ref) => {
                  if (ref) {
                    Object.assign(ref.style, canvasStyle);
                  }
                }}
              />
            </Document>
          </div>
        )}
      </div>
      {pdfFile && (
        <NavigationBar
          currentPage={pageNumber}
          totalPages={numPages}
          onPrevPage={() => handlePrevious()}
          onNextPage={() => handleNext()}
          onAction={() => handleDownload()}
          actionLabel="Download"
        />
      )}
    </CommonBox>
  );
};

export default PdfBox;
