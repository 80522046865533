import React, { useRef, useEffect, useState } from 'react';
import styles from './ScrollingTitle.module.css';

const ScrollingTitle = ({ title, theme }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(20);

  useEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const textWidth = textRef.current.offsetWidth;
      setShouldScroll(textWidth > containerWidth);
      setAnimationDuration(Math.max(20, textWidth / 50));
    }
  }, [title]);

  return (
    <div
      ref={containerRef}
      className="relative w-full overflow-hidden whitespace-nowrap mb-5"
    >
      <div
        ref={textRef}
        className={`text-3xl font-semibold ${
          theme === "light" ? "text-gray-900" : "text-gray-100"
        } ${styles.scrollText} ${shouldScroll ? styles.scrolling : ''}`}
        style={shouldScroll ? { animationDuration: `${animationDuration}s` } : undefined}
      >
        {title}
        {shouldScroll && (
          <span className="pl-16">
            {title}
          </span>
        )}
      </div>
    </div>
  );
};

export default ScrollingTitle;