import axiosInstance from "./axiosInstance";

/**
 * Get all brief templates
 * @param {string} language - Optional language code
 * @returns {Promise} - Response data
 */
export const getTemplatesApi = async (language) => {
  try {
    const response = await axiosInstance.get("/briefs/templates", {
      params: { language },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching templates:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch templates",
      }
    );
  }
};

/**
 * Get template details with sections and questions
 * @param {string} templateId - Template ID
 * @param {string} language - Optional language code
 * @returns {Promise} - Response data
 */
export const getTemplateDetailsApi = async (templateId, language) => {
  try {
    const response = await axiosInstance.get(
      `/briefs/templates/${templateId}`,
      {
        params: { language },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching template details:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch template details",
      }
    );
  }
};

/**
 * Create a new brief
 * @param {Object} data - Brief data
 * @param {string} data.templateId - Template ID
 * @param {string} data.title - Brief title
 * @param {string} data.language - Optional language code
 * @returns {Promise} - Response data
 */
export const createBriefApi = async (data) => {
  try {
    const response = await axiosInstance.post("/briefs", data);
    return response.data;
  } catch (error) {
    console.error("Error creating brief:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to create brief",
      }
    );
  }
};

/**
 * Get user's briefs with pagination
 * @param {number} page - Page number
 * @param {number} limit - Items per page
 * @returns {Promise} - Response data
 */
export const getUserBriefsApi = async (page = 1, limit = 10) => {
  try {
    const response = await axiosInstance.get("/briefs", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user briefs:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch briefs",
      }
    );
  }
};

/**
 * Get brief by ID
 * @param {string} briefId - Brief ID
 * @returns {Promise} - Response data
 */
export const getBriefApi = async (briefId) => {
  try {
    const response = await axiosInstance.get(`/briefs/${briefId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching brief:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch brief",
      }
    );
  }
};

/**
 * Get brief with responses
 * @param {string} briefId - Brief ID
 * @returns {Promise} - Response data
 */
export const getBriefResponsesApi = async (briefId) => {
  try {
    const response = await axiosInstance.get(`/briefs/${briefId}/responses`);
    return response.data;
  } catch (error) {
    console.error("Error fetching brief responses:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch brief responses",
      }
    );
  }
};

/**
 * Delete brief
 * @param {string} briefId - Brief ID
 * @returns {Promise} - Response data
 */
export const deleteBriefApi = async (briefId) => {
  try {
    const response = await axiosInstance.delete(`/briefs/${briefId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting brief:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to delete brief",
      }
    );
  }
};

/**
 * Transcribe audio for a brief (queue-based)
 * @param {string} briefId - Brief ID
 * @param {string} audioData - Base64 encoded audio data
 * @param {string} language - Optional language code (en or ar)
 * @param {Object} metadata - Optional metadata for tracking
 * @returns {Promise} - Response with job information
 */
export const transcribeAudioApi = async (
  briefId,
  audioData,
  language = "en",
  metadata = {}
) => {
  try {
    const response = await axiosInstance.post(`/briefs/${briefId}/transcribe`, {
      audioData,
      language,
      metadata,
    });
    return response.data;
  } catch (error) {
    console.error("Error transcribing audio:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to transcribe audio",
      }
    );
  }
};

/**
 * Force analysis of all transcriptions for a brief
 * @param {string} briefId - Brief ID
 * @returns {Promise} - Response with job information
 */
export const analyzeTranscriptionsApi = async (briefId) => {
  try {
    const response = await axiosInstance.post(`/briefs/${briefId}/analyze`);
    return response.data;
  } catch (error) {
    console.error("Error analyzing transcriptions:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to analyze transcriptions",
      }
    );
  }
};

/**
 * Generate a document from brief data
 * @param {string|number} briefId - Brief ID
 * @returns {Promise} - Response data containing the generated document
 */
export const generateBriefDocumentApi = async (briefId) => {
  try {
    const response = await axiosInstance.post(`/briefs/${briefId}/document`);
    return response.data;
  } catch (error) {
    console.error("Error generating document:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to generate document",
      }
    );
  }
};

/**
 * Get the document for a brief
 * @param {string|number} briefId - Brief ID
 * @returns {Promise} - Response data containing the document
 */
export const getBriefDocumentApi = async (briefId) => {
  try {
    const response = await axiosInstance.get(`/briefs/${briefId}/document`);
    return response.data;
  } catch (error) {
    console.error("Error fetching brief document:", error);
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to fetch document",
      }
    );
  }
};
// Let's check if the API function is working correctly.
// This should match what you have in your briefApi.js file:

/**
 * Update a section answer in a brief document
 * @param {string|number} briefId - Brief ID
 * @param {string|number} sectionId - Section ID
 * @param {string} answer - The edited answer content
 * @returns {Promise} - Response data containing the updated document
 */
export const updateBriefDocumentSectionAnswerApi = async (briefId, sectionId, answer) => {
  try {
    console.log(`Making API call to update section ${sectionId} for brief ${briefId}`);
    
    const response = await axiosInstance.put(
      `/briefs/${briefId}/document/sections/${sectionId}/answer`,
      { answer }
    );
    
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error("Error updating brief document section answer:", error);
    
    // More detailed error logging
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Request setup error:", error.message);
    }
    
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to update section answer",
      }
    );
  }
};
/**
 * Process text with AI writing using Claude
 * @param {string|number} briefId - Brief ID
 * @param {string|number} sectionId - Section ID
 * @param {string} selectedText - Selected text to process
 * @param {string} templateId - Template ID (rewrite, summarize, etc.)
 * @param {string|null} templateDetail - Optional custom instruction
 * @returns {Promise} - Response data containing the processed text
 */
export const processAIWritingApi = async (briefId, sectionId, selectedText, templateId, templateDetail = null) => {
  try {
    console.log(`Making API call to process text with AI for section ${sectionId} in brief ${briefId}`);
    
    const response = await axiosInstance.post(
      `/briefs/${briefId}/document/sections/${sectionId}/ai-write`,
      { 
        selectedText,
        templateId,
        templateDetail
      }
    );
    
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error("Error processing text with AI:", error);
    
    // More detailed error logging
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Request setup error:", error.message);
    }
    
    throw (
      error.response?.data || {
        status: "error",
        message: "Failed to process text with AI",
      }
    );
  }
};
export default {
  getTemplatesApi,
  getTemplateDetailsApi,
  createBriefApi,
  getUserBriefsApi,
  getBriefApi,
  getBriefResponsesApi,
  deleteBriefApi,
  transcribeAudioApi,
  analyzeTranscriptionsApi,
  generateBriefDocumentApi,
  updateBriefDocumentSectionAnswerApi
};
