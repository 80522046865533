import React from "react";
import { Avatar } from "@heroui/avatar";

const ProfileItem = ({ imageUrl, text }) => {
  return (
    <div className="flex items-center gap-3 p-2 rounded-[50px] bg-zinc-50 dark:bg-zinc-900">
      <Avatar src={imageUrl} alt="Profile" className="h-8 w-8 rounded-full" />
      <span className="font-medium text-sm leading-6 text-foreground">
        {text}
      </span>
    </div>
  );
};

export default ProfileItem;
