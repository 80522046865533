import { NAVIGATION_LINKS } from "../../routes/config";
import { LogoutIcon } from "../../assets/icons/icons";
import { Button } from "@heroui/react";
import { NavLink } from "react-router-dom";
import CommonBox from "../../components/CommonBox";
import { useThemeContext } from "../../context/ThemeContext";

const DashboardLayout = ({ children }) => {
  const { theme } = useThemeContext();

  return (
    <>
      <aside
        className={`w-[310px] h-fit fixed left-5 top-[100px] p-4 flex flex-col rounded-2xl ${
          theme === "light" ? "bg-[#fafafa]" : "bg-gray-800"
        }`}
      >
        <div>
          <div
            className={`flex flex-col gap-4 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          >
            {NAVIGATION_LINKS.map(
              ({ path, label, icon: Icon, isActive, disabled }) => (
                <div key={path} className="relative">
                  {disabled ? (
                    <div
                      className={`flex items-center rounded-[14px] gap-[6.5px] px-[18px] py-[14px] h-12 w-full text-base font-medium cursor-not-allowed opacity-50 ${
                        theme === "light" ? "text-gray-400" : "text-gray-500"
                      }`}
                    >
                      <Icon />
                      <span>{label}</span>
                      <span className="absolute right-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                        Coming Soon
                      </span>
                    </div>
                  ) : (
                    <NavLink
                      to={path}
                      className={({ isActive }) =>
                        `flex items-center rounded-[14px] gap-[6.5px] px-[18px] py-[14px] h-12 w-full no-underline text-base font-medium transition-colors ${
                          isActive
                            ? "bg-[#006FEE33] text-[#006FEE] font-bold [&>svg]:text-[#006FEE]"
                            : theme === "light"
                            ? "hover:bg-gray-100"
                            : "hover:bg-gray-700"
                        }`
                      }
                    >
                      <Icon />
                      <span>{label}</span>
                    </NavLink>
                  )}
                </div>
              )
            )}
          </div>
          <hr
            className={`border-t-[0.3px] my-4 ${
              theme === "light"
                ? "border-[rgba(212,212,216,1)]"
                : "border-gray-700"
            }`}
          />
          <div className="w-full">
            <Button
              variant="light"
              color="danger"
              startContent={<LogoutIcon />}
              className={`justify-start w-full text-base font-medium ${
                theme === "light" ? "hover:bg-red-50" : "hover:bg-red-900/20"
              }`}
            >
              Logout
            </Button>
          </div>
        </div>
      </aside>

      <main className="ml-[320px] px-6">
        <div>{children}</div>
      </main>
    </>
  );
};

export default DashboardLayout;
