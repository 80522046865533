import React, { useCallback } from "react";
import { Button } from "@heroui/react";
import { useThemeContext } from "../../context/ThemeContext";
import { DragDropIcon } from "../../assets/icons/icons";

const DragDropZone = ({ onUploadRequest }) => {
      const { theme } = useThemeContext();
      const isDark = theme === "dark";
  // Handle drag events
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Call parent function to handle upload/open popup
      if (onUploadRequest) {
        onUploadRequest();
      }
    },
    [onUploadRequest]
  );

  // Handle button click
  const handleButtonClick = useCallback(() => {
    if (onUploadRequest) {
      onUploadRequest();
    }
  }, [onUploadRequest]);

  return (
    <div
    className={`w-full border-2 border-dashed border-gray-300 rounded-xl p-8 flex flex-col items-center justify-center gap-4 ${
        isDark 
          ? 'bg-gray-800 hover:bg-gray-900' 
          : 'bg-gray-50 hover:bg-gray-100'
      } transition-colors duration-200`}      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {/* Upload Icon */}
      <div className="w-12 h-12 rounded-full flex items-center justify-center">
        <DragDropIcon  />
      </div>

      {/* Text */}
      <p className="text-base font-medium text-gray-600 text-center">
        Drag and drop your files here in PDF, JPEG or PNG format up to 100mb max
      </p>

      {/* Browse Button */}
      <Button color="primary" onPress={handleButtonClick} className="mt-2">
        Browse Files
      </Button>
    </div>
  );
};

export default DragDropZone;
