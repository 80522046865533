// Popup.jsx
import React, { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import { useThemeContext } from "../../context/ThemeContext";

const Popup = ({ isOpen, onClose, title, children }) => {
  const { theme } = useThemeContext();
  const isDark = theme === "dark";
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [animationState, setAnimationState] = useState(""); // "", "entering", "entered", "exiting"

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      // Small delay to ensure shouldRender takes effect before animation starts
      setTimeout(() => {
        setAnimationState("entering");
      }, 10);
      
      // After transition duration, set the fully visible state
      setTimeout(() => {
        setAnimationState("entered");
      }, 310); // 300ms for transition + 10ms delay
    } else {
      setAnimationState("exiting");
      // Wait for exit animation to complete before unmounting
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300); // Duration of the transition
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!shouldRender) return null;

  const isAnimating = animationState === "entering" || animationState === "exiting";
  const isVisible = animationState === "entering" || animationState === "entered";
  
  // Prevent closing during animation for a smoother experience
  const handleClose = () => {
    if (!isAnimating) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black/50 flex items-center justify-center z-[1000] backdrop-blur transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0"
        } ${isAnimating ? "animate-pulse-subtle" : ""}`}
        onClick={handleClose}
        style={{ transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)" }}
      >
        <div
          className={`rounded-[25px] border p-8 w-[800px] max-w-[90%] max-h-[90vh] overflow-y-auto z-[1001] transition-all duration-300 ${
            isDark ? "bg-gray-800 border-gray-700" : "bg-white border-[#3C3C3C]"
          } ${
            isVisible 
              ? "translate-y-0 scale-100 opacity-100" 
              : "translate-y-8 scale-95 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
          style={{ 
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            pointerEvents: isAnimating ? "none" : "auto" // Disable interactions during animation
          }}
        >
          <div className="flex justify-between items-center mb-5">
            {title && <h2 className="text-2xl font-semibold">{title}</h2>}
            <button
              onClick={handleClose}
              className={`bg-transparent border-none p-0 cursor-pointer flex items-center justify-center hover:opacity-80 ${
                isDark ? "text-white" : "text-gray-800"
              }`}
            >
              <FiX size={24} />
            </button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Popup;