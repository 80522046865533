import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from "react";
import { getDocumentsApi } from "../api/documentApi";
import toaster from "../components/Toast/Toast";
import { useAuth } from "./AuthContext";

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  
  const { logout, isAuthenticated } = useAuth();

  const fetchDocuments = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    setLoadingText("Loading your documents...");
    setIsLoading(true);
    try {
      const response = await getDocumentsApi();
      if (!response?.success) {
        throw new Error(response?.message || "Failed to fetch documents");
      }
      setDocuments(response.documents);
    } catch (error) {
      console.error("Error fetching documents:", error);
      if (error.response?.status === 401) {
        logout();
      }
      toaster.error(
        error?.message || "Failed to fetch documents. Please try again later."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  }, [isAuthenticated, logout]);

  // Fetch documents when user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchDocuments();
    }
  }, [isAuthenticated, fetchDocuments]);

  // Clear documents when user logs out
  useEffect(() => {
    if (!isAuthenticated) {
      setDocuments([]);
      setIsLoading(false);
      setLoadingText("");
    }
  }, [isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      documents,
      isLoading,
      loadingText,
      setIsLoading,
      setLoadingText,
      // Still expose fetchDocuments in case manual refresh is needed
      refreshDocuments: fetchDocuments,
    }),
    [documents, isLoading, loadingText, fetchDocuments]
  );

  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = () => useContext(DocumentContext);