import React from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button
} from "@heroui/react";
import { MoreVertical, DeleteIcon, CopyIcon } from "../../assets/icons/icons";
import { useThemeContext } from "../../context/ThemeContext";

const columns = [
  { name: "BRIEF", uid: "brief" },
  { name: "DATE CREATED", uid: "dateCreated" },
  { name: "ACTIONS", uid: "actions" }
];

const BriefTable = ({
  data,
  onEdit,
  onCopy,
  onDelete,
  bottomContent
}) => {
  const { theme } = useThemeContext();

  const textColorClass = theme === "dark" ? "text-white" : "text-[#11181C]";
  const headerBgClass = theme === "dark" ? "bg-gray-800" : "bg-[#F4F4F5]";
  const headerTextClass = theme === "dark" ? "text-gray-300" : "text-[#71717A]";

  const getChipStyles = (status) => {
    if (status === 'active') {
      return {
        backgroundColor: '#006FEE',
        color: 'white'
      };
    }
    return {
      backgroundColor: '#17C964',
      color: 'white'
    };
  };

  const renderCell = (item, columnKey) => {
    switch (columnKey) {
      case "brief":
        return (
          <div className="flex items-center gap-3">
            <span className={`text-sm font-normal ${textColorClass}`}>
              {item.title}
            </span>
            <Chip 
              size="sm"
              className="capitalize font-normal"
              style={getChipStyles(item.status)}
            >
              {item.status}
            </Chip>
          </div>
        );

      case "dateCreated":
        return (
          <span className={`text-sm font-normal ${textColorClass}`}>
            {item.dateCreated}
          </span>
        );

      case "actions":
        return (
          <Dropdown>
            <DropdownTrigger>
              <Button 
                variant="light"
                isIconOnly
                className={theme === "dark" ? "text-white" : "text-default-500"}
              >
                <MoreVertical 
                  color={theme === "dark" ? "white" : "#71717A"} 
                />
              </Button>
            </DropdownTrigger>
            <DropdownMenu 
              aria-label="Actions"
              className={theme === "dark" ? "bg-gray-800" : "bg-white"}
            >
                <DropdownItem
                key="edit"
                startContent={<CopyIcon size={18} />}
                onClick={() => onCopy?.(item.id)}
                className={theme === "dark" ? "text-white" : ""}
              >
                Copy Link
              </DropdownItem>
              <DropdownItem
                key="edit"
                startContent={<DeleteIcon size={18} />}
                onClick={() => onEdit?.(item.id)}
                className={theme === "dark" ? "text-white" : ""}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                key="delete"
                className={`text-danger ${theme === "dark" ? "text-white" : ""}`}
                color="danger"
                startContent={<DeleteIcon size={18} />}
                onClick={() => onDelete?.(item.id)}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        );

      default:
        return null;
    }
  };

  // Empty state render function
  const renderEmptyState = () => {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24">
          <div className="flex justify-center items-center h-full">
            <span className={`text-gray-500 ${theme === "dark" ? "text-gray-400" : ""}`}>
              No rows to display.
            </span>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table 
      aria-label="Brief table"
      className={`min-w-full ${theme === "dark" ? "text-white" : ""}`}
      color='primary'
      removeWrapper
      bottomContent={bottomContent}
      classNames={{
        wrapper: "min-h-[222px]",
      }}
    >
      <TableHeader>
        {columns.map((column) => (
          <TableColumn 
            key={column.uid}
            className={`${headerBgClass} ${headerTextClass} text-xs font-semibold`}
          >
            {column.name}
          </TableColumn>
        ))}
      </TableHeader>
      <TableBody 
        className={theme === "dark" ? "text-white" : ""}
      >
        {data && data.length > 0 ? (
          data.map(item => (
            <TableRow 
              key={item.id} 
              className={theme === "dark" ? "hover:bg-gray-700" : "hover:bg-gray-100"}
            >
              {columns.map(column => (
                <TableCell key={column.uid} className={theme === "dark" ? "text-white" : ""}>
                  {renderCell(item, column.uid)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <div className="flex justify-center items-center py-8">
                <span className={`text-gray-500 ${theme === "dark" ? "text-gray-400" : ""}`}>
                  No rows to display.
                </span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BriefTable;