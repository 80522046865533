import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { Button } from "@heroui/react";
import { FaFilePdf } from "react-icons/fa6";

// Register fonts
Font.register({
  family: 'Amiri',
  fonts: [
    { src: '/fonts/amiri-regular.ttf', fontWeight: 'normal', fontStyle: 'normal' },
    { src: '/fonts/amiri-bold.ttf', fontWeight: 'bold', fontStyle: 'normal' },
    { src: '/fonts/amiri-italic.ttf', fontWeight: 'normal', fontStyle: 'italic' },
    { src: '/fonts/amiri-bolditalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
  ]
});

// Function to detect if text contains Arabic
const containsArabic = (text) => {
  if (!text) return false;
  
  // Pattern for Arabic words (sequence of Arabic characters)
  const arabicWordPattern = /[\u0600-\u06FF]+/g;
  
  // Find all Arabic words
  const arabicWords = text.match(arabicWordPattern) || [];
  
  // Count total words (rough estimation)
  const totalWords = text.split(/\s+/).length;
  
  // Calculate percentage of Arabic words
  const arabicPercentage = (arabicWords.length / totalWords) * 100;
  
  // Consider it Arabic content if more than 30% of words are Arabic
  return arabicPercentage > 30;
};

// Create dynamic styles based on language
const createStyles = (isRTL) => StyleSheet.create({
  page: {
    padding: "20px",
    fontFamily: 'Amiri',
    fontSize: "12px",
    lineHeight: 1.6,
    direction: isRTL ? "rtl" : "ltr",
    textAlign: isRTL ? "right" : "left",
  },
  titlePage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "center",
  },
  subtitleText: {
    fontSize: "18px",
    marginBottom: "30px",
    color: "#666",
    textAlign: "center",
  },
  section: {
    marginBottom: "20px",
    direction: isRTL ? "rtl" : "ltr",
  },
  heading: {
    fontSize: "18px",
    marginBottom: "5px",
    fontWeight: "bold",
    textAlign: isRTL ? "right" : "left",
  },
  issueHeader: {
    flexDirection: isRTL ? "row-reverse" : "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "5px",
    gap: "10px",
  },
  pageNumber: {
    fontSize: "10px",
    backgroundColor: "#e8e8e8",
    padding: "2px 6px",
    borderRadius: "3px",
  },
  severity: {
    fontSize: "10px",
    textTransform: "uppercase",
  },
  type: {
    fontSize: "10px",
    color: "#666",
  },
  paragraph: {
    fontSize: "12px",
    color: "#333",
    marginBottom: "10px",
    textAlign: isRTL ? "right" : "left",
    direction: isRTL ? "rtl" : "ltr",
  },
  context: {
    fontSize: "11px",
    color: "#666",
    backgroundColor: "#f5f5f5",
    padding: "10px",
    marginTop: "5px",
    borderRadius: "3px",
    textAlign: isRTL ? "right" : "left",
    direction: isRTL ? "rtl" : "ltr",
  },
  miscSection: {
    marginTop: "20px",
    paddingTop: "20px",
    borderTop: "1px",
    borderColor: "#eee",
  },
  footer: {
    position: "absolute",
    bottom: "20px",
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: "10px",
    color: "#999",
  },
});

// Title Page Component with improved RTL handling
const TitlePage = ({ isRTL }) => {
  const styles = createStyles(isRTL);
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.titlePage}>
        <Text style={styles.titleText}>
          {isRTL ? "تقرير العميل" : "Client Report"}
        </Text>
        <Text style={styles.subtitleText}>
          {isRTL ? "رؤى وتوصيات مفصلة" : "Detailed Insights and Recommendations"}
        </Text>
      </View>
      <Text style={styles.footer}>
        {isRTL ? "تم إنشاؤه بواسطة hi-client.com" : "Generated by hi-client.com"}
      </Text>
    </Page>
  );
};

// Get severity color
const getSeverityColor = (severity) => {
  switch (severity?.toLowerCase()) {
    case "high": return "#FF4646";
    case "medium": return "#FFA500";
    case "low": return "#39F2AE";
    default: return "#666";
  }
};

// Content Pages Component with improved RTL handling
const ContentPages = ({ data, isRTL }) => {
  const styles = createStyles(isRTL);
  
  // Helper function to handle mixed content
  const renderText = (text, style) => {
    return (
      <Text style={[style, {
        direction: containsArabic(text) ? "rtl" : "ltr",
        textAlign: containsArabic(text) ? "right" : "left",
      }]}>
        {text}
      </Text>
    );
  };

  return (
    <Page size="A4" style={styles.page}>
      {data?.feedback_summary?.length > 0 ? (
        <>
          {data.feedback_summary.map((issue, index) => (
            <View key={index} style={styles.section}>
              <View style={styles.issueHeader}>
                <Text style={styles.pageNumber}>
                  {isRTL ? `صفحة ${issue.pageNumber}` : `Page ${issue.pageNumber}`}
                </Text>
                <Text style={[styles.severity, { color: getSeverityColor(issue.severity) }]}>
                  {issue.severity}
                </Text>
                {renderText(issue.type, styles.type)}
              </View>
              {renderText(issue.issueTitle, styles.heading)}
              {renderText(issue.issueExplanation, styles.paragraph)}
              {issue.context && renderText(issue.context, styles.context)}
            </View>
          ))}

          {/* Misc Section with improved RTL handling */}
          {data.misc && (
            <View style={styles.miscSection}>
              {data.misc.key_takeaways && (
                <View style={styles.section}>
                  <Text style={styles.heading}>
                    {isRTL ? "النقاط الرئيسية" : "Key Takeaways"}
                  </Text>
                  {renderText(data.misc.key_takeaways, styles.paragraph)}
                </View>
              )}
              {data.misc.additional_notes && (
                <View style={styles.section}>
                  <Text style={styles.heading}>
                    {isRTL ? "ملاحظات إضافية" : "Additional Notes"}
                  </Text>
                  {renderText(data.misc.additional_notes, styles.paragraph)}
                </View>
              )}
            </View>
          )}
        </>
      ) : (
        <Text>{isRTL ? "لا يوجد محتوى متاح" : "No content available"}</Text>
      )}
      <Text style={styles.footer}>
        {isRTL
          ? `تم إنشاؤه بواسطة hi-client.com - صفحة 2`
          : "Generated by hi-client.com - Page 2"}
      </Text>
    </Page>
  );
};

// Main Document Component
const MyDocument = ({ data }) => {
  const hasArabicContent = data?.feedback_summary?.some(
    (issue) =>
      containsArabic(issue.issueTitle) ||
      containsArabic(issue.issueExplanation) ||
      containsArabic(issue.context)
  ) ||
    containsArabic(data?.misc?.key_takeaways) ||
    containsArabic(data?.misc?.additional_notes);

  return (
    <Document>
      <TitlePage isRTL={hasArabicContent} />
      <ContentPages data={data} isRTL={hasArabicContent} />
    </Document>
  );
};

// Main App Component
const SummaryDocument = ({ data }) => {
  const hasSummaryData = data && 
    (data.feedback_summary?.length > 0 || 
     data.misc?.key_takeaways || 
     data.misc?.additional_notes);

  const hasArabicContent = data?.feedback_summary?.some(
    (issue) =>
      containsArabic(issue.issueTitle) ||
      containsArabic(issue.issueExplanation) ||
      containsArabic(issue.context)
  ) ||
    containsArabic(data?.misc?.key_takeaways) ||
    containsArabic(data?.misc?.additional_notes);

  return (
    <div>
      {hasSummaryData ? (
        <PDFDownloadLink
          document={<MyDocument data={data} />}
          fileName="Client_Report.pdf"
        >
          {({ loading }) => (
            <Button 
              color="primary" 
              startContent={<FaFilePdf />} 
              variant="light" 
              className="font-bold text-base"
            >
              {loading
                ? (hasArabicContent ? "جارِ التحميل..." : "Loading...")
                : (hasArabicContent ? "تحميل PDF" : "Download PDF")}
            </Button>
          )}
        </PDFDownloadLink>
      ) : (
        <p>{hasArabicContent ? "جارِ تحميل البيانات..." : "Loading data..."}</p>
      )}
    </div>
  );
};

export default SummaryDocument;