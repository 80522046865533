import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Card from "../Card";
import { debounce } from "lodash";

const CardCarousel = ({ cards }) => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const startXRef = useRef(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeThreshold: 50, // Lower number = more sensitive (default is 50)
    touchThreshold: 5,
    arrows: false,
    swipe: true,
    draggable: true,
    lazyLoad: "ondemand",
    dotsClass: "slick-dots",
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  React.useEffect(() => {
    return () => {
      // Clean up any listeners or refs
      startXRef.current = null;
      setIsDragging(false);
    };
  }, []);
  const handleMouseDown = (e) => {
    startXRef.current = e.clientX;
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (startXRef.current !== null) {
      const deltaX = Math.abs(e.clientX - startXRef.current);
      if (deltaX > 5) {
        setIsDragging(true);
      }
    }
  };

  const handleMouseUp = (e, id) => {
    if (!isDragging && startXRef.current !== null) {
      navigate(`/feedback/${id}`);
    }
    startXRef.current = null;
  };

  const handleMouseLeave = () => {
    startXRef.current = null;
    setIsDragging(false);
  };

  const debouncedMouseMove = debounce(handleMouseMove, 16);
  const MemoizedCard = React.memo(Card);

  return (
    <div className="relative w-full">
      <div className="mb-8">
        <Slider {...settings} className="cursor-grab active:cursor-grabbing">
          {cards.map((card, index) => (
            <div
              key={index}
              className="pl-0 pr-4"
              onMouseDown={handleMouseDown}
              onMouseMove={debouncedMouseMove}
              onMouseUp={(e) => handleMouseUp(e, card.id)}
              onMouseLeave={handleMouseLeave}
            >
              <MemoizedCard
                status={`Reviews: ${card.review_count}`}
                date={card.date}
                title={card.title}
                url={card.url}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardCarousel;
