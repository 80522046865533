import { isValidJSON } from "./helper";
import toaster from "../components/Toast/Toast";

const PING_INTERVAL = 30000;
const RECONNECT_DELAY = 3000;
const MAX_RECONNECT_ATTEMPTS = 3;

const createWebSocketConnection = (
  path,
  onOpen,
  onMessage,
  onError,
  onClose
) => {
  let reconnectAttempts = 0;
  let pingInterval;
  let reconnectTimeout;

  const connect = () => {
    const websocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}${path}`
    );

    const startPingInterval = () => {
      if (pingInterval) clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ type: "ping" }));
        }
      }, PING_INTERVAL);
    };

    const stopPingInterval = () => {
      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    };

    const clearReconnectTimeout = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    websocket.onopen = () => {
      reconnectAttempts = 0;
      startPingInterval();
      onOpen();
    };

    websocket.onmessage = onMessage;

    websocket.onerror = (error) => {
      stopPingInterval();
      onError(error);
    };

    websocket.onclose = (event) => {
      stopPingInterval();
      clearReconnectTimeout();

      if (
        !event.wasClean &&
        event.code === 1006 &&
        reconnectAttempts < MAX_RECONNECT_ATTEMPTS
      ) {
        reconnectAttempts++;
        toaster.info(
          `Connection lost. Reconnecting... Attempt ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`
        );

        reconnectTimeout = setTimeout(() => {
          connect();
        }, RECONNECT_DELAY);
      } else {
        onClose(event);
      }
    };

    return websocket;
  };

  return connect();
};

// Connect to Whisper WebSocket.
export const connectWhisperWebSocket = (
  onOpen,
  onMessage,
  onError,
  onClose
) => {
  return createWebSocketConnection(
    "/whisper",
    onOpen,
    onMessage,
    onError,
    onClose
  );
};

// Connect to Azure WebSocket
export const connectAzureWebSocket = (onOpen, onMessage, onError, onClose) => {
  return createWebSocketConnection(
    "/azure",
    onOpen,
    onMessage,
    onError,
    onClose
  );
};

// Handle Whisper Messages
export const handleWhisperMessage = (
  event,
  setTranscription,
  setReviewTranscription,
  setEndLoader,
  currentStep,
  pageNumber,
  setTranscriptionLoading,
  currentRequestId,
  currentQuestionIndex // Add this parameter
) => {
  if (!isValidJSON(event.data)) {
    setEndLoader(false);
    toaster.error("Received non-JSON message");
    return;
  }

  const output = JSON.parse(event.data);

  if (output && output.error) {
    toaster.error(`Error: ${output.error}`);
    console.error("Received error:", output.error);
    setTranscriptionLoading(false);
    return;
  }

  if (output && output.status === "success") {
    const { requestId } = output;

    if (requestId === currentRequestId) {
      setTranscriptionLoading(false);
    }

    switch (output.type) {
      case "transcription":
        setTranscription((prev) => [
          ...prev,
          { 
            text: output.text, 
            step: currentStep, 
            pageNumber: pageNumber,
            questionIndex: currentStep === 2 ? currentQuestionIndex : null // Add question index for step 2
          },
        ]);
        if (currentStep === 5) {
          setReviewTranscription((prev) => [
            ...prev,
            { 
              text: output.text, 
              step: currentStep, 
              pageNumber: pageNumber,
              questionIndex: null // Review transcriptions don't need question index
            },
          ]);
        }
        break;
      default:
        break;
    }
  }
};

// Handle Azure Messages
export const handleAzureMessage = (
  event,
  setTranscription,
  setReviewTranscription,
  setEndLoader,
  currentStep,
  pageNumber,
  setTranscriptionLoading,
  currentRequestId,
  currentQuestionIndex // Add this parameter
) => {
  if (!isValidJSON(event.data)) {
    setEndLoader(false);
    toaster.error("Received non-JSON message");
    return;
  }

  const output = JSON.parse(event.data);

  if (output && output.error) {
    toaster.error(`Error: ${output.error}`);
    console.error("Received error:", output.error);
    setTranscriptionLoading(false);
    return;
  }

  if (output && output.status === "success") {
    const { requestId } = output;

    if (requestId === currentRequestId) {
      setTranscriptionLoading(false);
    }

    switch (output.type) {
      case "transcription":
        setTranscription((prev) => [
          ...prev,
          { 
            text: output.text, 
            step: currentStep, 
            pageNumber: pageNumber,
            questionIndex: currentStep === 2 ? currentQuestionIndex : null // Add question index for step 2
          },
        ]);
        if (currentStep === 5) {
          setReviewTranscription((prev) => [
            ...prev,
            { 
              text: output.text, 
              step: currentStep, 
              pageNumber: pageNumber,
              questionIndex: null // Review transcriptions don't need question index
            },
          ]);
        }
        break;
      default:
        break;
    }
  }
};

// Add these new functions to your existing websocket.js file

// Connect to Brief WebSocket
export const connectBriefWebSocket = (onOpen, onMessage, onError, onClose) => {
  return createWebSocketConnection(
    "/brief",
    onOpen,
    onMessage,
    onError,
    onClose
  );
};

// Handle Brief WebSocket Messages
export const handleBriefMessage = (
  event,
  briefId,
  setTranscriptions,
  setTemplateData,
  setIsTranscribing,
  setConnected
) => {
  if (!isValidJSON(event.data)) {
    toaster.error("Received non-JSON message from brief server");
    return;
  }

  const data = JSON.parse(event.data);
  console.log("Received brief message:", data);

  // Handle different message types
  switch (data.type) {
    case 'brief-transcription':
      setIsTranscribing(false);
      if (data.briefId === briefId) {
        setTranscriptions(prev => [...prev, data.transcription]);
      }
      break;
    
    case 'brief-analysis-completed':
      console.log("Analysis completed:", data);
      if (data.briefId === briefId) {
        // Extract question status updates
        let questionStatusUpdates = [];
        
        if (data.updatedResponses && data.updatedResponses.length > 0) {
          // Extract from updatedResponses if available
          questionStatusUpdates = data.updatedResponses.map(response => ({
            section_id: response.section_id,
            question_id: response.question_id,
            status: response.status
          }));
        } 
        // If using simplified analysis structure
        else if (data.analysis && data.analysis.question_status && data.analysis.question_status.length > 0) {
          // Direct mapping from question_status array
          questionStatusUpdates = data.analysis.question_status.map(item => ({
            section_id: item.section_id,
            question_id: item.question_id,
            status: item.status
          }));
        }
        // Fall back to original structure if needed
        else if (data.analysis && data.analysis.results && data.analysis.results.length > 0) {
          // Flatten all matched questions from all transcriptions (legacy support)
          data.analysis.results.forEach(result => {
            if (result.matched_questions && result.matched_questions.length > 0) {
              const matchedUpdates = result.matched_questions.map(match => ({
                section_id: match.section_id,
                question_id: match.question_id,
                status: match.status
              }));
              questionStatusUpdates = [...questionStatusUpdates, ...matchedUpdates];
            }
          });
          
          // Remove duplicates (in case multiple transcriptions match the same question)
          const uniqueUpdates = {};
          questionStatusUpdates.forEach(update => {
            const key = `${update.question_id}`;
            // If we have multiple status values for the same question, prioritize "success"
            if (!uniqueUpdates[key] || update.status === "success") {
              uniqueUpdates[key] = update;
            }
          });
          
          questionStatusUpdates = Object.values(uniqueUpdates);
        }
        
        // Now update template data with the question status updates
        if (questionStatusUpdates.length > 0) {
          setTemplateData(prevTemplateData => {
            // Create a deep copy
            const updatedTemplateData = JSON.parse(JSON.stringify(prevTemplateData));
            
            // Update the status of each question based on the analysis
            questionStatusUpdates.forEach(update => {
              const sectionIndex = updatedTemplateData.findIndex(s => s.id === update.section_id);
              if (sectionIndex !== -1) {
                const questionIndex = updatedTemplateData[sectionIndex].questions.findIndex(q => q.id === update.question_id);
                if (questionIndex !== -1) {
                  // Map backend status to frontend status if needed
                  updatedTemplateData[sectionIndex].questions[questionIndex].status = update.status;
                }
              }
            });
            
            return updatedTemplateData;
          });
        }
        
        // Calculate and display completion status
        let completionPercentage = 0;
        let completedCount = 0;
        let processingCount = 0;
        
        // Try to get accurate counts from our extracted data
        questionStatusUpdates.forEach(update => {
          if (update.status === 'success') completedCount++;
          else if (update.status === 'processing') processingCount++;
        });
        
        // If we have meaningful data from our extraction
        if (questionStatusUpdates.length > 0) {
          const totalUpdated = questionStatusUpdates.length;
          // Use weighted percentage (completed + half of processing)
          completionPercentage = Math.round(((completedCount + (processingCount * 0.5)) / totalUpdated) * 100);
          
          toaster.success(`Analysis completed: ${completionPercentage}% of addressed questions fully answered`);
          toaster.info(`${completedCount} questions fully answered, ${processingCount} partially answered`);
        }
        // Fall back to server-provided completion data
        else if (data.completionStatus) {
          const { completionPercentage, completedQuestions, inProgressQuestions, totalQuestions } = data.completionStatus;
          toaster.success(`Analysis completed: ${completionPercentage}% of brief questions addressed`);
          
          if (completedQuestions > 0 || inProgressQuestions > 0) {
            toaster.info(`${completedQuestions} questions fully answered, ${inProgressQuestions} partially answered`);
          }
        }
      }
      break;
    
    case 'brief-status':
      if (data.status === 'transcribing') {
        setIsTranscribing(true);
      } else if (data.status === 'analyzing') {
        toaster.info("Analyzing your responses...");
      }
      break;
    
    case 'brief-error':
      setIsTranscribing(false);
      toaster.error(data.error || data.message || "An error occurred");
      break;
    
    case "pong":
      // Heartbeat response - you could update connection status here if needed
      setConnected(true);
      break;
      
    default:
      break;
  }
};
// Add this to your websocket helpers file

// Enhanced WebSocket connection function with better error handling
// Modify the connectEnhancedBriefWebSocket function to check for intentional disconnects
export const connectEnhancedBriefWebSocket = (onOpen, onMessage, onError, onClose) => {
  // Create a wrapper for the onClose callback that handles the intentional disconnect flag
  const onCloseWrapper = (event) => {
    // Check if this was an intentional disconnect (set by our component)
    if (onClose) {
      onClose(event);
    }
    
    // Return true if we should prevent reconnection (used by the WebSocket helper)
    return event.preventReconnect === true || event.code === 1000;
  };
  
  // Use the existing createEnhancedWebSocketConnection but with our wrapper
  return createEnhancedWebSocketConnection(
    "/brief",  // or whatever your path is
    onOpen,
    onMessage,
    onError,
    onCloseWrapper
  );
};

// Update the existing createEnhancedWebSocketConnection function to respect intentional disconnects
export const createEnhancedWebSocketConnection = (
  path,
  onOpen,
  onMessage,
  onError,
  onClose
) => {
  let reconnectAttempts = 0;
  let pingInterval;
  let reconnectTimeout;
  let isConnecting = false;
  let preventReconnection = false;

  const connect = () => {
    // If reconnection is explicitly prevented, don't connect
    if (preventReconnection) {
      console.log("Reconnection prevented - not connecting");
      return null;
    }
    
    // Prevent multiple simultaneous connection attempts
    if (isConnecting) return null;
    isConnecting = true;
    
    // Use explicit base URL or default to window.location
    let baseUrl = process.env.REACT_APP_WEBSOCKET_URL;
    if (!baseUrl) {
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      baseUrl = `${protocol}//${window.location.host}/ws`;
    }
    
    // Full WebSocket URL
    const wsUrl = `${baseUrl}${path}`;
    console.log(`Connecting to WebSocket at: ${wsUrl}`);
    
    const websocket = new WebSocket(wsUrl);

    const startPingInterval = () => {
      if (pingInterval) clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        if (websocket.readyState === WebSocket.OPEN) {
          try {
            websocket.send(JSON.stringify({ type: "ping" }));
          } catch (error) {
            console.error("Error sending ping:", error);
            stopPingInterval();
          }
        } else {
          stopPingInterval();
        }
      }, 25000); // 25 seconds
    };

    const stopPingInterval = () => {
      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    };

    const clearReconnectTimeout = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    websocket.onopen = (event) => {
      console.log(`WebSocket connection established to ${wsUrl}`);
      isConnecting = false;
      reconnectAttempts = 0;
      startPingInterval();
      if (onOpen) onOpen(event);
    };

    websocket.onmessage = onMessage;

    websocket.onerror = (error) => {
      console.error(`WebSocket error for ${wsUrl}:`, error);
      console.log(`WebSocket readyState: ${websocket.readyState}`);
      isConnecting = false;
      stopPingInterval();
      if (onError) onError(error);
    };

    websocket.onclose = (event) => {
      console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
      isConnecting = false;
      stopPingInterval();
      clearReconnectTimeout();

      // Call the provided onClose callback and get its result
      let shouldPreventReconnect = false;
      if (onClose) {
        shouldPreventReconnect = onClose(event);
      }
      
      // Normal closure (1000) should also prevent reconnection
      if (shouldPreventReconnect || event.code === 1000) {
        console.log("Preventing WebSocket reconnection due to intentional disconnect or normal closure");
        preventReconnection = true;
        return;
      }

      // Attempt to reconnect if appropriate and not intentionally disconnected
      if (!preventReconnection && reconnectAttempts < 3) {
        reconnectAttempts++;
        console.log(`Attempting to reconnect... (${reconnectAttempts}/3)`);
        
        reconnectTimeout = setTimeout(() => {
          connect();
        }, 3000); // 3 seconds delay
      } else {
        console.log("Maximum reconnection attempts reached or reconnection prevented.");
      }
    };

    return websocket;
  };

  // Return the initial connection attempt
  return connect();
};