// Built-in/Third-party modules
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Custom components
import CommentThread from "../../../components/CommentThread";
import FeedbackDetailCard2 from "../../../components/FeedbackDetailCard2";
import OverlayLoader from "../../../components/OverlayLoader";
import toaster from "../../../components/Toast/Toast";

// API methods
import {
  getDocumentByIdApi,
  toggleDocumentShortlinkApi,
} from "../../../api/documentApi";
import { getDocumentReviewsApi } from "../../../api/reviewApi";
import {
  generateConsolidatedSummaryApi,
  generatePdfReportApi,
} from "../../../api/summaryApi";

// Contexts
import { useBreadcrumb } from "../../../context/BreadcrumbContext";
import { useAuth } from "../../../context/AuthContext";

// Helpers
import {
  isObjectEmpty,
  formatDate,
  generateReviewLink,
} from "../../../helper/helper";

// Static assets
import styles from "./styles.module.css";

const FeedbackFile = () => {
  const { filename } = useParams();
  const { setPathDisplay } = useBreadcrumb();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [document, setDocument] = useState(null); // Changed from {} to null
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [shortlinkEnabled, setShortlinkEnabled] = useState(true);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);

  useEffect(() => {
    if (document?.title) {
      setPathDisplay({
        [`/feedback/${filename}`]: document.title,
      });
    }
  }, [document, filename, setPathDisplay]);

  useEffect(() => {
    if (filename) {
      fetchDocumentAndReviews();
    }
  }, [filename]); // Added dependency

  const fetchDocumentAndReviews = async () => {
    setLoadingText("Getting document...");
    setIsLoading(true);
    try {
      // Fetch document details
      const docResponse = await getDocumentByIdApi(filename);
      if (!docResponse?.success) {
        throw docResponse;
      }

      setDocument(docResponse.document);
      setShortlinkEnabled(docResponse.document?.shortlink_enabled ?? true);
      setLoadingText("Getting Reviews...");
      // Fetch document reviews
      const reviewsResponse = await getDocumentReviewsApi(filename);
      if (!reviewsResponse?.success) {
        throw reviewsResponse;
      }

      setReviews(reviewsResponse.reviews || []);
    } catch (error) {
      console.error("Error fetching document data:", error);
      toaster.error(
        error.message || "Failed to fetch document details. Please try again."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  };

  const handleToggleShortlink = async (checked) => {
    try {
      const response = await toggleDocumentShortlinkApi(filename, checked);
      if (!response?.success) {
        throw response;
      }

      setShortlinkEnabled(checked);
      toaster.success(
        `Shortlink ${checked ? "enabled" : "disabled"} successfully`
      );
    } catch (error) {
      console.error("Error toggling shortlink:", error);
      toaster.error(error.message || "Failed to update shortlink status");
    }
  };

  const generateConsolidatedSummary = async () => {
    if (!reviews?.length) {
      toaster.error("No reviews available to generate summary");
      return;
    }
    setLoadingText("Generating Consolidated Summary...");
    setConsolidatedLoading(true);
    try {
      const response = await generateConsolidatedSummaryApi(reviews);
      if (!response?.status === "success" || !response?.summary) {
        throw new Error("Failed to generate summary");
      }
      setLoadingText("Generating PDF...");
      await generatePdfReportApi(document, response.summary);
      toaster.success("Report generated and downloaded successfully");
    } catch (error) {
      console.error("Error generating consolidated summary:", error);
      toaster.error(
        error.message || "Failed to generate summary. Please try again later."
      );
    } finally {
      setLoadingText("");
      setConsolidatedLoading(false);
    }
  };
  const handleNavigation = (id) => {
    navigate(`${location.pathname}/${id}`);
  };

  const handleCopyLink = () => {
    if (!shortlinkEnabled) {
      toaster.error("Please enable shortlink first");
      return;
    }

    if (!user?.id || !filename) {
      toaster.error("Missing required information to generate link");
      return;
    }

    const link = generateReviewLink(user.id, filename);
    navigator.clipboard
      .writeText(link)
      .then(() => toaster.success("Link copied to clipboard!"))
      .catch(() => toaster.error("Failed to copy link"));
  };

  if (isLoading || consolidatedLoading) {
    return <OverlayLoader text={loadingText} />;
  }

  if (!document || !isObjectEmpty(document)) {
    return <p className={styles.notFound}>Document not found</p>;
  }
  return (
    <div className={styles.container}>
      <FeedbackDetailCard2
        title={document.title}
        imageUrl={document.file_url}
        ownerName={user?.name}
        date={formatDate(document.created_at)}
        reviewCount={document.review_count}
        onCopyLink={handleCopyLink}
        handleGenerateSummary={generateConsolidatedSummary}
        shortlinkEnabled={shortlinkEnabled}
        onToggleShortlink={handleToggleShortlink}
      />
      <div className="py-4">
      {reviews?.length > 0 ? (
        <div className="mt-8">
          <CommentThread comments={reviews} document={document} onReviewClick={(review) => handleNavigation(review.id)} />
        </div>
      ) : (
        <p className={styles.noReviews}>No reviews available</p>
      )}
      </div>
     

      {/* <div className={styles.reviewContainer}>
        <div className={styles.cardsGrid}>
          {reviews?.length > 0 ? (
            reviews.map((review) => (
              <div
                className={styles.cardBoxWrapper}
                key={review.id}
                onClick={() => handleNavigation(review.id)}
              >
                <CardBox minHeight="255px">
                  <div className={styles.guestCardContainer}>
                    <p className={styles.guestTitle}>
                      Guest {review.id} Feedback
                    </p>
                    <p className={styles.guestTime}>
                      {timeAgo(review.created_at)}
                    </p>
                    <p className={styles.guestcontent}>
                      {review.transcription}
                    </p>
                  </div>
                </CardBox>
              </div>
            ))
          ) : (
            <p className={styles.noReviews}>No reviews available</p>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default FeedbackFile;
