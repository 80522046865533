import React from 'react';
import { useThemeContext } from "../../context/ThemeContext";
import CommonBox from '../CommonBox';
import DefaultDotText from './DefaultDotText';
import { Progress } from "@heroui/react";

const TemplateSection = ({ sectionTitle, templateData, connected, progress }) => {
  const { isDark } = useThemeContext();
  
  // Styles
  const headerTitleStyle = "font-bold text-2xl text-black dark:text-white";
  const titleStyle = "font-bold text-2xl text-black dark:text-white";
  const questionStyle = isDark 
    ? "font-light text-lg text-white/90" 
    : "font-light text-lg text-black/90";
  
  return (
    <CommonBox width="69.22%" padding="16px">
      <div className="mb-8 text-left">
        {/* Header with title and icons */}
        <div className="flex justify-between items-center mb-6">
          <h2 className={headerTitleStyle}>{sectionTitle}</h2>
          <div className="flex space-x-2">
            <button className="w-6 h-6 flex items-center justify-center rounded-full border border-gray-300 dark:border-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </button>
            <button className="w-6 h-6 flex items-center justify-center rounded-full border border-gray-300 dark:border-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
            </button>
          </div>
        </div>
        
        {/* Progress bar */}
        {connected && (
          <div className="mb-6">
            <div className="flex gap-4 items-center mb-2">
              <span
                className={`font-medium ${
                  isDark ? "text-gray-200" : "text-gray-700"
                }`}
              >
                {progress.answered} of {progress.total} questions answered
              </span>
              <Progress
                value={progress.percent}
                color="primary"
                className="w-[75%] h-2"
                aria-label="Brief completion progress"
              />
            </div>
          </div>
        )}
        
        {/* Template sections and questions */}
        {templateData && templateData.length > 0 ? (
          templateData.map((section, index) => (
            <div key={section.id || index} className="mb-6">
              <h3 className={`${titleStyle} mb-2`}>{section.title}</h3>
              
              {section.questions && section.questions.map((question, qIndex) => (
                <div key={question.id || qIndex} className="mb-4">
                  <div className="flex justify-between items-start">
                    <p className={`${questionStyle} flex-1`}>
                      {question.text}
                      {question.isRequired && <span className="text-red-500 ml-1">*</span>}
                    </p>
                    <DefaultDotText 
                      text={
                        question.status === "processing" 
                          ? "Processing" 
                          : question.status === "success" 
                            ? "Success" 
                            : "Default"
                      } 
                    />
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 py-8">
            No template data available
          </div>
        )}
      </div>
    </CommonBox>
  );
};

export default TemplateSection;