import React, { useState, useEffect } from "react";
import { useNavigate , useLocation} from "react-router-dom";
import { SearchIcon } from "../../assets/icons/icons";

// Context
import { useDocument } from "../../context/DocumentContext";
import { useAuth } from "../../context/AuthContext";
import { useThemeContext } from "../../context/ThemeContext";

// API & Utils
import {
  uploadDocumentApi,
  deleteDocumentByIdApi,
} from "../../api/documentApi";
import { generateReviewLink, formatDate } from "../../helper/helper";
import { FEEDBACK_TEXT } from "../../constants/constant";
import { UPLOAD_ERROR_MESSAGES } from "../../utils/errorMessages";

// Components
import styles from "./Feedback.module.css";
import toaster from "../../components/Toast/Toast";
import Card from "../../components/Card";
import Popup from "../../components/Popup";
import FeedbackPopupContent from "../../components/FeedbackPopupContent";
import OverlayLoader from "../../components/OverlayLoader";
import { Input } from "@heroui/react";
import DragDropZone from "../../components/DragDropZone";

class DocumentUploadError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
    this.name = "DocumentUploadError";
  }
}

const Feedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { theme } = useThemeContext();
  const isDark = theme === "dark";
  const {
    documents,
    isLoading,
    loadingText,
    refreshDocuments,
    setIsLoading,
    setLoadingText,
  } = useDocument();

  const [searchValue, setSearchValue] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [title, setTitle] = useState("");
  const [pageReady, setPageReady] = useState(false);

  useEffect(() => {
    setPageReady(true);
  }, []);

  useEffect(() => {
    let popupTimer;
    
    if (location.state?.openUploadPopup && pageReady) {
      // Small delay for smoother transition
      popupTimer = setTimeout(() => {
        setIsPopupOpen(true);
      }, 150);
      
      // Clear the state after using it
      window.history.replaceState({}, document.title);
    }
    
    return () => clearTimeout(popupTimer);
  }, [location, pageReady]);

  const handleNewFeedback = () => {
    setIsPopupOpen(true);
  };

  const handleNavigation = (id) => {
    navigate(`/feedback/${id}`);
  };

  const submitFeedback = async () => {
    try {
      if (!selectedPdf) {
        throw new DocumentUploadError(
          "FILE_REQUIRED",
          UPLOAD_ERROR_MESSAGES.FILE_REQUIRED
        );
      }

      if (!title?.trim()) {
        throw new DocumentUploadError(
          "TITLE_REQUIRED",
          UPLOAD_ERROR_MESSAGES.TITLE_REQUIRED
        );
      }

      if (selectedPdf.type !== "application/pdf") {
        throw new DocumentUploadError(
          "INVALID_FILE_TYPE",
          "Please upload a PDF file"
        );
      }
      setIsLoading(true);
      setLoadingText("Submitting feedback");
      const formData = new FormData();
      formData.append("file", selectedPdf);
      formData.append("title", title.trim());

      const response = await uploadDocumentApi(formData);
      setIsLoading(false);
      if (!response?.success) {
        throw new Error(response?.message || "Upload failed");
      }

      if (response.ragStatus === "completed") {
        toaster.success("Document uploaded and processed successfully!");
      } else if (response.ragStatus === "failed") {
        toaster.success("Document uploaded successfully!");
        toaster.warning(
          "Some features may be limited due to processing issues"
        );
      }

      setSelectedPdf(null);
      setTitle("");
      setIsPopupOpen(false);
      await refreshDocuments();
    } catch (error) {
      console.error("Error uploading document:", error);
      switch (error.code) {
        case "AUTH_REQUIRED":
          toaster.error(UPLOAD_ERROR_MESSAGES.AUTH_REQUIRED);
          break;
        case "FILE_REQUIRED":
        case "TITLE_REQUIRED":
        case "INVALID_FILE_TYPE":
        case "FILE_TOO_LARGE":
          toaster.error(error.message);
          break;
        case "NETWORK_ERROR":
          toaster.error(UPLOAD_ERROR_MESSAGES.NETWORK_ERROR);
          break;
        default:
          toaster.error(error.message || UPLOAD_ERROR_MESSAGES.UPLOAD_ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = (documentId) => {
    const link = generateReviewLink(user.id, documentId);
    navigator.clipboard
      .writeText(link)
      .then(() => toaster.success("Link copied to clipboard!"))
      .catch(() => toaster.error("Failed to copy link"));
  };

  const handleDeleteDocument = async (documentId) => {
    // e.stopPropagation();
    try {
      const docResponse = await deleteDocumentByIdApi(documentId);
      if (!docResponse?.success) {
        throw docResponse;
      }
      toaster.success("Document deleted successfully");
      await refreshDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
      toaster.error(
        error.message || "Failed to delete document. Please try again."
      );
    }
  };

  // Filter documents based on search
  const filteredDocuments = documents.filter((doc) =>
    doc.title.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <div className="transition-opacity duration-300 ease-in-out">
      {isLoading && <OverlayLoader text={loadingText} />}
      <div className="flex justify-between items-center w-full mb-6">
        <h1 className="text-2xl font-semibold">Projects</h1>
        <div className="w-72">
          <Input
            placeholder="Search projects..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startContent={<SearchIcon />}
          />
        </div>
      </div>
      <div
        className={`${
          isDark ? "bg-gray-800" : "bg-[#FAFAFA]"
        }  p-4 rounded-2xl mb-[40px]`}
      >
        <DragDropZone onUploadRequest={handleNewFeedback} />
      </div>
      <div className={styles.cardsGrid}>
        {!isLoading &&
          filteredDocuments.length > 0 &&
          filteredDocuments.map((document) => (
            <div
              key={document.id}
              className={styles.cardWrapper}
              onClick={() => handleNavigation(document.id)}
            >
              <Card
                status={`Reviews: ${parseInt(document.review_count)}`}
                date={formatDate(document.created_at)}
                title={document.title}
                url={document.file_url}
                onCopy={() => {
                  handleCopyLink(document.id);
                }}
                onDelete={() => handleDeleteDocument(document.id)}
              />
            </div>
          ))}
      </div>

      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        title={FEEDBACK_TEXT.newFeedbackReqText}
      >
        <FeedbackPopupContent
          selectedPdf={selectedPdf}
          setSelectedPdf={setSelectedPdf}
          title={title}
          setTitle={setTitle}
          handleNewFeedback={submitFeedback}
          onClose={() => setIsPopupOpen(false)}
        />
      </Popup>
    </div>
  );
};

export default Feedback;
