import React, { useEffect, useState, useMemo } from "react";
import { 
  Button, 
  Input, 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  useDisclosure,
  Pagination
} from "@heroui/react";
import OverlayLoader from "../../components/OverlayLoader";
import BriefCard from "../../components/BriefCard";
import { useThemeContext } from "../../context/ThemeContext";
import BriefTable from "../../components/BriefTable";
import { SearchIcon } from "../../assets/icons/icons";
import { getTemplatesApi, getUserBriefsApi, createBriefApi, deleteBriefApi } from "../../api/briefApi";
import { formatDistanceToNow } from 'date-fns';
import { toast } from 'react-toastify';

const Brief = () => {
  const { theme } = useThemeContext();
  const isDark = theme === "dark";
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  
  // State for templates and briefs
  const [templates, setTemplates] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [briefTitle, setBriefTitle] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  
  // Supported languages
  const languages = [
    { key: "en", name: "English" },
    { key: "ar", name: "Arabic (العربية)" }
  ];
  
  // Pagination state
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  
  // Modal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch templates
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setIsLoading(true);
        setLoadingText("Loading templates...");
        const response = await getTemplatesApi();
        if (response.status === "success") {
          setTemplates(response.data);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to load templates");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  // Log API responses for debugging
  const fetchBriefs = async () => {
    try {
      setIsLoading(true);
      setLoadingText("Loading your briefs...");
      
      // Log request parameters
      console.log("Fetching briefs with params:", { page, limit });
      
      const response = await getUserBriefsApi(page, limit);
      
      // Log full response for debugging
      console.log("Brief API Response:", response);
      
      if (response.status === "success") {
        // Check the structure of the response data
        if (Array.isArray(response.data)) {
          setBriefs(response.data);
        } else {
          console.error("Unexpected data structure:", response.data);
          setBriefs([]);
        }
        
        // Calculate total pages from pagination data
        if (response.pagination) {
          setTotalPages(Math.max(response.pagination.pages || 1, 1));
        }
      }
    } catch (error) {
      console.error("Error fetching briefs:", error);
      toast.error("Failed to load briefs");
      setBriefs([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchBriefs();
  }, [page, limit]);

  // Memoize template cards to prevent unnecessary re-renders
  const templateCards = useMemo(() => {
    return templates.map(template => ({
      mostUsed: template.id <= 2, // Example logic - first two templates are "most used"
      title: template.title,
      timeToComplete: `~${template.estimated_time_minutes} mins to complete`,
      description: template.description,
      onButtonClick: () => handleTemplateSelect(template.id),
    }));
  }, [templates]);

  // Handler for template selection
  const handleTemplateSelect = (templateId) => {
    const template = templates.find(t => t.id === templateId);
    if (template) {
      setSelectedTemplate(template);
      setBriefTitle(template.title); // Pre-fill with template title
      onOpen();
    }
  };

  // Create new brief
  const handleCreateBrief = async () => {
    if (!selectedTemplate || !briefTitle.trim()) {
      toast.error("Please provide a title for your brief");
      return;
    }

    try {
      setIsLoading(true);
      setLoadingText("Creating brief...");
      
      const response = await createBriefApi({
        templateId: selectedTemplate.id,
        title: briefTitle.trim(),
        language: selectedLanguage // Use selected language
      });
      
      if (response.status === "success") {
        toast.success("Brief created successfully");
        onClose();
        setBriefTitle("");
        setSelectedTemplate(null);
        setSelectedLanguage("en"); // Reset to default
        
        // Refresh briefs list with a slight delay to ensure the database has committed
        setTimeout(() => {
          // Reset to first page when adding new content
          setPage(1);
          fetchBriefs();
        }, 500);
      }
    } catch (error) {
      console.error("Error creating brief:", error);
      toast.error(error.message || "Failed to create brief");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle edit brief
  const handleEdit = (id) => {
    console.log("Edit brief:", id);
    // Redirect to edit page
    // window.location.href = `/briefs/${id}`;
  };
  const handleCopy = (id) => {
    // Get the base URL (protocol + hostname + port if any)
    const baseUrl = window.location.origin;
    
    // Create the complete URL to be copied
    const urlToCopy = `${baseUrl}/briefs/${id}`;
    
    // Use the Clipboard API to copy text
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        console.log("URL copied to clipboard:", urlToCopy);
        // Optional: Show some feedback to the user
        // For example, you could show a tooltip or a notification
      })
      .catch(err => {
        console.error("Failed to copy URL: ", err);
        // Handle any errors here
      });
  };

  // Handle delete brief
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this brief?")) {
      try {
        setIsLoading(true);
        setLoadingText("Deleting brief...");
        
        await deleteBriefApi(id);
        toast.success("Brief deleted successfully");
        
        // Refresh briefs list
        fetchBriefs();
      } catch (error) {
        console.error("Error deleting brief:", error);
        toast.error("Failed to delete brief");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return dateString;
    }
  };

  // Memoize formatted briefs for the table
  const formattedBriefs = useMemo(() => {
    return briefs.map(brief => ({
      id: brief.id,
      title: brief.title,
      status: brief.status,
      dateCreated: formatDate(brief.created_at)
    }));
  }, [briefs]);

  // Memoize filtered briefs based on search
  const filteredBriefs = useMemo(() => {
    // For debugging
    console.log("Formatted briefs:", formattedBriefs);
    
    if (formattedBriefs.length === 0) {
      return [];
    }
    
    return formattedBriefs.filter(brief => 
      brief.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [formattedBriefs, searchValue]);

  return (
    <div className="w-full">
      {isLoading && <OverlayLoader text={loadingText} />}
      
      {/* Template selection area */}
      <div className="w-full">
        <div className="flex flex-wrap gap-4 items-stretch">
          {templateCards.map((card, index) => (
            <BriefCard key={`template-${index}`} {...card} />
          ))}
          <div className="flex-auto w-[225px] min-w-[225px]">
            <div className="h-full px-[18px] pt-[18px] pb-2 flex flex-col justify-center items-center text-center">
              <h3
                className={`text-[29px] font-semibold leading-9 ${
                  isDark ? "text-gray-100" : "text-[#11181C]"
                }`}
              >
                More Templates
              </h3>
              <p
                className={`text-base font-normal mt-[40px] mb-[40px] ${
                  isDark ? "text-gray-400" : "text-[#27272A]"
                }`}
              >
                Browse template library
              </p>
              <Button
                color="primary"
                radius="full"
                variant="bordered"
                className="w-full"
              >
                Browse
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      {/* My Briefs section */}
      <div className="flex justify-between items-center w-full mb-6 mt-[45px]">
        <h1 className="text-2xl font-semibold">My Briefs</h1>
        <div className="w-72">
          <Input
            placeholder="Search projects..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startContent={<SearchIcon />}
          />
        </div>
      </div>
      <div key={theme}>
        <BriefTable
          data={filteredBriefs}
          onEdit={handleEdit}
          onCopy={handleCopy}
          onDelete={handleDelete}
          bottomContent={
            <div className="flex w-full justify-center my-4">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                page={page}
                total={totalPages}
                onChange={handlePageChange}
              />
            </div>
          }
        />
      </div>
      
      {/* Create Brief Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent className={isDark ? "dark:bg-gray-800 dark:text-white" : ""}>
          <ModalHeader className="flex flex-col gap-1">
            Create New Brief
          </ModalHeader>
          <ModalBody>
            <p className="mb-4">
              Creating a brief using the <strong>{selectedTemplate?.title}</strong> template.
            </p>
            <Input
              label="Brief Title"
              placeholder="Enter a title for your brief"
              value={briefTitle}
              onChange={(e) => setBriefTitle(e.target.value)}
              className="mb-4"
            />
            <div className="mb-4">
              <label className={`block text-sm font-medium mb-2 ${isDark ? "text-gray-300" : "text-gray-700"}`}>
                Language
              </label>
              <select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                className={`w-full px-3 py-2 border rounded-md ${
                  isDark 
                    ? "bg-gray-700 border-gray-600 text-white" 
                    : "bg-white border-gray-300 text-gray-900"
                }`}
              >
                {languages.map(lang => (
                  <option key={lang.key} value={lang.key}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="flat" onPress={onClose}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleCreateBrief}>
              Create Brief
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Brief;