import React from "react";
import { useThemeContext } from "../../context/ThemeContext";

const InputOptions = ({ onRecordClick, onTypeClick, recordIcon, typeIcon }) => {
 const { theme } = useThemeContext();
 
 return (
   <div className={`
     w-full max-w-2xl p-1 rounded-[14px] border shadow-sm
     ${theme === 'light' 
       ? 'bg-white border-gray-200' 
       : 'bg-gray-800 border-gray-700'}
   `}>
     <div className={`
       flex 
       ${theme === 'light' ? 'divide-gray-200' : 'divide-gray-700'} 
       divide-x
     `}>
       {/* Record Option */}
       <button
         onClick={onRecordClick}
         className={`
           flex items-center justify-center gap-2 w-1/2 py-1 px-4 
           text-base font-normal
           transition-colors duration-200
           ${theme === 'light' 
             ? 'text-gray-500 hover:text-blue-500' 
             : 'text-gray-400 hover:text-blue-400'}
         `}
       >
         <img src={recordIcon} alt="record" className="w-5 h-5" />
         <span>Record</span>
       </button>

       {/* Type Option */}
       <button
         onClick={onTypeClick}
         className={`
           flex items-center justify-center gap-2 w-1/2 py-1 px-4
           text-base font-normal
           transition-colors duration-200
           ${theme === 'light' 
             ? 'text-gray-500 hover:text-blue-500' 
             : 'text-gray-400 hover:text-blue-400'}
         `}
       >
         <img src={typeIcon} alt="type" className="w-5 h-5" />
         <span>Type</span>
       </button>
     </div>
   </div>
 );
};

export default InputOptions;