import axiosInstance from "./axiosInstance";

export const generateSummaryApi = async (data) => {
  try {
    const response = await axiosInstance.post("/generate-summary", data);
    return response.data; // Expecting { status: "success", feedback_summary: [...] }
  } catch (error) {
    console.error("Error generating summary:", error);
    throw error;
    //throw error.response?.data?.detail || "Failed to join meeting";
  }
};
export const generateConsolidatedSummaryApi = async (reviews) => {
  try {
    const response = await axiosInstance.post("/consolidated-summary", {
      reviews,
    });
    return response.data;
  } catch (error) {
    console.error("Error generating consolidated summary:", error);
    throw (
      error.response?.data?.detail || "Failed to generate consolidated summary"
    );
  }
};

export const generatePdfReportApi = async (documentDetails, consolidatedSummary) => {
  try {
    const response = await axiosInstance.post(
      "/generate-pdf",
      { documentDetails, consolidatedSummary },
      {
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    // Check if the response is actually a PDF
    if (response.data.type !== 'application/pdf') {
      // If server sent an error message as JSON
      const text = await new Response(response.data).text();
      let error;
      try {
        error = JSON.parse(text);
        throw new Error(error.detail || error.message || 'Failed to generate PDF');
      } catch (e) {
        throw new Error('Received invalid response format');
      }
    }

    // Create file name with sanitization
    const fileName = `${documentDetails.title.replace(/[^a-zA-Z0-9-_]/g, '_')}_Consolidated_Report.pdf`;

    // Handle PDF download
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    
    // Create and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    
    // Using click() directly can be blocked in some browsers
    // Using a timeout helps ensure compatibility
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);

    return { 
      success: true,
      fileName,
      fileSize: response.data.size 
    };
  } catch (error) {
    console.error("Error generating PDF report:", error);
    
    // Enhanced error handling
    const errorMessage = error.response?.data?.detail 
      || error.response?.data?.message 
      || error.message 
      || "Failed to generate PDF report";
    
    throw new Error(errorMessage);
  } finally {
    // Any cleanup if needed
  }
};

