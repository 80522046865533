import React, { useEffect } from "react";
import { useThemeContext } from "../../context/ThemeContext";
import { UserIcon } from "../../assets/icons/icons";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import TiptapToolbar from "../TiptapToolbar";
import TiptapBubbleMenu from "../TiptapBubbleMenu";
import { FontSize } from "../extensions/FontSize";
import "./TiptapDocumentStyles.css";

// Tiptap Editor component with theme support
  const TiptapEditor = ({ 
    content, 
    onChange, 
    isDark, 
    showToolbar = true, 
    onWriteWithAI,
    onEditorReady,
    sectionId
  }) => {
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          }
        }),
        TextStyle,
        FontSize,
      ],
      content,
      editorProps: {
        attributes: {
          class: `w-full min-h-[120px] text-xs font-medium rounded outline-none ${
            isDark ? "bg-gray-700 text-gray-200 dark-theme" : "bg-white text-[#11181C]"
          }`,
        },
      },
      onUpdate: ({ editor }) => {
        // Important: Make sure we're calling onChange with the latest HTML content
        const newContent = editor.getHTML();
        console.log(`Editor update for section ${sectionId}:`, newContent);
        onChange(newContent);
      },
    });
  
    // Notify parent when editor is ready
    useEffect(() => {
      if (editor && onEditorReady) {
        onEditorReady(sectionId, editor);
      }
    }, [editor, onEditorReady, sectionId]);
  
    // This is critical - we need to properly update editor content when the prop changes
    useEffect(() => {
      if (editor && content !== editor.getHTML()) {
        console.log(`Updating editor content for section ${sectionId}`);
        editor.commands.setContent(content);
      }
    }, [content, editor]);
    
    const handleWriteWithAI = (selectedText, templateId, templateDetail) => {
        console.log("AI writing requested:", {
          text: selectedText,
          template: templateId, 
          detail: templateDetail,
          handlerExists: !!onWriteWithAI,
          sectionId
        });
        
        try {
          if (onWriteWithAI) {
            // Pass sectionId so parent knows which editor to update
            onWriteWithAI(selectedText, sectionId, templateId, templateDetail);
          } else {
            alert(`AI writing requested for: "${selectedText}" using template: ${templateDetail}`);
          }
        } catch (error) {
          console.error("Error in handleWriteWithAI:", error);
          alert("An error occurred while processing your AI request.");
        }
      };
    
    return (
      <div className="tiptap-editor-container">
        {showToolbar && editor && <TiptapToolbar editor={editor} isDark={isDark} />}
        {editor && (
          <TiptapBubbleMenu 
            editor={editor} 
            isDark={isDark} 
            onWriteWithAI={handleWriteWithAI}
          />
        )}
        <EditorContent editor={editor} />
      </div>
    );
  };

  const DocumentSection = ({ 
    section, 
    isEditing, 
    editedValue, 
    onValueChange, 
    onWriteWithAI,
    onEditorReady  // New prop to pass through
  }) => {
    const { isDark } = useThemeContext();
  
    return (
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <div className="mr-3 flex-shrink-0">
            <UserIcon isDark={isDark} />
          </div>
          <h3
            className={`
            text-xs 
            font-medium 
            ${isDark ? "text-gray-400" : "text-zinc-400"}
          `}
          >
            {section.title}
          </h3>
        </div>
        <div className="mt-3">
          {isEditing ? (
            <TiptapEditor
              content={editedValue}
              onChange={onValueChange}
              isDark={isDark}
              onWriteWithAI={onWriteWithAI}
              onEditorReady={onEditorReady}
              sectionId={section.section_id}
            />
          ) : (
            <div
              className={`
              text-xs 
              font-medium 
              ${isDark ? "text-gray-200" : "text-[#11181C]"}
            `}
              dangerouslySetInnerHTML={{ __html: section.answer }}
            />
          )}
        </div>
      </div>
    );
  };

// Container component with editor reference passing
const DocumentContainer = ({ 
    document, 
    isEditing, 
    editedSections, 
    onSectionChange,
    onWriteWithAI,
    onEditorReady
  }) => {
    const { isDark } = useThemeContext();
  
    if (!document || !document.content || !document.content.sections) {
      return <div>No document data available</div>;
    }
  
    return (
      <div
        className={`
        ${isDark ? "bg-gray-800" : "bg-white"} 
        text-left
        rounded-2xl 
        px-4 py-6
      `}
      >
        {document.content.sections.map((section) => (
          <DocumentSection 
            key={section.section_id} 
            section={section} 
            isEditing={isEditing}
            editedValue={editedSections[section.section_id] || ""}
            onValueChange={(newValue) => onSectionChange(section.section_id, newValue)}
            onWriteWithAI={(selectedText, sectionId, templateId, templateDetail) => {
              console.log("DocumentSection received:", { selectedText, sectionId, templateId, templateDetail });
              if (onWriteWithAI) {
                onWriteWithAI(selectedText, sectionId, templateId, templateDetail);
              }
            }}
            onEditorReady={onEditorReady}
          />
        ))}
      </div>
    );
  };

export default DocumentContainer;