import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useThemeContext } from "../../context/ThemeContext";
import TemplateSection from "../../components/BriefEditor/TemplateSection";
import FeedbackSection from "../../components/BriefEditor/FeedbackSection";
import { useMicVAD } from "@ricky0123/vad-react";
import { toast } from "react-toastify";
import { float32ToPCM, isValidJSON } from "../../helper/helper";
import {
  connectEnhancedBriefWebSocket,
  handleBriefMessage,
} from "../../helper/websocketHelpers";
import OverlayLoader from "../../components/OverlayLoader";
import {
  getBriefApi,
  getBriefResponsesApi,
  generateBriefDocumentApi,
} from "../../api/briefApi";
import toaster from "../../components/Toast/Toast";
import { Button } from "@heroui/react";

const BriefEditor = () => {
  const { briefId } = useParams();
  const navigate = useNavigate();
  const { isDark } = useThemeContext();
  const userId = localStorage.getItem("userId");

  // WebSocket references
  const websocketRef = useRef(null);
  const currentRequestId = useRef(null);
  const intentionalDisconnect = useRef(false);


  // State variables
  const [connected, setConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [intensity, setIntensity] = useState(0);
  const [micPermission, setMicPermission] = useState(false);
  const [transcriptions, setTranscriptions] = useState([]);
  const [templateData, setTemplateData] = useState([
    {
      id: "section-1",
      title: "Target Audience",
      description: "Define the ideal customer for this product",
      questions: [
        {
          id: "q1",
          text: "Who is the primary demographic for this product?",
          isRequired: true,
          status: "default",
        },
        {
          id: "q2",
          text: "What pain points does this product solve for the customer?",
          isRequired: true,
          status: "default",
        },
      ],
    },
    {
      id: "section-2",
      title: "Brand Voice",
      description: "Define the tone and messaging approach",
      questions: [
        {
          id: "q3",
          text: "What tone should we use in our messaging?",
          isRequired: true,
          status: "default",
        },
        {
          id: "q4",
          text: "What are the key brand values we should emphasize?",
          isRequired: false,
          status: "default",
        },
      ],
    },
    {
      id: "section-3",
      title: "Visual Identity",
      description: "Define the visual elements of the campaign",
      questions: [
        {
          id: "q5",
          text: "What color palette should be used in the campaign?",
          isRequired: true,
          status: "default",
        },
        {
          id: "q6",
          text: "What imagery and visual style best represents the product?",
          isRequired: true,
          status: "default",
        },
      ],
    },
  ]);
  const [loadinText, setLoadingText] = useState(null);
  const [endLoader, setEndLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brief, setBrief] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);

  // Modified formatTemplateData to handle processing status while keeping original structure
  const formatTemplateData = (
    template,
    answeredIds = [],
    processingIds = []
  ) => {
    if (!template || !template.sections) return [];

    return template.sections.map((section) => ({
      id: section.section_id,
      title: section.section_title,
      description: section.section_description,
      questions: section.questions.map((q) => ({
        id: q.id,
        text: q.question_text,
        isRequired: q.is_required,
        // Set status based on which array contains the question ID
        status: answeredIds.includes(q.id)
          ? "success"
          : processingIds.includes(q.id)
          ? "processing"
          : "default", // default, processing, success
      })),
    }));
  };

  // Calculate total questions and answered questions (with processing count)
  const calculateProgress = useCallback(() => {
    if (!templateData.length) return { total: 0, answered: 0, processing: 0 };

    let totalQuestions = 0;
    let answeredCount = 0;
    let processingCount = 0;

    templateData.forEach((section) => {
      totalQuestions += section.questions.length;
      section.questions.forEach((question) => {
        if (question.status === "success") answeredCount++;
        else if (question.status === "processing") processingCount++;
      });
    });

    return {
      total: totalQuestions,
      answered: answeredCount,
      processing: processingCount,
      // You could also calculate a weighted progress if desired
      // weightedProgress: Math.round(((answeredCount + (processingCount * 0.5)) / totalQuestions) * 100)
    };
  }, [templateData]);

  const loadBriefData = useCallback(async () => {
    try {
      setLoading(true);
      console.log("Loading brief data for briefId:", briefId);

      // Fetch brief details
      const briefResponse = await getBriefApi(briefId);
      const briefData = briefResponse.data;
      setBrief(briefData);

      // Check if brief is already active
      if (briefData.status === "active" || briefData.status === "complete") {
        // setConnected(true);
      }

      // Fetch brief responses with template data
      const responsesData = await getBriefResponsesApi(briefId);
      const template = responsesData.data.template;
      const responses = responsesData.data.brief.responses || [];

      console.log("Loaded responses:", responses.length, responses);

      // Extract answered question ids (success)
      const answered = responses
        .filter((r) => r.status === "success")
        .map((r) => r.question_id)
        .filter((id) => id !== null); // Filter out nulls

      // Extract processing question ids (partially answered)
      const processing = responses
        .filter((r) => r.status === "processing")
        .map((r) => r.question_id)
        .filter((id) => id !== null); // Filter out nulls

      console.log("Completed questions:", answered.length);
      console.log("Processing questions:", processing.length);

      setAnsweredQuestions(answered);

      // Format template data with both success and processing questions
      const formattedTemplate = formatTemplateData(
        template,
        answered,
        processing
      );
      setTemplateData(formattedTemplate);

      // Extract all transcriptions
      const allTranscriptions = [];

      // Add raw transcriptions (no question_id)
      responses
        .filter((r) => r.transcription)
        .forEach((r) => {
          if (r.transcription && !allTranscriptions.includes(r.transcription)) {
            allTranscriptions.push(r.transcription);
          }
        });

      console.log(
        "All transcriptions:",
        allTranscriptions.length,
        allTranscriptions
      );
      setTranscriptions(allTranscriptions);

      setLoading(false);
    } catch (error) {
      console.error("Error loading brief data:", error);
      toast.error("Failed to load brief data. Please try again.");
      setLoading(false);
    }
  }, [briefId]);

  useEffect(() => {
    loadBriefData();
  }, [loadBriefData]);

  // Calculate progress
  const { total: totalQuestions, answered: answeredCount } =
    calculateProgress();
  const progressPercent =
    totalQuestions > 0 ? (answeredCount / totalQuestions) * 100 : 0;

  // Generate a unique request ID
  const generateRequestId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  // Send audio function
  const sendAudio = useCallback(
    (audio) => {
      if (
        !connected ||
        !websocketRef.current ||
        websocketRef.current.readyState !== WebSocket.OPEN
      ) {
        toaster.error("Cannot send audio - WebSocket not connected");
        return;
      }

      try {
        // Generate request ID
        const requestId = generateRequestId();
        currentRequestId.current = requestId;

        // Convert Float32Array to PCM
        const pcmAudioBuffer = float32ToPCM(audio);

        // Convert to base64 string
        const base64Audio = btoa(
          new Uint8Array(pcmAudioBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        // Set transcribing state
        setIsTranscribing(true);

        // Send audio via WebSocket
        websocketRef.current.send(
          JSON.stringify({
            type: "brief-audio",
            briefId: briefId,
            userId: userId,
            audioData: base64Audio,
            requestId: requestId,
          })
        );
      } catch (error) {
        console.error("Error sending audio:", error);
        setIsTranscribing(false);
        toaster.error("Failed to process audio");
      }
    },
    [connected, briefId, userId]
  );

  const vad = useMicVAD({
    startOnLoad: false,
    baseAssetPath: "/static/js/",
    onnxWASMBasePath: "/static/js/",
    positiveSpeechThreshold: 0.9,
    negativeSpeechThreshold: 0.45,
    minSpeechFrames: 4,
    redemptionFrames: 12,
    preSpeechPadFrames: 2,

    onSpeechStart: useCallback(() => {
      console.log("Speech detected - starting recording");
      setIsRecording(true);
    }, []),

    onFrameProcessed: useCallback(({ isSpeech }) => {
      if (isSpeech > 0.3) {
        setIntensity(isSpeech);
      } else {
        setIntensity(0);
      }
    }, []),

    onSpeechEnd: useCallback(
      (audio) => {
        console.log("Speech ended - sending audio");
        setIsRecording(false);
        sendAudio(audio);
      },
      [sendAudio]
    ),

    onVADMisfire: useCallback(() => {
      console.log("Speech too short (misfire)");
      toaster.info("Speech too short, please speak longer");
      setIsRecording(false);
    }, []),

    additionalAudioConstraints: {
      noiseSuppression: true,
      echoCancellation: true,
      autoGainControl: true,
    },
  });
  // Effect to start/stop VAD based on connection and permission
  useEffect(() => {
    if (connected && micPermission) {
      vad.start();
    } else {
      vad.pause();
    }
  }, [connected, micPermission, vad]);

  // WebSocket message handler
  const handleWebSocketMessage = useCallback(
    (event) => {
      handleBriefMessage(
        event,
        briefId,
        setTranscriptions,
        setTemplateData,
        setIsTranscribing,
        setConnected
      );
    },
    [briefId]
  );

  // WebSocket connection/disconnection function
  const handleConnectSession = useCallback(() => {
    // Reset the intentional disconnect flag when connecting
    intentionalDisconnect.current = false;
  
    // If already connected, don't try to connect again
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      toaster.info("WebSocket is already connected.");
      return Promise.resolve();
    }
  
    // Clean up any existing connection
    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
    }
  
    return new Promise((resolve, reject) => {
      const websocketHandlers = {
        onOpen: () => {
          console.log("Brief WebSocket connection established");
          setConnected(true);
  
          // Send initial ping with briefId to associate connection
          if (
            websocketRef.current &&
            websocketRef.current.readyState === WebSocket.OPEN
          ) {
            websocketRef.current.send(
              JSON.stringify({
                type: "ping",
                briefId: briefId,
                userId: userId,
              })
            );
          }
  
          resolve();
        },
        onMessage: handleWebSocketMessage,
        onError: (error) => {
          console.error("Brief WebSocket error:", error);
          setConnected(false);
          toaster.error("Connection error with brief server");
          reject(error);
        },
        onClose: (event) => {
          console.log("Brief WebSocket connection closed", event);
          setConnected(false);
  
          // Check if this was an intentional disconnect
          if (intentionalDisconnect.current) {
            console.log("Intentional disconnect - not attempting reconnection");
            // This is important - we need to tell the helper not to reconnect
            event.preventReconnect = true;
          }
  
          if (event.code === 1000) {
            // Normal closure
            toaster.info("Brief session ended");
          } else {
            // Abnormal closure
            toaster.error("Connection to brief server lost");
          }
        },
      };
  
      // Connect to WebSocket
      websocketRef.current = connectEnhancedBriefWebSocket(
        websocketHandlers.onOpen,
        websocketHandlers.onMessage,
        websocketHandlers.onError,
        websocketHandlers.onClose
      );
    });
  }, [briefId, userId, handleWebSocketMessage]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Set the intentional disconnect flag before closing
      intentionalDisconnect.current = true;
      
      if (websocketRef.current) {
        console.log("Component unmounting - closing WebSocket intentionally");
        websocketRef.current.close(1000, "Component unmounted");
        websocketRef.current = null;
      }
    };
  }, []);

  // Handle microphone permission errors
  useEffect(() => {
    if (vad.error) {
      console.error("VAD initialization error:", vad.error);
      toaster.error(
        "Microphone initialization failed. Please check your microphone permissions."
      );
      setMicPermission(false);
    }

    if (vad.userSpeechPermission === true) {
      setMicPermission(true);
    } else if (vad.userSpeechPermission === false) {
      setMicPermission(false);
      toaster.error("Microphone permission denied");
    }
  }, [vad.error, vad.userSpeechPermission]);

  // Handle start button - Connect WebSocket and prepare microphone
  const handleStart = async () => {
    try {
      console.log("Starting brief session...");

      // First connect to WebSocket
      await handleConnectSession();

      // Then enable microphone permission
      setMicPermission(true);

      toaster.success("Brief session started");
    } catch (error) {
      console.error("Failed to start brief session:", error);
      toaster.error("Failed to connect to server");
    }
  };

  // Handle force analysis button
  const handleForceAnalysis = () => {
    if (
      connected &&
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(
        JSON.stringify({
          type: "brief-force-analysis",
          briefId: briefId,
          userId: userId,
        })
      );
      toaster.info("Analyzing all responses...");
    } else {
      toaster.error("Cannot analyze - not connected to server");
    }
  };

  const handleGenerateDocument = async () => {
    try {
      setEndLoader(true);
      setLoadingText("Generating Document...")
  
      // Set the intentional disconnect flag before closing
      intentionalDisconnect.current = true;
  
      // Close WebSocket connection first if it's open
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        console.log("Closing WebSocket connection before navigation");
        websocketRef.current.close(1000, "Navigation to document view");
        websocketRef.current = null;
        setConnected(false);
      }
  
      // Stop the VAD (voice activity detection) explicitly
      if (vad && typeof vad.pause === 'function') {
        vad.pause();
      }
  
      const response = await generateBriefDocumentApi(briefId);
  
      if (response.status === "success") {
        // Add the new document to the list
        console.log("res is", response.data);
        setDocument(response.data);
  
        // Show a success message before navigation
        toaster.success("Document generated successfully");
  
        // Navigate to the document page
        navigate(`/brief/document/${briefId}`);
      } else {
        toaster.error(response.message || "Failed to generate document");
        // Reset the intentional disconnect flag if we're not navigating away
        intentionalDisconnect.current = false;
      }
    } catch (err) {
      toaster.error(err.message || "Failed to generate document");
      // Reset the intentional disconnect flag if there was an error
      intentionalDisconnect.current = false;
    } finally {
      setEndLoader(false);
    }
  };

  return (
    <div className="container-95">
      {endLoader && <OverlayLoader text={loadinText} />}
      <div className="flex w-full gap-4">
        {/* Left Side - Creative Brief Form */}
        <TemplateSection
          sectionTitle={brief?.title || "Creative Brief"}
          templateData={templateData}
          connected={connected}
          progress={{
            total: totalQuestions,
            answered: answeredCount,
            percent: progressPercent,
          }}
        />

        {/* Right Side - Feedback Section */}
        <FeedbackSection
          handleStart={handleStart}
          connected={connected}
          transcriptionData={transcriptions}
          isRecording={isRecording}
          isTranscribing={isTranscribing}
          intensity={intensity}
          onForceAnalysis={handleForceAnalysis}
          micPermission={micPermission}
          setMicPermission={setMicPermission}
          handleBuildDocument={handleGenerateDocument}
        />
      </div>
    </div>
  );
};

export default BriefEditor;
