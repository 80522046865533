import React from "react";
import styles from "./ChatBox.module.css";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@heroui/react";
import CommonBox from "../../components/CommonBox";
import StartTalking from "../StartTalking";
import TranscriptionDisplay from "../TranscriptionDisplay";
import ReviewFeedback from "../ReviewFeedback";
import { CHAT_TEXT } from "../../constants/constant";
import { StarIcon, NextIcon } from "../../assets/icons/icons";
import newMicIcon from "../../assets/icons/newMicIcon.svg";
import { useThemeContext } from "../../context/ThemeContext";

const ChatBox = ({
  handleStart,
  handleNext,
  step,
  questions,
  isConnected,
  isRecording,
  setIsRecording,
  intensity,
  transcriptionLoading,
  pdfFile,
  currentQuestionIndex,
  setSTTService,
  selectedService,
  transcription,
  setTranscription,
  reviewTranscription,
  pageNumber,
  handleReviewConfirm,
  setStep,
  setCurrentQuestionIndex,
  summary,
  setSummary,
  selectedLanguage,
  setSelectedLanguage,
}) => {
  const { theme } = useThemeContext();
  const handlePlayPause = () => {
    setIsRecording((prevstate) => !prevstate);
  };
  const handleTranscriptionDelete = (step, pageNumber) => {
    setTranscription((prev) => {
      // Find the index of the item to delete
      const index = prev.findIndex(
        (item) => item.step === step && item.pageNumber === pageNumber
      );

      if (index === -1) return prev;

      // Create a new array without the deleted item
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;
    });
  };
  return (
    <CommonBox width="30.78%" padding="16px">
      <div
        className={
          !isConnected
            ? "flex items-center flex-col h-full flex-1 text-left"
            : "flex justify-center items-start flex-1"
        }
        style={{ justifyContent: !pdfFile && "center" }}
      >
        {!isConnected ? (
          <>
            {pdfFile && (
              <div>
                <h2 className="font-bold text-2xl">{CHAT_TEXT.heading}</h2>
                <hr className="border-0 border-t border-[#D4D4D8] my-6 w-full" />
                <p
                  className={`
     font-medium text-sm mb-4
     ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
   `}
                >
                  {CHAT_TEXT.howWork}
                </p>
                <div className="flex flex-col gap-4 mb-5">
                  {CHAT_TEXT.workStep.map((item) => (
                    <div key={item.step} className="flex items-center gap-4">
                      <div className="w-5 h-5 rounded-full bg-[#006fee] flex justify-center items-center text-sm font-normal shrink-0 text-white">
                        {item.step}
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="font-medium text-base">{item.text}</p>
                        {item.hasLanguageSelector && (
                          <Dropdown>
                            <DropdownTrigger>
                              <Button
                                className="capitalize"
                                variant="bordered"
                                size="sm"
                              >
                                {selectedLanguage === "en"
                                  ? "English"
                                  : "Arabic"}
                              </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                              disallowEmptySelection
                              aria-label="Language selection"
                              selectedKeys={new Set([selectedLanguage])}
                              selectionMode="single"
                              variant="flat"
                              onSelectionChange={(keys) => {
                                const selected = Array.from(keys)[0];
                                setSelectedLanguage(selected);
                              }}
                            >
                              <DropdownItem key="en">English</DropdownItem>
                              <DropdownItem key="ar">Arabic</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div
              className={`mt-5 ${
                pdfFile ? "absolute bottom-5 left-1/2 -translate-x-1/2" : ""
              }`}
            >
              <Button
                color="primary"
                startContent={<StarIcon />}
                onPress={handleStart}
              >
                {CHAT_TEXT.feedbackButtonText}
              </Button>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-start h-full text-left">
            <div className="overflow: auto">
              {step === 1 || step === 2 ? (
                <>
                  <h2 className="font-bold text-2xl mb-2">
                    {CHAT_TEXT.thoughtText}
                  </h2>
                  <p
                    className={`
     font-medium text-sm mb-6
     ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
   `}
                  >
                    {CHAT_TEXT.startInfo}
                  </p>
                  <hr className="border-0 border-t border-[#D4D4D8] my-6 w-full" />
                  {transcription.length === 0 ? (
                    <StartTalking />
                  ) : (
                    <TranscriptionDisplay
                      transcription={transcription}
                      pageNumber={pageNumber}
                      onDelete={handleTranscriptionDelete}
                      step={step}
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                  )}
                </>
              ) : step === 3 ? (
                <>
                  <h2 className="font-bold text-2xl mb-2">
                    {CHAT_TEXT.feedbackReviewTitle}
                  </h2>
                  <p
                    className={`font-medium text-sm mb-6 ${
                      theme === "light" ? "text-[#5D6673]" : "text-gray-300"
                    }
   `}
                  >
                    {CHAT_TEXT.feedbackReviewText}
                  </p>
                  {/* <hr className={styles.lineBreak} /> */}
                  {/* <div className={styles.helperBox}>
                    <QuestionNavigator
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                    {isRecording && <Waveform intensity={intensity} />}
                  </div> */}
                  <ReviewFeedback
                    setStep={setStep}
                    summary={summary}
                    setSummary={setSummary}
                  />
                </>
              ) : (
                step === 5 && (
                  <>
                    <h2 className={styles.heading}>{CHAT_TEXT.thoughtText}</h2>
                    <p className={styles.helperText}>{CHAT_TEXT.timeText}</p>
                    <hr className={styles.lineBreak} />
                    <TranscriptionDisplay
                      transcription={reviewTranscription}
                      pageNumber={pageNumber}
                      step={step}
                    />
                  </>
                )
              )}
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-auto">
              {step === 3 ? (
                <>
                  <div className="flex items-center justify-end mt-4 w-full">
                    <Button
                      color="black"
                      variant="light"
                      isLoading={transcriptionLoading}
                      fullWidth
                      className="font-bold text-base"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onPress={handleReviewConfirm}
                      isLoading={transcriptionLoading}
                      fullWidth
                      className="font-bold text-base"
                    >
                      Confirm
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <hr className="border-0 border-t border-[#D4D4D8] my-6 w-full" />
                  <div className="flex flex-col items-center gap-4">
                    {/* Icon container */}
                    <div className="relative">
                      <div
                        className={`h-14 w-14 rounded-full flex items-center justify-center bg-[#CCE3FD] cursor-pointer hover:bg-blue-200 transition-colors`}
                        onClick={handlePlayPause}
                      >
                        {transcriptionLoading ? (
                          <div className="w-6 h-6 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                        ) : (
                          <>
                            <img
                              src={newMicIcon}
                              alt="icon"
                              className="w-6 h-6"
                            />
                            {!isRecording && (
                              <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gray-400 transform -rotate-45" />
                            )}
                          </>
                        )}
                      </div>
                      {intensity > 0.7 && (
                        <span
                          className={`
        absolute left-1/2 -translate-x-1/2 
        font-medium text-sm
        ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
      `}
                        >
                          Listening...
                        </span>
                      )}
                    </div>

                    <span
                      className={`
    font-medium text-sm mt-2
    ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
  `}
                    >
                      {CHAT_TEXT.muteText}
                    </span>

                    {/* HeroUI Button with icon */}
                    <Button
                      color="primary"
                      endContent={
                        transcriptionLoading ? (
                          <div className="w-4 h-4 animate-spin rounded-full border-2 border-gray-300 border-t-white" />
                        ) : (
                          <NextIcon />
                        )
                      }
                      onPress={handleNext}
                      isLoading={transcriptionLoading}
                    >
                      Next
                    </Button>
                  </div>
                  {/* <p className={styles.helperText}>{CHAT_TEXT.muteText}</p>
                  <div className={styles.footerBox}>
                    <div className={styles.listenBox}>
                      <IconButton
                        handleButtonClick={handlePlayPause}
                        icon={!isRecording ? LuMicOff : LuMic}
                        backgroundColor={
                          !isRecording ? "rgb(93 102 115 / 25%)" : "#39F2AE40"
                        } // Red background
                        iconColor="#39F2AE" // Black icon
                        isLoading={transcriptionLoading}
                      />
                      {isRecording && (
                        <div className={styles.listenBox}>
                          <div className={styles.circle} />
                          <p className={styles.listenText}>Listening...</p>
                        </div>
                      )}
                    </div>

                    <CustomButton
                      handleButtonClick={handleNext}
                      text={
                        step === 1
                          ? CHAT_TEXT.nextText
                          : currentQuestionIndex < questions.length - 1
                          ? CHAT_TEXT.nextQuestion
                          : CHAT_TEXT.finishText
                      }
                      backgroundColor="#39F2AE"
                      glow={true}
                      loading={transcriptionLoading}
                      textColor="black"
                      paddingX="35px"
                      height="46px"
                      radius="25px" // Rounded corners
                      Icon={FaArrowRightLong} // Icon at the start
                      iconPosition="end"
                      gap="12px" // Default gap between icon and text
                    />
                  </div> */}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </CommonBox>
  );
};
export default ChatBox;
