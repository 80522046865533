import React from "react";

// Simple icon components for toolbar buttons
const BoldIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
    <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
  </svg>
);

const ItalicIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="19" y1="4" x2="10" y2="4"></line>
    <line x1="14" y1="20" x2="5" y2="20"></line>
    <line x1="15" y1="4" x2="9" y2="20"></line>
  </svg>
);

const ListBulletIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="9" y1="6" x2="20" y2="6"></line>
    <line x1="9" y1="12" x2="20" y2="12"></line>
    <line x1="9" y1="18" x2="20" y2="18"></line>
    <circle cx="5" cy="6" r="2"></circle>
    <circle cx="5" cy="12" r="2"></circle>
    <circle cx="5" cy="18" r="2"></circle>
  </svg>
);

const ListOrderedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="10" y1="6" x2="21" y2="6"></line>
    <line x1="10" y1="12" x2="21" y2="12"></line>
    <line x1="10" y1="18" x2="21" y2="18"></line>
    <path d="M4 6h1v4"></path>
    <path d="M4 10h2"></path>
    <path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1"></path>
  </svg>
);

const FontSizeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <text x="4" y="17" fontSize="18" fontWeight="bold">A</text>
    <text x="13" y="17" fontSize="10">A</text>
  </svg>
);

// Font size options
const fontSizeOptions = [
  { label: 'Small', value: 'x-small' },
  { label: 'Normal', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
];

const TiptapToolbar = ({ editor, isDark }) => {
  if (!editor) {
    return null;
  }
  
  // Function to check if current font size is active
  const isFontSizeActive = (fontSize) => {
    if (!editor) return false;
    
    const { fontSize: currentFontSize } = editor.getAttributes('textStyle');
    return currentFontSize === fontSize;
  };

  return (
    <div 
      className={`
        flex flex-wrap items-center p-1 mb-2 border rounded-md
        ${isDark ? "bg-gray-800 border-gray-700" : "bg-gray-100 border-gray-300"}
      `}
    >
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`
          p-1 mr-1 rounded-md
          ${editor.isActive('bold') 
            ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-300') 
            : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200')}
        `}
        title="Bold"
      >
        <BoldIcon />
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`
          p-1 mr-1 rounded-md
          ${editor.isActive('italic') 
            ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-300') 
            : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200')}
        `}
        title="Italic"
      >
        <ItalicIcon />
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`
          p-1 mr-1 rounded-md
          ${editor.isActive('bulletList') 
            ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-300') 
            : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200')}
        `}
        title="Bullet List"
      >
        <ListBulletIcon />
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`
          p-1 mr-1 rounded-md
          ${editor.isActive('orderedList') 
            ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-300') 
            : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200')}
        `}
        title="Ordered List"
      >
        <ListOrderedIcon />
      </button>
      
      {/* Font size dropdown */}
      <div className="relative ml-2">
        <select
          onChange={(e) => editor.chain().focus().setFontSize(e.target.value).run()}
          className={`
            text-xs p-1 rounded-md appearance-none pl-6 pr-8 outline-none
            ${isDark ? "bg-gray-800 text-gray-300 border-gray-700" : "bg-white text-gray-700 border-gray-300"}
            border
          `}
          value={(editor.getAttributes('textStyle').fontSize || '')}
        >
          <option value="">Font Size</option>
          {fontSizeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 left-1 flex items-center pointer-events-none">
          <FontSizeIcon />
        </div>
      </div>
    </div>
  );
};

export default TiptapToolbar;