import React from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useThemeContext } from "../../context/ThemeContext";

const ReviewCard = ({
  pageNumber,
  title,
  text,
  showActions = true,
  onEdit,
  onDelete,
}) => {
  const { theme } = useThemeContext();

  return (
    <div className="relative text-left">
      <div className={`
        relative p-2.5 rounded-[24px_24px_24px_0] group
        ${theme === 'light' ? 'bg-[#D4D4D8]' : 'bg-gray-800'}
      `}>
        {showActions && (
          <div className="absolute top-1.5 right-2.5 hidden group-hover:flex gap-2">
            <FiEdit2
              color={theme === 'light' ? '#006FEE' : '#3B82F6'}
              className="w-5 h-5 cursor-pointer hover:text-blue-500 transition-colors"
              onClick={onEdit}
            />
            <FiTrash2
              color={theme === 'light' ? '#006FEE' : '#3B82F6'}
              className="w-5 h-5 cursor-pointer hover:text-red-500 transition-colors"
              onClick={onDelete}
            />
          </div>
        )}

        <div className="flex items-center gap-2 mb-2">
          {title && (
            <h3 className={`
              font-bold text-lg
              ${theme === 'light' ? 'text-gray-900' : 'text-gray-100'}
            `}>
              {title}
            </h3>
          )}
          {pageNumber && (
            <span className="bg-[#006FEE] text-white px-3.5 py-1 rounded-full text-xs font-normal whitespace-nowrap">
              Page {pageNumber}
            </span>
          )}
        </div>

        <p className={`
          font-medium text-base
          ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'}
        `}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;