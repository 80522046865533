import React from "react";
import { useThemeContext } from "../../context/ThemeContext";
import waveIcon from "../../assets/icons/waveIcon.svg";
import { STARTTALKING_TEXT } from "../../constants/constant";

const StartTalking = () => {
  const { theme } = useThemeContext();
  return (
    <div
      className="flex flex-col items-center gap-4 m-0 text-center justify-center h-[280px]
"
    >
      <div>
        <img src={waveIcon} alt="waveIcon" />
      </div>
      <h1
        className={`
        font-semibold text-xl
        ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
      `}
      >
        {STARTTALKING_TEXT.title}{" "}
      </h1>
      <p
        className={`
        font-medium text-sm
        ${theme === "light" ? "text-[#5D6673]" : "text-gray-300"}
      `}
      >
        {STARTTALKING_TEXT.helper}
      </p>
    </div>
  );
};

export default StartTalking;
