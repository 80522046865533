/* eslint-disable react-hooks/exhaustive-deps */
// src/context/ThemeContext.jsx
import React, { createContext, useContext, useEffect } from 'react';
import { useTheme } from "@heroui/use-theme";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    // Load theme from localStorage on mount
    const savedTheme = localStorage.getItem('heroui-theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};