import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DownloadIcon } from "../../assets/icons/icons";
import { Button } from "@heroui/react";
import { formatDate } from "../../helper/helper";

// Register Amiri font for Arabic text
Font.register({
  family: "Amiri",
  fonts: [
    {
      src: "/fonts/amiri-regular.ttf",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    { src: "/fonts/amiri-bold.ttf", fontWeight: "bold", fontStyle: "normal" },
  ],
});

// Function to detect significant Arabic content
const containsArabic = (text) => {
  if (!text) return false;
  const arabicWordPattern = /[\u0600-\u06FF]+/g;
  const arabicWords = text.match(arabicWordPattern) || [];
  const totalWords = text.split(/\s+/).length;
  const arabicPercentage = (arabicWords.length / totalWords) * 100;
  return arabicPercentage > 30;
};

// Create dynamic styles based on language direction
const createStyles = (isRTL) =>
  StyleSheet.create({
    page: {
      padding: "30px",
      fontFamily: "Amiri",
      fontSize: "12px",
      lineHeight: 1.6,
      direction: isRTL ? "rtl" : "ltr",
    },
    titlePage: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    titleText: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
      textAlign: "center",
    },
    documentTitle: {
      fontSize: "18px",
      marginBottom: "20px",
      color: "#666",
      textAlign: "center",
    },
    dateText: {
      fontSize: "14px",
      color: "#666",
      marginBottom: "30px",
      textAlign: "center",
    },
    section: {
      marginBottom: "25px",
      direction: isRTL ? "rtl" : "ltr",
    },
    sectionTitle: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "8px",
      color: "#333",
      textAlign: isRTL ? "right" : "left",
    },
    heading: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "5px",
      color: "#444",
      textAlign: isRTL ? "right" : "left",
    },
    text: {
      fontSize: "12px",
      marginBottom: "10px",
      color: "#333",
      textAlign: isRTL ? "right" : "left",
    },
    metadata: {
      flexDirection: isRTL ? "row-reverse" : "row",
      marginBottom: "5px",
      flexWrap: "wrap",
    },
    tag: {
      fontSize: "10px",
      marginRight: isRTL ? "0px" : "10px",
      marginLeft: isRTL ? "10px" : "0px",
      color: "#666",
    },
    context: {
      fontSize: "11px",
      marginTop: "5px",
      marginBottom: "10px",
      fontStyle: "italic",
      color: "#666",
      textAlign: isRTL ? "right" : "left",
    },
    questionSection: {
      marginTop: "15px",
      marginBottom: "8px",
    },
    question: {
      fontSize: "12px",
      fontWeight: "bold",
      marginBottom: "5px",
      textAlign: isRTL ? "right" : "left",
    },
    answer: {
      fontSize: "12px",
      marginBottom: "15px",
      paddingLeft: isRTL ? "0px" : "15px",
      paddingRight: isRTL ? "15px" : "0px",
      textAlign: isRTL ? "right" : "left",
    },
    footer: {
      position: "absolute",
      bottom: "30px",
      left: "30px",
      right: "30px",
      textAlign: "center",
      fontSize: "10px",
      color: "#999",
    },
  });

// Helper functions remain the same
const isNewStructure = (item) => {
  return (
    item.hasOwnProperty("issueTitle") && item.hasOwnProperty("issueExplanation")
  );
};

const getTitle = (item) => {
  if (isNewStructure(item)) {
    return item.issueTitle;
  }
  return item.title || "Untitled";
};

const getDescription = (item) => {
  if (isNewStructure(item)) {
    return item.issueExplanation;
  }
  return item.summary_text || item.text || "No description available";
};

const isNewFormat = (summary) => {
  if (!summary || !Array.isArray(summary) || summary.length === 0) return false;
  return isNewStructure(summary[0]);
};

// Updated components with RTL support
const TitlePage = ({ documentTitle, createdAt, isRTL }) => {
  const styles = createStyles(isRTL);
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.titlePage}>
        <Text style={styles.titleText}>
          {isRTL ? "تقرير ملاحظات الضيوف" : "Guest Feedback Report"}
        </Text>
        <Text style={styles.documentTitle}>{documentTitle}</Text>
        <Text style={styles.dateText}>{formatDate(createdAt)}</Text>
      </View>
      <Text style={styles.footer}>
        {isRTL
          ? "تم إنشاؤه بواسطة hi-client.com"
          : "Generated by hi-client.com"}
      </Text>
    </Page>
  );
};

const SummaryPage = ({ summary, misc, isRTL }) => {
  const styles = createStyles(isRTL);
  const useNewFormat = isNewFormat(summary);

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {isRTL ? "النتائج الرئيسية" : "Key Findings"}
        </Text>
        {summary.map((item, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.heading}>{getTitle(item)}</Text>
            {useNewFormat && (
              <View style={styles.metadata}>
                <Text style={styles.tag}>
                  {isRTL
                    ? `النوع: ${item.type || "غير متوفر"}`
                    : `Type: ${item.type || "N/A"}`}
                </Text>
                <Text style={styles.tag}>
                  {isRTL
                    ? `الأهمية: ${item.severity || "غير متوفر"}`
                    : `Severity: ${item.severity || "N/A"}`}
                </Text>
                <Text style={styles.tag}>
                  {isRTL
                    ? `الصفحة: ${item.pageNumber || "غير متوفر"}`
                    : `Page: ${item.pageNumber || "N/A"}`}
                </Text>
              </View>
            )}
            <Text style={styles.text}>{getDescription(item)}</Text>
            {useNewFormat && item.context && (
              <Text style={styles.context}>
                {isRTL ? `السياق: ${item.context}` : `Context: ${item.context}`}
              </Text>
            )}
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {isRTL ? "النقاط الرئيسية" : "Key Takeaways"}
        </Text>
        <Text style={styles.text}>
          {misc?.key_takeaways || (isRTL ? "غير متوفر" : "Not available")}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {isRTL ? "ملاحظات إضافية" : "Additional Notes"}
        </Text>
        <Text style={styles.text}>
          {misc?.additional_notes || (isRTL ? "غير متوفر" : "Not available")}
        </Text>
      </View>
      <Text style={styles.footer}>
        {isRTL
          ? "تم إنشاؤه بواسطة hi-client.com"
          : "Generated by hi-client.com"}
      </Text>
    </Page>
  );
};

const QuestionsPage = ({ questions, answerText, transcription, isRTL }) => {
  const styles = createStyles(isRTL);
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {isRTL ? "ملاحظات مفصلة" : "Detailed Feedback"}
        </Text>
        <Text style={styles.text}>
          {transcription ||
            (isRTL
              ? "لا تتوفر ملاحظات مفصلة"
              : "No detailed feedback available")}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {isRTL
            ? "الأسئلة والأجوبة المتابعة"
            : "Follow-up Questions & Answers"}
        </Text>
        {questions?.length > 0 ? (
          questions.map((q, index) => (
            <View key={index} style={styles.questionSection}>
              <Text style={styles.question}>
                {isRTL
                  ? `س${index + 1}: ${q.question_text}`
                  : `Q${index + 1}: ${q.question_text}`}
              </Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>
            {isRTL ? "لا تتوفر أسئلة" : "No questions available"}
          </Text>
        )}
        <Text style={styles.answer}>
          {answerText || (isRTL ? "لم يتم تقديم إجابة" : "No answer provided")}
        </Text>
      </View>
      <Text style={styles.footer}>
        {isRTL
          ? "تم إنشاؤه بواسطة hi-client.com"
          : "Generated by hi-client.com"}
      </Text>
    </Page>
  );
};

const GuestReport = ({ document }) => {
  // Check if content contains Arabic
  const hasArabicContent =
    containsArabic(document.document_title) ||
    document.summary?.some(
      (item) =>
        containsArabic(getTitle(item)) || containsArabic(getDescription(item))
    ) ||
    containsArabic(document.misc?.key_takeaways) ||
    containsArabic(document.misc?.additional_notes) ||
    containsArabic(document.transcription) ||
    document.questions?.some((q) => containsArabic(q.question_text)) ||
    containsArabic(document.answer_text);

  return (
    <Document>
      <TitlePage
        documentTitle={document.document_title}
        createdAt={document.created_at}
        isRTL={hasArabicContent}
      />
      <SummaryPage
        summary={document.summary || []}
        misc={document.misc || {}}
        isRTL={hasArabicContent}
      />
      <QuestionsPage
        questions={document.questions || []}
        answerText={document.answer_text}
        transcription={document.transcription}
        isRTL={hasArabicContent}
      />
    </Document>
  );
};

const GuestReportDownload = ({ document }) => {
  const hasArabicContent =
    document &&
    (containsArabic(document.document_title) ||
      document.summary?.some(
        (item) =>
          containsArabic(getTitle(item)) || containsArabic(getDescription(item))
      ) ||
      containsArabic(document.misc?.key_takeaways) ||
      containsArabic(document.misc?.additional_notes));

  return (
    <div>
      {document ? (
        <PDFDownloadLink
          document={<GuestReport document={document} />}
          fileName={`${document.document_title}_Feedback_Report.pdf`}
        >
          {({ loading }) =>
            loading ? (
              hasArabicContent ? (
                "جارِ التحميل..."
              ) : (
                "Loading..."
              )
            ) : (
              <Button
                startContent={<DownloadIcon />}
                variant="light"
                color="primary"
                className="text-base font-bold"
              >
                {hasArabicContent ? "تحميل التقرير" : "Download Report"}
              </Button>
            )
          }
        </PDFDownloadLink>
      ) : (
        <p>{hasArabicContent ? "جارِ تحميل البيانات..." : "Loading data..."}</p>
      )}
    </div>
  );
};

export default GuestReportDownload;
