import React from "react";
import { useThemeContext } from "../../context/ThemeContext";
import { Button } from "@heroui/button";
import ScrollingTitle from "../ScrollingTitle";

// BriefCard Component
const BriefCard = ({
  mostUsed = false,
  timeToComplete = "~15 mins to complete",
  title = "Creative Brief",
  description = "Standard template for new creative projects",
  onButtonClick,
}) => {
  const { theme } = useThemeContext();
  const isDark = theme === "dark";

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="flex-auto w-[225px] min-w-[225px]">
      {" "}
      {/* This wrapper ensures equal width and height */}
      <div
        className={`h-full px-[18px] pt-[18px] pb-2 text-left rounded-2xl flex flex-col justify-between
        ${isDark ? "bg-gray-800" : "bg-[#FAFAFA]"}`}
      >
        <div className="flex-grow">
          {mostUsed && (
            <p
              className={`text-xs font-bold mb-1
              ${isDark ? "text-blue-400" : "text-[#006FEE]"}`}
            >
              Most Used
            </p>
          )}

          <p
            className={`text-xs font-medium
            ${isDark ? "text-gray-300" : "text-[#11181C]"}`}
          >
            {timeToComplete}
          </p>

          <h3
            className={`text-[29px] font-semibold leading-9 mt-1
            ${isDark ? "text-gray-100" : "text-[#11181C]"}`}
          >
            {title}
          </h3>

          <p
            className={`text-base font-normal mt-[52px]
            ${isDark ? "text-gray-400" : "text-[#27272A]"}`}
          >
            {description}
          </p>
        </div>

        <Button
          color="primary"
          radius="full"
          className="w-full mt-4"
          onClick={onButtonClick}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default BriefCard;
