import React from "react";
import { useNavigate } from "react-router-dom";
import darkLogo from "../../assets/logo.svg";
import lightLogo from "../../assets/lightLogo.svg";
import moonIcon from "../../assets/icons/moonIcon.svg";
import sunIcon from "../../assets/icons/sunIcon.svg";
import ProfileItem from "../ProfileItem";
import { Button } from "@heroui/button";
import { useAuth } from "../../context/AuthContext";
import { PATHS } from "../../routes/paths";
import { useThemeContext } from "../../context/ThemeContext";

const Navbar = () => {
  const { isAuthenticated, loading, user } = useAuth();
  const { theme, setTheme } = useThemeContext();
  const navigate = useNavigate();

  const handleButtonClick = (location) => {
    navigate(location);
  };

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const bgClass = theme === "dark" ? "bg-black " : "bg-white ";

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 ${bgClass} transition-colors duration-200 px-1 py-1`}
    >
      <div className="flex justify-between items-center h-[60px] w-[95%] mx-auto box-border">
        <div
          className="text-2xl font-bold cursor-pointer"
          onClick={() => handleButtonClick(PATHS.PUBLIC.HOME)}
        >
          <img
            src={theme === "dark" ? darkLogo : lightLogo}
            alt="Logo"
            className="h-auto w-auto"
          />
        </div>
        
        <div className="flex items-center gap-6">
        <div
          onClick={toggleTheme}
          className={`w-11 h-11 rounded-full cursor-pointer flex items-center justify-center ${
            theme === "dark" ? "bg-gray-800" : "bg-[#FAFAFA]"
          }`}
        >
          <img
            src={theme === "light" ? sunIcon : moonIcon}
            alt={
              theme === "light" ? "Switch to dark mode" : "Switch to light mode"
            }
            className="w-6 h-6" // Adjust icon size as needed
          />
        </div>
        {loading ? (
          <span>loading...</span>
        ) : isAuthenticated ? (
          <ProfileItem
            imageUrl={user?.profile_picture || ""}
            text={user?.name}
          />
        ) : (
          <Button
            onPress={() => handleButtonClick(PATHS.PUBLIC.LOGIN)}
            className="min-w-[137px] h-[50px] rounded-[25px]"
            color="default"
            variant="solid"
          >
            Log In
          </Button>
        )}
        </div>
    
      </div>
    </nav>
  );
};

export default Navbar;
