import { Select, SelectItem, Button } from "@heroui/react";
import StatBox from "../../components/StatBox";
import CardCarousel from "../../components/CardCarousel";
import { useThemeContext } from "../../context/ThemeContext";
import { useDocument } from "../../context/DocumentContext";
import { formatDate } from "../../helper/helper";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";


const timeOptions = [
  { key: "all", label: "All Time" },
  { key: "30days", label: "Last 30 Days" },
  { key: "3months", label: "Last 3 Months" },
  { key: "6months", label: "Last 6 Months" },
  { key: "1year", label: "Last Year" },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { documents } = useDocument();
  const [selectedPeriod, setSelectedPeriod] = useState("all");
  const isDark = theme === 'dark';

  const backgroundClass = isDark ? 'bg-gray-800' : 'bg-[#FAFAFA]';
  const textClass = isDark ? 'text-white' : 'text-black';
  const secondaryTextClass = isDark ? 'text-gray-400' : 'text-gray-500';
  const dividerClass = isDark ? 'divide-gray-700' : 'divide-[#11111126]';

  const stats = useMemo(() => {
    if (!documents?.length) return {
      activeProjects: 0,
      totalUploads: 0,
      totalFeedback: 0,
      trends: { activeProjects: 0, totalUploads: 0, totalFeedback: 0 }
    };

    let filteredDocs = [...documents];
    const now = new Date();

    // Filter documents based on selected period
    if (selectedPeriod !== 'all') {
      const periodMap = {
        '30days': 30,
        '3months': 90,
        '6months': 180,
        '1year': 365
      };
      const days = periodMap[selectedPeriod];
      const cutoffDate = new Date(now.getTime() - (days * 24 * 60 * 60 * 1000));
      filteredDocs = documents.filter(doc => new Date(doc.created_at) >= cutoffDate);
    }

    // Calculate current stats
    const activeProjects = filteredDocs.filter(doc => doc.shortlink_enabled).length;
    const totalUploads = filteredDocs.length;
    const totalFeedback = filteredDocs.reduce((sum, doc) => sum + parseInt(doc.review_count || 0), 0);

    // Calculate trends
    let trends = { activeProjects: 0, totalUploads: 0, totalFeedback: 0 };

    if (selectedPeriod !== 'all') {
      const periodInDays = {
        '30days': 30,
        '3months': 90,
        '6months': 180,
        '1year': 365
      }[selectedPeriod];

      const currentPeriodStart = new Date(now.getTime() - (periodInDays * 24 * 60 * 60 * 1000));
      const previousPeriodStart = new Date(currentPeriodStart.getTime() - (periodInDays * 24 * 60 * 60 * 1000));

      const currentPeriodDocs = documents.filter(doc => new Date(doc.created_at) >= currentPeriodStart);
      const previousPeriodDocs = documents.filter(doc => {
        const docDate = new Date(doc.created_at);
        return docDate >= previousPeriodStart && docDate < currentPeriodStart;
      });

      const calculateTrend = (current, previous) => {
        // If both periods have no data, return null to indicate no trend
        if (current === 0 && previous === 0) return null;
        // If only previous period had no data but current has data, show 100% increase
        if (previous === 0) return 100;
        // If current period has no data but previous had data, show -100% decrease
        if (current === 0) return -100;
        // Normal calculation
        return ((current - previous) / previous) * 100;
      };

      const currentActive = currentPeriodDocs.filter(doc => doc.shortlink_enabled).length;
      const previousActive = previousPeriodDocs.filter(doc => doc.shortlink_enabled).length;
      
      const currentFeedback = currentPeriodDocs.reduce((sum, doc) => sum + parseInt(doc.review_count || 0), 0);
      const previousFeedback = previousPeriodDocs.reduce((sum, doc) => sum + parseInt(doc.review_count || 0), 0);

      trends = {
        activeProjects: calculateTrend(currentActive, previousActive),
        totalUploads: calculateTrend(currentPeriodDocs.length, previousPeriodDocs.length),
        totalFeedback: calculateTrend(currentFeedback, previousFeedback)
      };
    }

    return {
      activeProjects,
      totalUploads,
      totalFeedback,
      trends
    };
  }, [documents, selectedPeriod]);

  const handleUploadClick = () => {
    navigate('/feedback', { state: { openUploadPopup: true } });
  };
  // Get latest 6 projects
  const latestProjects = useMemo(() => {
    if (!documents) return [];
    
    return documents
      .slice(0, 6)
      .map(doc => ({
        id: doc.id,
        review_count: parseInt(doc.review_count) ,
        date: formatDate(doc.created_at),
        title: doc.title,
        url: doc.file_url,
        statusColor: parseInt(doc.review_count) > 0 ? "#17C964" : undefined,
        onCopy: () => {},
        onDelete: () => {},
      }));
  }, [documents]);

  return (
    <>
      <div className="text-left mb-4">
        <p className={`text-2xl font-semibold ${textClass}`}>Recent Projects</p>
      </div>
      <CardCarousel cards={latestProjects} />
      <div className="flex justify-between items-center mb-4">
        <h2 className={`text-2xl font-semibold ${textClass}`}>Analytics</h2>
        <Select 
          className="max-w-xs" 
          defaultSelectedKeys={["all"]}
          onSelectionChange={(key) => setSelectedPeriod(key.currentKey)}
        >
          {timeOptions.map((option) => (
            <SelectItem key={option.key}>{option.label}</SelectItem>
          ))}
        </Select>
      </div>
      <div className={`${backgroundClass} p-6 rounded-xl`}>
        <div className={`flex divide-x ${dividerClass}`}>
          <StatBox
            title="Active Projects"
            value={stats.activeProjects.toString()}
            percentage={stats.trends.activeProjects !== null ? Math.round(stats.trends.activeProjects) : null}
            isPositive={stats.trends.activeProjects > 0}
            isDark={isDark}
          />
          <StatBox
            title="Total Uploads"
            value={stats.totalUploads.toString()}
            percentage={stats.trends.totalUploads !== null ? Math.round(stats.trends.totalUploads) : null}
            isPositive={stats.trends.totalUploads > 0}
            isDark={isDark}
          />
          <StatBox
            title="Submitted Feedback"
            value={stats.totalFeedback.toString()}
            percentage={stats.trends.totalFeedback !== null ? Math.round(stats.trends.totalFeedback) : null}
            isPositive={stats.trends.totalFeedback > 0}
            isDark={isDark}
          />
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 mt-[40px] pb-5">
        <h2 className={`text-2xl font-semibold ${textClass}`}>
          Wanna share some feedback?
        </h2>
        <p className={`text-base font-medium ${secondaryTextClass}`}>
          Upload a file and start sharing your feedback with your team members
        </p>
        <Button color="primary" size="lg" onPress={handleUploadClick}>
          Upload File
        </Button>
      </div>
    </>
  );
};

export default Dashboard;