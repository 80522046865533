import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiX } from "react-icons/fi";
import { FEEDBACK_TEXT } from "../../constants/constant";
import { DragDropIcon } from "../../assets/icons/icons";
import { Button } from "@heroui/react";

const DragDropPDF = ({ onFileSelect, selectedPdf }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type === "application/pdf") {
        onFileSelect(file);
      }
    },
    [onFileSelect]
  );

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent dropzone click
    onFileSelect(null);
  };

  const getFileName = (path) => {
    if (!path) return "";
    return path.replace("./", "").split("/").pop();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    maxSize: 100 * 1024 * 1024,
    multiple: false,
  });

  const handleButtonClick = (e) => {
    open(); // Use dropzone's open method
  };

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dashed border-[#5D6673] rounded-[35px] px-5 pt-9 pb-5 text-center cursor-pointer"
    >
      <input {...getInputProps()} />
      <div className="flex items-center justify-center">
        <DragDropIcon />
      </div>

      {selectedPdf ? (
        <>
          <div className="relative flex items-center justify-center mt-2.5">
            <h3 className="text-base font-bold">
              {getFileName(selectedPdf.path)}
            </h3>
            <button
              onClick={handleDelete}
              className="absolute right-4 bg-transparent border-none text-gray-400 cursor-pointer p-1.5 flex items-center justify-center hover:text-red-500"
            >
              <FiX size={20} />
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="text-base font-medium my-2">{FEEDBACK_TEXT.dropText}</p>
          <Button
            color="primary"
            variant="flat"
            className="text-base font-bold"
            onPress={handleButtonClick}
          >
            Browse Files
          </Button>
        </>
      )}
    </div>
  );
};

export default DragDropPDF;
