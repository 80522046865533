import React from "react";
import { Button } from "@heroui/react";
import DragDropPDF from "../DragDropPDF";
import { Input } from "@heroui/react";
import { FiUpload } from "react-icons/fi";
import { FEEDBACK_TEXT } from "../../constants/constant";

const FeedbackPopupContent = ({
  handleNewFeedback,
  selectedPdf,
  setSelectedPdf,
  title,
  setTitle,
  onClose,
}) => {
  const handleFileSelect = (file) => {
    setSelectedPdf(file);
  };
  return (
    <div className="flex flex-col gap-5 text-left">
      <div>
        <Input
          label="Project Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="eg. Brand Campaing 2024"
          type="text"
        />
      </div>

      <div className="mt-2">
        <DragDropPDF
          onFileSelect={handleFileSelect}
          selectedPdf={selectedPdf}
        />
      </div>
      <p className="text-sm font-medium">{FEEDBACK_TEXT.helperText1}</p>
      <div className="flex items-center gap-8 justify-end">
        <Button
          // color="black"
          variant="light"
          className="text-base font-bold"
          onPress={onClose}
          size="lg"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="solid"
          className="text-base font-bold"
          onPress={handleNewFeedback}
          startContent={<FiUpload />}
          size="lg"
        >
          Create Project
        </Button>
      </div>
    </div>
  );
};
export default FeedbackPopupContent;
