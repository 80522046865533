import React, { useEffect, useRef } from "react";
import { Button } from "@heroui/react";
import { useThemeContext } from "../../context/ThemeContext";
import StepItem from "./StepItem";
import CommonBox from "../CommonBox";
import { CHAT_TEXT } from "../../constants/constant";
import { StarIcon, NextIcon } from "../../assets/icons/icons";
import newMicIcon from "../../assets/icons/newMicIcon.svg";

const FeedbackSection = ({
  handleStart,
  connected,
  transcriptionData,
  isRecording,
  isTranscribing,
  intensity,
  onForceAnalysis,
  setIsRecording,
  micPermission,
  setMicPermission,
  handleBuildDocument
}) => {
  const { isDark } = useThemeContext();
  const transcriptionContainerRef = useRef(null);
  
  useEffect(() => {
    console.log("FeedbackSection received transcriptions:", transcriptionData);
  }, [transcriptionData]);

  // Auto-scroll to bottom when new transcriptions are added
  useEffect(() => {
    if (transcriptionContainerRef.current && transcriptionData?.length > 0) {
      transcriptionContainerRef.current.scrollTop = transcriptionContainerRef.current.scrollHeight;
    }
  }, [transcriptionData]);

  const steps = [
    { number: 1, text: "Hit the button below and speak naturally" },
    { number: 2, text: "You can pause anytime and navigate the document" },
    {
      number: 3,
      text: 'When done, Click "Continue" and we\'ll ask a few follow-up questions',
    },
  ];

  return (
    <CommonBox width="30.78%" padding="16px">
      <div className="text-left h-full relative">
        {!connected ? (
          // Not connected - show instructions
          <>
            <h2 className="font-bold text-2xl mb-6 text-black dark:text-white">
              Ready to share your thoughts?
            </h2>
            <hr className="border-0 border-t border-[#D4D4D8] my-6 w-full" />
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Here's how it works:
            </p>

            {steps.map((step) => (
              <StepItem
                key={step.number}
                number={step.number}
                text={step.text}
              />
            ))}

            <div className="mt-5 absolute bottom-5 left-1/2 -translate-x-1/2">
              <Button
                color="primary"
                startContent={<StarIcon />}
                onPress={handleStart}
              >
                {CHAT_TEXT.feedbackButtonText}
              </Button>
            </div>
          </>
        ) : (
          // Connected - show transcription section
          <>
            <div className="flex items-center justify-between mb-6">
              <h2 className="font-bold text-2xl text-black dark:text-white">
                Your Response
              </h2>
            </div>

            {isTranscribing && !transcriptionData?.length && (
              <div className="text-center py-10">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto mb-4"></div>
                <p className="text-gray-600 dark:text-gray-300">
                  Processing your speech...
                </p>
              </div>
            )}

            {/* Transcription list with auto-scroll */}
            {transcriptionData && transcriptionData.length > 0 ? (
              <div 
                ref={transcriptionContainerRef}
                className="max-h-[calc(100vh-400px)] overflow-y-auto mb-2 scroll-smooth"
              >
                {transcriptionData.map((text, i) => (
                  <div key={i} className="mb-4 rounded-lg">
                    <p className="text-[#006FEE] font-bold">{text}</p>
                  </div>
                ))}
              </div>
            ) : !isTranscribing ? (
              <div className="text-center py-10">
                <p className="text-gray-600 dark:text-gray-300">
                  Start speaking to see your transcription here.
                </p>
              </div>
            ) : null}

            <>
              <hr className="border-0 border-t border-[#D4D4D8] my-6 w-full" />
              <div className="flex flex-col items-center gap-4">
                {/* Icon container */}
                <div className="relative">
                  <div
                    className={`h-14 w-14 rounded-full flex items-center justify-center bg-[#CCE3FD] cursor-pointer hover:bg-blue-200 transition-colors`}
                    onClick={() => setMicPermission((prevstate) => !prevstate)}
                  >
                    {isTranscribing ? (
                      <div className="w-6 h-6 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                    ) : (
                      <>
                        <img src={newMicIcon} alt="icon" className="w-6 h-6" />
                        {!micPermission && (
                          <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gray-400 transform -rotate-45" />
                        )}
                      </>
                    )}
                  </div>
                  {intensity > 0.7 && (
                    <span
                      className={`
        absolute left-1/2 -translate-x-1/2 
        font-medium text-sm
        ${!isDark ? "text-[#5D6673]" : "text-gray-300"}
      `}
                    >
                      Listening...
                    </span>
                  )}
                </div>

                <span
                  className={`
    font-medium text-sm mt-2
    ${!isDark ? "text-[#5D6673]" : "text-gray-300"}
  `}
                >
                  {CHAT_TEXT.muteText}
                </span>

                {/* HeroUI Button with icon */}
                <div className="flex gap-2">
                  <Button
                    color="primary"
                    endContent={
                      isTranscribing ? (
                        <div className="w-4 h-4 animate-spin rounded-full border-2 border-gray-300 border-t-white" />
                      ) : (
                        <NextIcon />
                      )
                    }
                     onPress={handleBuildDocument}
                    isLoading={isTranscribing}
                  >
                    Build Document
                  </Button>
                  {/* Force Analysis button */}
                  {transcriptionData && transcriptionData.length > 0 && (
                    <Button
                      color="secondary"
                      onPress={onForceAnalysis}
                      className="mx-auto"
                    >
                      Force Analyze
                    </Button>
                  )}
                </div>
              </div>
            </>
          </>
        )}
      </div>
    </CommonBox>
  );
};

export default FeedbackSection;