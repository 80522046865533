import React, { useState } from "react";
import { Button } from "@heroui/react";
import { Document, Page } from "react-pdf";
import { CopyIcon, DeleteIcon } from "../../assets/icons/icons";
import { useThemeContext } from "../../context/ThemeContext";
import ScrollingTitle from "../ScrollingTitle";
import styles from "./Card.module.css";

const Card = ({ status, date, title, url, onCopy, onDelete }) => {
  const [thumbnailError, setThumbnailError] = useState(false);
  const { theme } = useThemeContext();

  const onLoadError = () => {
    setThumbnailError(true);
  };

  return (
    <div
      className={`${
        theme === "light" ? "bg-gray-50" : "bg-gray-800"
      } shadow-sm rounded-2xl p-4 text-left w-full ${
        theme === "dark" ? "shadow-gray-900" : ""
      }`}
    >
      <div className="flex flex-col gap-2 mb-2">
        <span className="text-blue-500 font-bold text-xs">{status}</span>
        <span
          className={`${
            theme === "light" ? "text-gray-900" : "text-gray-100"
          } font-medium text-xs`}
        >
          {date}
        </span>
      </div>

      {/* <h2
        className={`text-3xl font-semibold mb-4 ${
          theme === "light" ? "text-gray-900" : "text-gray-100"
        }`}
      >
        {title}
      </h2> */}
      <ScrollingTitle title={title} theme={theme} />
      <div
        className={`w-full h-[180px] mb-4 rounded-xl overflow-hidden ${
          theme === "light" ? "bg-gray-900" : "bg-gray-950"
        }`}
      >
        {!thumbnailError ? (
          <Document
            file={url}
            onLoadError={onLoadError}
            className="w-full h-full"
          >
            <Page
              pageNumber={1}
              width={340}
              height="100%"
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className={`w-full h-full p-0 m-0 ${styles.page}`}
            />
          </Document>
        ) : (
          <div
            className={`w-full h-full flex items-center justify-center ${
              theme === "light"
                ? "bg-gray-100 text-gray-500"
                : "bg-gray-800 text-gray-400"
            }`}
          >
            <span>Preview not available</span>
          </div>
        )}
      </div>

      {onCopy && onDelete && (
        <div className="flex items-center justify-center gap-4">
          <Button
            color="primary"
            variant="light"
            onPress={() => onCopy()}
            startContent={<CopyIcon />}
          >
            Copy Link
          </Button>

          <div
            className={`w-px h-6 ${
              theme === "light" ? "bg-gray-200" : "bg-gray-700"
            }`}
          />

          <Button
            color="danger"
            variant="light"
            onPress={() => onDelete()}
            startContent={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default Card;
