import React, { useState, useRef, useEffect } from 'react';
import { BubbleMenu } from '@tiptap/react';

// Icons for menu items
const AIIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <circle cx="8.5" cy="8.5" r="1.5"></circle>
    <circle cx="15.5" cy="8.5" r="1.5"></circle>
    <path d="M8.5 15a4 4 0 0 0 7 0"></path>
  </svg>
);

const FormatIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 20h9"></path>
    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
  </svg>
);

const ChevronDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

// Custom popup modal component
const CustomInstructionModal = ({ isOpen, onClose, onSubmit, isDark }) => {
  const [instruction, setInstruction] = useState('');
  const modalRef = useRef(null);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div 
        ref={modalRef}
        className={`
          p-4 rounded-lg shadow-lg w-full max-w-md
          ${isDark ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'}
        `}
      >
        <h3 className="text-sm font-medium mb-2">Custom AI Instruction</h3>
        <p className="text-xs mb-3">
          Tell the AI what you want to do with the selected text:
        </p>
        <textarea
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          className={`
            w-full p-2 text-xs border rounded-md mb-3 min-h-[80px]
            ${isDark 
              ? 'bg-gray-700 border-gray-600 text-gray-200' 
              : 'bg-white border-gray-300 text-gray-800'}
          `}
          placeholder="e.g., Rewrite this to be more persuasive and add a call to action at the end."
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className={`
              px-3 py-1 text-xs rounded-md
              ${isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}
            `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (instruction.trim()) {
                onSubmit(instruction);
                onClose();
              }
            }}
            className="px-3 py-1 text-xs rounded-md bg-blue-500 text-white hover:bg-blue-600"
            disabled={!instruction.trim()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

// AI Templates dropdown menu
const AITemplatesDropdown = ({ isOpen, onSelect, isDark }) => {
  if (!isOpen) return null;

  // Predefined templates
  const templates = [
    { id: 'rewrite', label: 'Rewrite' },
    { id: 'summarize', label: 'Summarize' },
    { id: 'restructure', label: 'Restructure' },
    { id: 'expand', label: 'Expand' },
    { id: 'simplify', label: 'Simplify' },
    { id: 'professional', label: 'Make Professional' },
    { id: 'custom', label: 'Custom Message...' }
  ];

  return (
    <div 
      className={`
        absolute top-full left-0 mt-1 py-1 rounded-md shadow-lg z-10 w-40
        ${isDark ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'}
      `}
    >
      {templates.map(template => (
        <button
          key={template.id}
          onClick={() => onSelect(template.id, template.label)}
          className={`
            w-full text-left px-3 py-1 text-xs
            ${isDark 
              ? 'hover:bg-gray-700 text-gray-300' 
              : 'hover:bg-gray-100 text-gray-800'}
          `}
        >
          {template.label}
        </button>
      ))}
    </div>
  );
};

const TiptapBubbleMenu = ({ editor, isDark, onWriteWithAI }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAIDropdown, setShowAIDropdown] = useState(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const aiButtonRef = useRef(null);

  if (!editor) {
    return null;
  }

  // Handler for AI template selection
  const handleTemplateSelect = (templateId, templateLabel) => {
    setShowAIDropdown(false);
    
    if (templateId === 'custom') {
      setShowCustomModal(true);
      return;
    }
    
    const selectedText = editor.view.state.doc.textBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      ' '
    );
    
    if (onWriteWithAI && selectedText) {
      onWriteWithAI(selectedText, templateId, templateLabel);
    }
  };
  
  // Handler for custom AI instruction
  const handleCustomInstruction = (instruction) => {
    const selectedText = editor.view.state.doc.textBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      ' '
    );
    
    if (onWriteWithAI && selectedText) {
      onWriteWithAI(selectedText, 'custom', instruction);
    }
  };

  return (
    <>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          onHide: () => {
            setIsOpen(false);
            // Keep dropdown open if it's being interacted with
            if (!showCustomModal) {
              setShowAIDropdown(false);
            }
          },
          onShow: () => setIsOpen(true),
        }}
        className={`
          py-1 px-1 rounded-lg shadow-lg
          ${isDark ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'}
        `}
        shouldShow={({ editor }) => {
          // Only show when text is selected
          const { empty } = editor.state.selection;
          return !empty;
        }}
      >
        <div className="flex items-center space-x-1">
          {/* Basic formatting options */}
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`
              p-1 rounded-md
              ${editor.isActive('bold') 
                ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-100') 
                : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100')}
            `}
            title="Bold"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
              <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
            </svg>
          </button>
          
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`
              p-1 rounded-md
              ${editor.isActive('italic') 
                ? (isDark ? 'bg-gray-700 text-white' : 'bg-gray-100') 
                : (isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100')}
            `}
            title="Italic"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
              <line x1="19" y1="4" x2="10" y2="4"></line>
              <line x1="14" y1="20" x2="5" y2="20"></line>
              <line x1="15" y1="4" x2="9" y2="20"></line>
            </svg>
          </button>
          
          {/* Divider */}
          <div className={`h-4 w-px ${isDark ? 'bg-gray-700' : 'bg-gray-300'}`}></div>
          
          {/* Write with AI dropdown toggle button */}
          <div className="relative">
            <button
              ref={aiButtonRef}
              onMouseEnter={() => setShowAIDropdown(true)}
              onFocus={() => setShowAIDropdown(true)}
              className={`
                p-1 rounded-md flex items-center
                ${isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}
              `}
              title="Write with AI"
            >
              <AIIcon />
              <span className="ml-1 text-xs whitespace-nowrap">Write with AI</span>
              <ChevronDownIcon />
            </button>
            
            <AITemplatesDropdown 
              isOpen={showAIDropdown} 
              onSelect={handleTemplateSelect} 
              isDark={isDark} 
            />
          </div>
          
          {/* Format text button */}
          <button
            onClick={() => {
              // Add your format text logic here
              // For example, you could open a dropdown with formatting options
            }}
            className={`
              p-1 rounded-md
              ${isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}
            `}
            title="Format Text"
          >
            <FormatIcon />
          </button>
        </div>
      </BubbleMenu>
      
      {/* Custom instruction modal */}
      <CustomInstructionModal 
        isOpen={showCustomModal}
        onClose={() => setShowCustomModal(false)}
        onSubmit={handleCustomInstruction}
        isDark={isDark}
      />
    </>
  );
};

export default TiptapBubbleMenu;