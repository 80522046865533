import React from "react";
import { CalendarIcon, UserIcon } from "../../assets/icons/icons";
import { useThemeContext } from "../../context/ThemeContext";
import GuestReportDownload from "../GuestReportDownload";

const InfoCard = ({ review, title, name, date, reviewer }) => {
  const { theme } = useThemeContext();
  const isDark = theme === "dark";

  return (
    <div
      className={`p-4 rounded-2xl ${isDark ? "bg-gray-800" : "bg-[#FAFAFA]"}`}
    >
      {/* Title and Button Row */}
      <div className="flex justify-between items-center mb-4">
        <h1
          className={`text-2xl font-semibold capitalize ${
            isDark ? "text-white" : "text-black"
          }`}
        >
          {title}
        </h1>
        {review && <GuestReportDownload document={review} />}
      </div>

      {/* Name Row */}
      <div className="mb-4">
        <p
          className={`text-base font-medium ${
            isDark ? "text-gray-300" : "text-gray-600"
          }`}
        >
          {name}
        </p>
      </div>

      {/* Info Columns */}
      <div className="flex gap-16">
        {/* Date Column */}
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <CalendarIcon />
            <span className="text-base font-medium text-[#A1A1AA]">Date</span>
          </div>
          <span
            className={`text-base font-semibold ${
              isDark ? "text-white" : "text-black"
            }`}
          >
            {date}
          </span>
        </div>

        {/* Reviewer Column */}
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <UserIcon />
            <span className="text-base font-medium text-[#A1A1AA]">
              Reviewer
            </span>
          </div>
          <span
            className={`text-base font-semibold ${
              isDark ? "text-white" : "text-black"
            }`}
          >
            {reviewer}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;

// Usage Example:
/*
<InfoCard
  title="Document Review"
  name="Project Analysis"
  date="2024-02-06"
  reviewer="John Doe"
  buttonText="Submit Review"
  onButtonClick={() => console.log('Button clicked')}
/>
*/
