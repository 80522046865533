import React from "react";
import { IoClose } from "react-icons/io5";
import { Form, Input, Button, Textarea } from "@heroui/react";
import { useThemeContext } from "../../context/ThemeContext";
import toaster from "../Toast/Toast";

const DEFAULT_FORM_DATA = {
  title: "",
  description: "",
  page: "",
};

const FeedbackPopup = ({
  isOpen,
  onClose,
  onConfirm,
  initialData,
  mode = "add",
}) => {
  const { theme } = useThemeContext();
  const [formData, setFormData] = React.useState(() => {
    if (!initialData) return DEFAULT_FORM_DATA;

    return {
      title: initialData.title || "",
      description: initialData.description || initialData.text || "",
      page: initialData.page || initialData.pageNumber || "",
    };
  });

  React.useEffect(() => {
    if (mode === "add") {
      setFormData(DEFAULT_FORM_DATA);
    } else if (initialData) {
      setFormData({
        title: initialData.title || "",
        description: initialData.description || initialData.text || "",
        page: initialData.page || initialData.pageNumber || "",
      });
    }
  }, [mode, initialData]);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.currentTarget));

    if (!data.title || !data.description || !data.page) {
      toaster.error("Please fill in all required fields");
      return;
    }

    const submissionData = {
      title: data.title.trim(),
      text: data.description.trim(),
      pageNumber: data.page.trim(),
    };

    onConfirm(submissionData);
    toaster.success(
      mode === "add"
        ? "Feedback added successfully"
        : "Feedback updated successfully"
    );
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black/70 backdrop-blur-sm z-[1000]"
        onClick={onClose}
      />

      {/* Container */}
      <div className="fixed inset-0 flex items-center justify-center z-[1001]">
        {/* Popup */}
        <div
          className={`
          w-full max-w-[700px] relative p-6 rounded-3xl border
          ${
            theme === "light"
              ? "bg-white border-gray-200"
              : "bg-[#0D141C] border-[#3C3C3C]"
          }
        `}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-6 right-6 hover:opacity-70 transition-opacity"
          >
            <IoClose size={24} color="#A1A1A1" />
          </button>

          {/* Title */}
          <h2
            className={`
            text-2xl font-semibold mb-6 font-['Space_Grotesk']
            ${theme === "light" ? "text-gray-900" : "text-white"}
          `}
          >
            {mode === "add" ? "Add Feedback" : "Edit Feedback"}
          </h2>

          <Form
            className="space-y-5"
            validationBehavior="native"
            onSubmit={handleSubmit}
          >
            {/* Title Input */}
            <Input
              isRequired
              label="Title"
              placeholder="Enter title"
              name="title"
              defaultValue={formData.title}
            />

            {/* Description Input */}
            <Textarea
              isRequired
              label="Description"
              placeholder="Enter description"
              name="description"
              defaultValue={formData.description}
              minRows={4} // Sets minimum number of rows
              maxRows={10} // Optional: sets maximum number of rows
              className="min-h-[100px]" // Ensures minimum height
            />

            {/* Page Input */}
            <Input
              isRequired
              label="Page"
              placeholder="Enter page number"
              name="page"
              defaultValue={formData.page}
            />

            {/* Action Buttons */}
            <div className="w-full flex justify-end gap-2.5 mt-6">
              <Button
                variant="light"
                onPress={onClose}
                className={
                  theme === "light"
                    ? "border-gray-300"
                    : "border-[#5D6673] text-white"
                }
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create Feedback
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FeedbackPopup;
