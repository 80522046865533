import React from "react";
import { useThemeContext } from "../../context/ThemeContext";
import nextArrowIcon from "../../assets/icons/nextArrow.svg";
import prevArrowIcon from "../../assets/icons/prevArrow.svg";

const NavigationBar = ({
  currentPage = 1,
  totalPages = 1,
  onPrevPage,
  onNextPage,
}) => {
  const { theme } = useThemeContext();
  return (
    <div className="flex justify-center items-center gap-4 w-full">
      {/* Left Icon */}
      <div
        className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 p-1 rounded-full transition-colors"
        onClick={onPrevPage}
      >
        <img src={prevArrowIcon} alt="left icon" className="w-6 h-6" />
      </div>

      {/* Text in middle */}
      <span
        className={`
    font-medium text-sm 
    ${theme === "light" ? "text-[#A1A1AA]" : "text-gray-400"}
  `}
      >
        Page {currentPage} of {totalPages}
      </span>

      {/* Right Icon */}
      <div
        className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 p-2 rounded-full transition-colors"
        onClick={onNextPage}
      >
        <img src={nextArrowIcon} alt="right icon" className="w-6 h-6" />
      </div>
    </div>
    // <div className={styles.container}>
    //   <div className={styles.leftSection}>
    //     <CustomButton
    //       handleButtonClick={onPrevPage}
    //       text="Previous"
    //       glow={false}
    //       backgroundColor="#39F2AE"
    //       textColor="#404040"
    //       disabled={currentPage <= 1}
    //       height="30px"
    //       radius="25px" // Rounded corners
    //       width="110px"
    //     />

    //     <span className={styles.pageText}>
    //       Page {currentPage} of {totalPages}
    //     </span>
    //     <CustomButton
    //       handleButtonClick={onNextPage}
    //       text="Next"
    //       glow={false}
    //       backgroundColor="#39F2AE"
    //       textColor="#404040"
    //       height="30px"
    //       radius="25px" // Rounded corners
    //       width="110px"
    //       disabled={currentPage >= totalPages}
    //     />
    //   </div>

    //   <CustomButton
    //     handleButtonClick={onAction}
    //     text={actionLabel}
    //     glow={false}
    //     backgroundColor="#39F2AE"
    //     textColor="#404040"
    //     height="30px"
    //     radius="25px" // Rounded corners
    //     Icon={FiDownload} // Icon at the start
    //     iconPosition="start"
    //   />
    // </div>
  );
};

export default NavigationBar;
