import React, { useState, useRef } from "react";
import { Button, Switch } from "@heroui/react";
import { Document, Page } from "react-pdf";
import { useThemeContext } from "../../context/ThemeContext";
import NavigationBar from "../NavigationBar";
import {
  UserIcon,
  CalendarIcon,
  MessageSquareIcon,
  LinkIcon,
} from "../../assets/icons/icons";

const FeedbackDetailCard2 = ({
  title,
  imageUrl,
  ownerName,
  date,
  reviewCount,
  onCopyLink,
  handleGenerateSummary,
  shortlinkEnabled,
  onToggleShortlink,
  uploadFile,
}) => {
  const { theme } = useThemeContext();
  const isDark = theme === "dark";
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages: totalPages }) => {
    setNumPages(totalPages);
  };

  const onPageLoadSuccess = (page) => {
    const pdfWidth = page.originalWidth;
    const pdfHeight = page.originalHeight;

    // Use fixed container dimensions instead of getting from ref
    const containerWidth = 1240; // Fixed width
    const containerHeight = 730; // Fixed height

    // Calculate aspect ratios
    const containerAspectRatio = containerWidth / containerHeight;
    const pdfAspectRatio = pdfWidth / pdfHeight;

    let newScale;
    if (pdfAspectRatio > containerAspectRatio) {
      // If PDF is wider relative to height than container, scale to container width
      newScale = containerWidth / pdfWidth;
    } else {
      // If PDF is taller relative to width than container, scale to container height
      newScale = containerHeight / pdfHeight;
    }

    // Apply the scale with a small margin factor
    setScale(newScale * 0.9);
  };

  const handlePrevious = () => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setPageNumber((prev) => Math.min(prev + 1, numPages));
  };

  const handleDownload = () => {
    window.open(imageUrl, "_blank");
  };

  return (
    <div
      className={`flex flex-col gap-2 ${
        isDark ? "bg-gray-800" : "bg-[#FAFAFA]"
      } rounded-2xl p-4`}
    >
      {/* Header Row */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold capitalize">{title}</h1>
        <Button color="primary" onPress={uploadFile}>
          Upload File
        </Button>
      </div>

      {/* PDF Viewer */}
      <div
        ref={containerRef}
        className="w-full h-[580px] flex items-center justify-center bg-gray-400 rounded-lg overflow-hidden"
      >
        <Document
          file={imageUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="canvas"
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={onPageLoadSuccess}
            onRenderError={() => {
              console.error("Error rendering page");
            }}
            scale={scale}
          />
        </Document>
      </div>

      {imageUrl && (
        <NavigationBar
          currentPage={pageNumber}
          totalPages={numPages}
          onPrevPage={handlePrevious}
          onNextPage={handleNext}
          onAction={handleDownload}
          actionLabel="Download"
        />
      )}

      {/* Info Columns */}
      <div className="flex justify-between items-start">
        {/* Owner Column */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <UserIcon />
            <span className="text-base font-medium text-zinc-400">Owner</span>
          </div>
          <span className="text-base font-semibold">{ownerName}</span>
        </div>

        {/* Date Column */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <CalendarIcon />
            <span className="text-base font-medium text-zinc-400">Date</span>
          </div>
          <span className="text-base font-semibold">{date}</span>
        </div>

        {/* Reviews Column */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <MessageSquareIcon />
            <span className="text-base font-medium text-zinc-400">Reviews</span>
          </div>
          <span className="text-base font-semibold">{reviewCount} Reviews</span>
        </div>

        {/* Shortlink Column */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <LinkIcon />
            <span className="text-base font-medium text-zinc-400">
              Shortlink
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              isSelected={shortlinkEnabled}
              onValueChange={onToggleShortlink}
            />
            <span
              className="text-base font-semibold cursor-pointer hover:opacity-80"
              onClick={onCopyLink}
            >
              Click to copy
            </span>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="w-full border-[0.3px] border-zinc-300 mt-1" />

      {/* Generate Summary Button */}
      <div className="flex justify-end mt-1">
        <Button
          color="primary"
          size="lg"
          onPress={handleGenerateSummary}
          className="text-base font-bold"
        >
          Generate Summary
        </Button>
      </div>
    </div>
  );
};

export default FeedbackDetailCard2;
