import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import { getReviewByIdApi } from "../../../api/reviewApi";
import { isObjectEmpty, formatDate } from "../../../helper/helper";
import { useBreadcrumb } from "../../../context/BreadcrumbContext";

import toaster from "../../../components/Toast/Toast";
import OverlayLoader from "../../../components/OverlayLoader";
import InfoCard from "../../../components/InfoCard/InfoCard";

// Helper function to get severity color
const getSeverityColor = (severity) => {
  if (!severity) return "#1890FF"; // Default color
  switch (severity.toLowerCase()) {
    case "high":
      return "#FF4D4F";
    case "medium":
      return "#FAAD14";
    case "low":
      return "#52C41A";
    default:
      return "#1890FF";
  }
};

// Helper function to get type icon or label
const getTypeLabel = (type) => {
  if (!type) return "General Issue";
  switch (type.toLowerCase()) {
    case "content":
      return "Content Issue";
    case "technical":
      return "Technical Issue";
    case "visual":
      return "Visual Issue";
    default:
      return "General Issue";
  }
};

// Helper function to check if item uses new structure
const isNewStructure = (item) => {
  return (
    item.hasOwnProperty("issueTitle") && item.hasOwnProperty("issueExplanation")
  );
};

// Helper function to get the title from either structure
const getTitle = (item) => {
  if (isNewStructure(item)) {
    return item.issueTitle;
  }
  return item.title || "Untitled";
};

// Helper function to get the description/text from either structure
const getDescription = (item) => {
  if (isNewStructure(item)) {
    return item.issueExplanation;
  }
  return item.summary_text || item.text || "No description available";
};

const FeedbackGuest = () => {
  const { filename, guestId } = useParams();
  const { setPathDisplay } = useBreadcrumb();
  const [review, setReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [isNewFormat, setIsNewFormat] = useState(false);

  useEffect(() => {
    if (review) {
      setPathDisplay({
        [`/feedback/${filename}`]: review.document_title,
        [`/feedback/${filename}/${guestId}`]: `Guest ${guestId} Feedback`,
      });

      // Determine the format by checking the first summary item
      if (review.summary?.length > 0) {
        setIsNewFormat(isNewStructure(review.summary[0]));
      }
    }
  }, [review, filename, guestId, setPathDisplay]);

  useEffect(() => {
    fetchReview();
  }, []);

  const fetchReview = async () => {
    setLoadingText("Getting Reviews...");
    setIsLoading(true);
    try {
      const response = await getReviewByIdApi(guestId);
      if (!response?.success) {
        throw response;
      }
      setReview(response.review);
    } catch (error) {
      console.error("Error fetching review:", error);
      toaster.error(
        error.message || "Failed to fetch review. Please try again later."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <OverlayLoader text={loadingText} />;
  }

  if (!review || !isObjectEmpty(review)) {
    return <p >No review found</p>;
  }
  return (
    <div className='text-left'>
      <InfoCard
        review={review}
        title={review.document_title}
        name={`Guest ${review.id}`}
        date={formatDate(review.created_at)}
        reviewer={`Guest #${review.id}`}
      />

      <div className="flex flex-col gap-5 mt-10">
        <div>
          <p className="text-2xl font-semibold mb-2">Key Takeaways</p>
          {review.misc?.key_takeaways ? (
            <p className="text-base font-medium text-[#71717A]">
              {review.misc.key_takeaways}
            </p>
          ) : (
            <p className="text-base font-medium text-[#71717A]">Not Found</p>
          )}
        </div>

        <div>
          <p className="text-2xl font-semibold mb-4">Detailed Feedback</p>
          {review.summary?.length > 0 ? (
            review.summary.map((item, index) => (
              <div key={index} className='mb-4'>
                <div className='flex justify-between items-start mb-1'>
                  <p
                    className="text-base font-bold"
                    style={{ color: getSeverityColor(item.severity) }}
                  >
                    {getTitle(item)}
                  </p>
                  {isNewFormat && (
                    <div className='flex gap-2'>
                      <span className='px-2 py-1 rounded text-xs text-[#666666] bg-[#F5F5F5]'>
                        {getTypeLabel(item.type)}
                      </span>
                      <span className='px-2 py-1 rounded text-xs text-[#1890FF] bg-[#E6F7FF]'>
                        Page {item.pageNumber || "N/A"}
                      </span>
                    </div>
                  )}
                </div>
                <p className="text-base font-medium text-[#71717A]">
                  {getDescription(item)}
                </p>
                {isNewFormat && item.context && (
                  <p className="text-base font-medium text-[#71717A] italic">
                    Context: {item.context}
                  </p>
                )}
              </div>
            ))
          ) : (
            <p className="text-base font-medium text-[#71717A]">Not Found</p>
          )}
        </div>

        <div>
          <p className="text-2xl font-semibold mb-2">Additional Notes</p>
          {review.misc?.additional_notes ? (
            <p className="text-base font-medium text-[#71717A]">
              {review.misc.additional_notes}
            </p>
          ) : (
            <p className="text-base font-medium text-[#71717A]">Not Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackGuest;
