import React from 'react';
import { UserIcon } from '../../assets/icons/icons';
import { useThemeContext } from '../../context/ThemeContext';

const BriefInfo = ({ title, createdBy, dateCreated, templateType }) => {
  const { isDark } = useThemeContext();
  
  return (
    <div className={`p-6 rounded-lg ${isDark ? 'bg-gray-800' : 'bg-white'}`}>

        <div className='flex gap-2'>
        <div >
          <UserIcon />
        </div>
        <h3 className={`text-base font-medium ${isDark ? 'text-gray-300' : 'text-zinc-400'}`}>
            Brief Information
          </h3>
        </div>

        <div className="flex flex-col gap-4 mt-4">

            <div className='flex justify-between'>
            <div>
              <p className={`text-sm font-medium ${isDark ? 'text-gray-400' : 'text-black/60'}`}>
                Template
              </p>
            </div>
            <div>
              <p className={`text-sm font-medium ${isDark ? 'text-white' : 'text-black'}`}>
                {templateType}
              </p>
            </div>
            </div>
            <div className='flex justify-between'>
            <div>
              <p className={`text-sm font-medium ${isDark ? 'text-gray-400' : 'text-black/60'}`}>
                Created By
              </p>
            </div>
            <div>
              <p className={`text-sm font-medium ${isDark ? 'text-white' : 'text-black'}`}>
                {createdBy}
              </p>
            </div>
                </div>
                <div className='flex justify-between'>
                <div>
              <p className={`text-sm font-medium ${isDark ? 'text-gray-400' : 'text-black/60'}`}>
                Date Created
              </p>
            </div>
            <div>
              <p className={`text-sm font-medium ${isDark ? 'text-white' : 'text-black'}`}>
                {dateCreated}
              </p>
            </div>
                </div>
        </div>
      <div className="flex items-start">
      
        
        <div className="flex-1">
       
          
          <div className="grid grid-cols-2 gap-y-4 mt-6">
          
            
         
            
           
          </div>
        </div>
      </div>
    </div>
  );
};


export default BriefInfo;